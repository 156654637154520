import { LeaderboardPrediction } from "./LeaderboardPrediction";
import { Athlete } from "../../Shared/Athlete";
import {
	ILeaderboardPredictionFinalistsSelectionStrategy,
	LeaderboardPredictionFinalistsSelectionStrategyFactory
} from "./FinalistSelectionStrategies/LeaderboardPredictionFinalistsSelectionStrategy";
import { IProvidesFinalists } from "../../Shared/IProvidesFinalists";
import { RankedAthleteCollection } from "../RankedAthleteCollection";
import { CompetitionRuleset } from "../../../Enums/CompetitionRuleset";

export class GroupStageLeaderboardPrediction extends LeaderboardPrediction implements IProvidesFinalists {

	public override readonly IsFinal: boolean = false;

	private readonly _finalistsSelectionStrategy: ILeaderboardPredictionFinalistsSelectionStrategy;

	constructor(stageId: string, ruleset: CompetitionRuleset, competingAthletes: Array<Athlete>, existingRankings?: RankedAthleteCollection) {
		super(stageId, ruleset, competingAthletes, existingRankings);

		this._finalistsSelectionStrategy = LeaderboardPredictionFinalistsSelectionStrategyFactory.Create(ruleset);
	}

	public GetFinalists(): Array<Athlete> {
		return this._finalistsSelectionStrategy.GetFinalists(this.Rankings);
	}
}
