import { ConfirmModalSettings } from "../Components/Global/ConfirmModalSettings";
import { PredictionStoreState } from "./useStore";


export const displayModal = (draft: PredictionStoreState, prompts: Array<string>, onConfirmClicked: () => void) => {
	draft.confirmModalSettings = new ConfirmModalSettings(true, "Confirm", prompts);
	draft.onConfirmModalConfirmClicked = () => {
		onConfirmClicked();
	};
};

export const hideModal = (draft: PredictionStoreState) => {
	draft.confirmModalSettings = new ConfirmModalSettings(false);
};
