import { ICompetitionPrediction } from "./Interfaces/ICompetitionPrediction";
import { immerable } from "immer";
import { CompetitionStructure } from "../CompetitionStructure/CompetitionStructure";
import { BaseStagePrediction } from "../StagePrediction/BaseStagePrediction";
import { Stage } from "../CompetitionStructure/Stage";
import { ICommand } from "./Commands/Base/ModifyPredictionCommand";
import { CanExecuteResponse } from "./Commands/Base/CanExecuteResponse";
import { PredictionTypeEnum } from "../../Enums/PredictionTypeEnum";
import { newGuid } from "../../../Utility/new-guild";
import { AthletePosition } from "../../ValueObjects/AthletePosition";


export abstract class BaseCompetitionPrediction implements ICompetitionPrediction {
	[immerable] = true;

	public readonly CompetitionPredictionId: string;
	public readonly Competition: CompetitionStructure;
	public abstract Predictions: Array<BaseStagePrediction>;
	public abstract SelectedGroup: Stage;

	protected constructor(competition: CompetitionStructure, id?: string) {
		this.Competition = competition;
		this.CompetitionPredictionId = id ?? newGuid();
	}

	public get HasFinalStage(): boolean {
		return this.Predictions.some(x => x.IsFinal);
	}

	public RunModifyCommand(command: ICommand): CanExecuteResponse {
		const canExecuteResult = command.CanExecute();

		if (!canExecuteResult.CanExecute) {
			return canExecuteResult;
		}

		canExecuteResult.ExecutionSuccess = command.Execute();

		return canExecuteResult;
	}

	public GetPredictionFor(stageId: string): BaseStagePrediction {
		const prediction = this.Predictions.find(x => x.StageId === stageId);

		if (!prediction) {
			throw new Error(`No prediction found for stageId: ${stageId} in getPredictionFor()`);
		}

		return prediction;
	}

	public ChangePredictionTypeFor(stageId: string, predictionType: PredictionTypeEnum): boolean {
		const foundIndex = this.Predictions.findIndex((x: BaseStagePrediction) => x.StageId === stageId);
		if (foundIndex === -1) throw new Error(`No prediction was found for stageId: ${stageId}`);

		const group = this.Competition.Stages.find(x => x.Id === stageId);
		if (group === undefined) throw new Error(`No group was found for stageId: ${stageId}`);

		this.Predictions = this.Predictions.filter(x => x.StageId !== stageId);

		return this.CreatePredictionFor(stageId, predictionType);
	}

	public abstract CreatePredictionFor(stageId: string, predictionType: PredictionTypeEnum): boolean;

	public get HaveSelectionsBeenMade(): boolean {
		return this.Predictions.some(x => x.HaveSelectionsBeenMade());
	}

	public GetRankings(): Array<AthletePosition> {
		return this
			.Predictions
			.find(x => x.IsFinal)
			?.GetRankings() ?? [];
	}
}
