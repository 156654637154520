import { Typography } from "@mui/material";
import React from "react";

export const UnauthenticatedWarning = () => {
	return (
		<Typography variant="h6" textAlign="center" my={5}>
			Please sign in to view this page 🔒
		</Typography>
	);
};
