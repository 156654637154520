import React from "react";
import { Box, CircularProgress, SxProps, Theme } from "@mui/material";


export const LoadingSpinner = ({ text, sx }: { text?: string, sx?: SxProps<Theme> }) => {
	return (
		<Box sx={{ textAlign: "center", pt: 10, ...sx }}>
			<CircularProgress/>
			<h5>{text}</h5>
		</Box>
	);
};
