import React, { useState } from "react";
import { Alert, Box } from "@mui/material";
import { useStore } from "../../../Store/useStore";
import { ProceedToFinalsDialog } from "../../../Components/StagedSelection/FinalStage/ProceedToFinalsDialog";
import { SelectedFinalistsCard } from "../../../Components/StagedSelection/FinalStage/SelectedFinalistsCard";
import { EventPredictions } from "../../../Domain/Aggregates/StagePrediction/EventPredictions/EventPredictions";
import { FinalStageSection } from "../../../Components/StagedSelection/FinalStage/FinalStageSection";
import { Wsm2023Prediction } from "../../../Domain/Aggregates/CompetitionPrediction/Wsm2023Prediction";
import { CompetitionPaper } from "../../../Components/PredictionSelection/CompetitionPaper";
import { LeaderboardPrediction } from "../../../Domain/Aggregates/StagePrediction/LeaderboardPredictions/LeaderboardPrediction";


type PredictionType = LeaderboardPrediction & EventPredictions;


export const Wsm2023FinalsComponent = () => {

	const competitionPrediction = useStore((store) => store.competitionPrediction) as Wsm2023Prediction;

	const finalPrediction = competitionPrediction.Predictions.find(x => x.IsFinal) as PredictionType;
	const finalists = competitionPrediction.GetFinalists();
	const isReadOnly = useStore((state) => state.isReadOnly);

	return (
		<CompetitionPaper showShareButton={false} showStatsButton={false}>

			<Box mb={2}>
				<SelectedFinalistsCard />
			</Box>

			{finalPrediction && competitionPrediction.IsFinalStageActive ? (
				<FinalStageSection />
			) : isReadOnly ? (
				<Alert
					icon={false}
					severity="warning"
					sx={{ justifyContent: "center" }}
				>
					User has not yet made predictions for the finals
				</Alert>
			) : (
				<ProceedToFinalsDialog
					requiredFinalistsCount={competitionPrediction.RequiredFinalistsCount}
					actualFinalistsCount={finalists.length}
				/>
			)}

		</CompetitionPaper>
	);
};

