import { SingleEventPrediction } from "../SingleEventPrediction";
import { StagePointsTable } from "../../Scoring/StagePointsTables";
import { Wsm2023EventUpdaterOnPointsChangeStrategy } from "./Wsm2023EventUpdaterOnPointsChangeStrategy";
import { CompetitionRuleset } from "../../../../Enums/CompetitionRuleset";

/**
 * This can be called whenever an event changes, originally introduced to update the StoneOff
 * prediction for the group stage of an EventsPrediction. User selection are made, the points
 * table updates and new athletes may need inserted into the StoneOff event.
 */
export interface IEventUpdaterOnPointsChangeStrategy {
	UpdateEventPredictionss(eventPredictions: Array<SingleEventPrediction>, pointsTable: StagePointsTable): Array<SingleEventPrediction>;
}


export class EventUpdaterOnPointsChangeStrategyFactory {
	public static Create(ruleset: CompetitionRuleset): IEventUpdaterOnPointsChangeStrategy {
		switch (ruleset) {
			case CompetitionRuleset.Wsm2023: {
				return new Wsm2023EventUpdaterOnPointsChangeStrategy();
			}
			default: {
				throw new Error(`EventPredictionUpdaterOnPointsChangeFactory was not able to provide a strategy for ruleset: ${ruleset}`);
			}
		}
	}
}

