import {
	ICompetitionPrediction
} from "../../Domain/Aggregates/CompetitionPrediction/Interfaces/ICompetitionPrediction";
import { BaseStagePrediction } from "../../Domain/Aggregates/StagePrediction/BaseStagePrediction";
import { PredictionTypeEnum } from "../../Domain/Enums/PredictionTypeEnum";
import {
	LeaderboardPrediction
} from "../../Domain/Aggregates/StagePrediction/LeaderboardPredictions/LeaderboardPrediction";
import { EventPredictions } from "../../Domain/Aggregates/StagePrediction/EventPredictions/EventPredictions";
import { SingleEventPrediction } from "../../Domain/Aggregates/StagePrediction/EventPredictions/SingleEventPrediction";
import { RankedAthleteCollection } from "../../Domain/Aggregates/StagePrediction/RankedAthleteCollection";
import { AthletePosition } from "../../Domain/ValueObjects/AthletePosition";
import { IAthletePositionDto } from "./Dtos/IAthletePositionDto";
import { CompetitionPredictionDto } from "./Dtos/CompetitionPredictionDto";
import { LeaderboardPredictionDto } from "./Dtos/LeaderboardPredictionDto";
import { EventsPredictionDto, SingleEventPredictionDto } from "./Dtos/EventsPredictionDto";


/**
 * Takes a CompetitionPrediction and converts it into a CompetitionPredictionDto, suitable for storage and comparison
 */
export class CompetitionPredictionDtoFactory {
	constructor() {
		//
	}

	public Create(competition: ICompetitionPrediction): CompetitionPredictionDto {
		const leaderboardPredictionDtos: Array<LeaderboardPredictionDto> = [];
		const eventsPredictionDtos: Array<EventsPredictionDto> = [];

		competition
			.Predictions
			.forEach((x: BaseStagePrediction) => {
				if (x.PredictionType === PredictionTypeEnum.Leaderboard) {
					leaderboardPredictionDtos.push(this.getDtoForLeaderboardPrediction(x as LeaderboardPrediction));
				} else {
					eventsPredictionDtos.push(this.getDtoForEventsPrediction(x as EventPredictions));
				}
			});

		const finalPlacings = this.getDtoForAthletePositions(competition.GetRankings());

		return new CompetitionPredictionDto(
			competition.Competition.Id,
			competition.CompetitionPredictionId,
			leaderboardPredictionDtos,
			eventsPredictionDtos,
			finalPlacings
		);
	}

	private getDtoForLeaderboardPrediction(leaderboardPrediction: LeaderboardPrediction): LeaderboardPredictionDto {
		const athletePositionsDto: Array<IAthletePositionDto> = this.getDtoForAthletePositions(leaderboardPrediction.Rankings.AthletePositions);

		return new LeaderboardPredictionDto(
			leaderboardPrediction.StageId,
			leaderboardPrediction.PredictionType,
			athletePositionsDto
		);
	}

	private getDtoForEventsPrediction(eventsPrediction: EventPredictions): EventsPredictionDto {
		const singleEventDtos = eventsPrediction.EventPredictions.map(x => this.getDtoForSingleEventPrediction(x));

		return new EventsPredictionDto(
			eventsPrediction.StageId,
			eventsPrediction.PredictionType,
			singleEventDtos
		);
	}

	private getDtoForSingleEventPrediction(singleEventPrediction: SingleEventPrediction): SingleEventPredictionDto {
		const athletePositionsDto: Array<IAthletePositionDto> = this.getDtoForAthletePositions(singleEventPrediction.Rankings.AthletePositions);

		return new SingleEventPredictionDto(singleEventPrediction.StrongmanEvent.StrongmanEventType.Name, athletePositionsDto);
	}

	private getDtoForAthletePositions(athletePositions: Array<AthletePosition>): Array<IAthletePositionDto> {
		return athletePositions.map((position: AthletePosition) => {
			const dto: IAthletePositionDto = {
				AthleteIds: position.Athletes.map(x => x.Id),
				Position: position.Position
			};

			return dto;
		});
	}
}
