import { AthletePosition } from "../../../ValueObjects/AthletePosition";
import { StrongmanEventType } from "../../CompetitionStructure/StrongmanEventType";
import { Athlete } from "../../Shared/Athlete";
import { immerable } from "immer";

export class EventPointsTable {
	public readonly StrongmanEventType: StrongmanEventType;
	public readonly AthletesWithPoints: Array<AthletePoints>;

	constructor(strongmanEventType: StrongmanEventType, athletePoints: Array<AthletePoints>) {
		this.StrongmanEventType = strongmanEventType;
		this.AthletesWithPoints = athletePoints;
	}
}

/**
 * AthletePoints is a simple class used when calculating the points for an individual event
 */
export class AthletePoints {
	[immerable] = true;
	public readonly Athlete: Athlete;
	public readonly Points: number;

	constructor(athlete: Athlete, points: number) {
		this.Athlete = athlete;
		this.Points = points;
	}
}

export class EventPointsTableFactory {
	private readonly strongmanEventType: StrongmanEventType;
	private readonly athletePositions: Array<AthletePosition>;
	private readonly maxPointsAvailable: number;

	constructor(strongmanEventType: StrongmanEventType, athletePositions: Array<AthletePosition>, maxPointsAvailable: number) {
		this.strongmanEventType = strongmanEventType;
		this.athletePositions = athletePositions;
		this.maxPointsAvailable = maxPointsAvailable;
	}

	public Create(): EventPointsTable {
		const athletesWithPoints = this.getAthletesWithPoints();

		return new EventPointsTable(this.strongmanEventType, athletesWithPoints);
	}

	private getAthletesWithPoints() : Array<AthletePoints> {
		const athletesWithPoints: Array<AthletePoints> = [];

		this.athletePositions.forEach((x: AthletePosition) => {
			const points = this.getPointsForAthlete(x.Position, x.Athletes.length);

			x.Athletes.forEach((athlete: Athlete) => {
				athletesWithPoints.push(
					new AthletePoints(athlete, points)
				);
			});
		});

		return athletesWithPoints;
	}

	private getPointsForAthlete(position: number, numberOfAthletesAtPosition: number): number {
		if(numberOfAthletesAtPosition === 1) {
			return this.getPointsForPosition(position);
		}

		const nextUntiedPosition = position + (numberOfAthletesAtPosition - 1);
		const pointsForTiedPosition = this.getPointsForPosition(position);
		const pointsForNextUntiedPosition = this.getPointsForPosition(nextUntiedPosition);
		const pointsDifferent = pointsForTiedPosition - pointsForNextUntiedPosition;
		const points = pointsForNextUntiedPosition + (pointsDifferent / 2);

		return points;
	}

	private getPointsForPosition(position: number) {
		return (this.maxPointsAvailable - position) + 1;
	}
}
