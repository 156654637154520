import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Stage } from "../../../Domain/Aggregates/CompetitionStructure/Stage";
import { GroupCard } from "./GroupCard";
import { shallow } from "zustand/shallow";
import { useStore } from "../../../Store/useStore";
import { EditGroupModal } from "./EditGroup/EditGroupModal";


interface Props {
	groups: Array<Stage>;
}

// TODO: split this up into components
export const GroupSelectionRow = React.memo(({ groups }: Props) => {
	//console.log(" --- GroupSelectionRow2 rendered ---");

	const selectedGroup = useStore((store) => store.competitionPrediction.SelectedGroup);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const setSelectedGroup = React.useCallback(useStore((store) => store.setSelectedStage, shallow), []);
	const [showAllGroups, setShowAllGroups] = React.useState(false);

	const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);
	const [groupToBeEdited, setGroupToBeEdited] = useState<Stage | undefined>(undefined);

	const handleToggleGroups = () => {
		setShowAllGroups(prev => !prev);
	};

	useEffect(() => {
		if(isMobile) {
			setShowAllGroups(false);
		}
	}, [selectedGroup.Id]);

	useEffect(() => {
		setIsEditGroupModalOpen(!!groupToBeEdited);
	}, [groupToBeEdited]);

	const handleEditButtonClicked = useCallback((stage: Stage) => {
		setIsEditGroupModalOpen(true);
		setGroupToBeEdited(stage);
	}, []);

	return (
		<Grid container spacing={2} justifyContent="center" mt={0} mb={2}>
			{isMobile ? (
				<>
					{groups
						.filter(x => showAllGroups ? true : x.Id === selectedGroup.Id)
						.map(stage => (
							<Grid item key={stage.Name} xs={12}>
								<GroupCard
									stage={stage}
									setSelectedGroup={setSelectedGroup}
									isSelected={selectedGroup.Id === stage.Id}
									onEditButtonClicked={() => handleEditButtonClicked(stage)}
								/>
							</Grid>
						))}

					<ShowAllGroupsButton
						showAllGroups={showAllGroups}
						onClick={handleToggleGroups}
					/>
				</>

			) : (
				<>
					{groups.map((stage: Stage) => (
						<Grid
							key={stage.Name}
							item
							sx={{
								// display:flex is to fix an issue with MUI's CardActionArea when multiple are displayed in a <Grid>
								display: "flex",
								maxWidth: {
									xs: "100%",
									sm: "33%",
									md: "20%",
								}
							}}
						>
							<GroupCard
								stage={stage}
								setSelectedGroup={setSelectedGroup}
								isSelected={selectedGroup.Id === stage.Id}
								onEditButtonClicked={() => handleEditButtonClicked(stage)}
							/>
						</Grid>
					))}
				</>
			)}

			{process.env.REACT_APP_feature_editGroupsEnabled === "true" && (
				isEditGroupModalOpen && groupToBeEdited && (
					<EditGroupModal
						groupToBeEdited={groupToBeEdited}
						close={() => setIsEditGroupModalOpen(false)}
					/>
				)
			)}

		</Grid>
	);
});

const ShowAllGroupsButton = ({ showAllGroups, onClick }: { showAllGroups: boolean, onClick: () => void }) => {
	return (
		<Grid item xs={12} sx={{ mt: "-1px", pt: showAllGroups ? "unset" : "0px !important" }}>
			<Button
				color="warning"
				variant="contained"
				onClick={onClick}
				sx={{
					margin: "auto",
					width: "100%",
					borderTopLeftRadius: "0 !important",
					borderTopRightRadius: "0 !important"
				}}
			>
				{showAllGroups ? "Hide Groups" : "Change Group"}
			</Button>
		</Grid>
	);
};
