import { Country } from "../../../Domain/ValueObjects/Country";

export interface CountryViewModel {
	Name: string;
	CountryCode: string;
}

export const mapCountryViewModelToEntity = (viewModel: CountryViewModel): Country => {
	return new Country(viewModel.Name, viewModel.CountryCode);
};
