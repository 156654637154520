import { Athlete } from "../Shared/Athlete";
import { StrongmanEvent } from "./StrongmanEvent";
import { StrongmanEventType } from "./StrongmanEventType";
import { immerable } from "immer";


export class Stage {
	[immerable] = true;
	public readonly Id: string;
	public readonly IsFinal: boolean;
	public readonly Athletes: Array<Athlete>;
	public readonly Name: string;
	public readonly Events: Array<StrongmanEvent>;
	public readonly DisplayOrder: number;

	public constructor(id: string, athletes: Array<Athlete>, events: Array<StrongmanEvent>, name: string, isFinal: boolean, displayOrder: number = 1) {
		this.Id = id;
		this.Athletes = athletes;
		this.Events = events;
		this.Name = name;
		this.IsFinal = isFinal;
		this.DisplayOrder = displayOrder;

		// this.Events = events.map(x => {
		// 	return new StrongmanEvent(
		// 		x.id,
		// 		new StrongmanEventType(x.strongmanEventType.name),
		// 		x.displayOrder
		// 	);
		// }) ?? [];
	}

	public ContainsAthlete(athleteId: number): boolean {
		return this.Athletes.some(x => x.Id === athleteId);
	}

	public GetAthlete(athleteId: number): Athlete {
		if(!this.ContainsAthlete(athleteId)) {
			throw new Error(`Stage.GetAthlete(${athleteId}) did not find that athleteId in stage ${this.Id}`);
		} else {
			return this.Athletes.find(x => x.Id === athleteId) as Athlete;
		}
	}
}
