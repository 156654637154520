import { create } from "zustand";
import { produce } from "immer";
import { CompetitionStructure } from "../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import { BaseStagePrediction } from "../Domain/Aggregates/StagePrediction/BaseStagePrediction";
import { Wsm2023Prediction } from "../Domain/Aggregates/CompetitionPrediction/Wsm2023Prediction";
import { devtools } from "zustand/middleware";
import { createConfirmModalSlice, IConfirmModalSlice } from "./Slices/createConfirmModalSlice";
import { createSettingsSlice, ISettingsSlice } from "./Slices/createSettingsSlice";
import { ICompetitionPrediction } from "../Domain/Aggregates/CompetitionPrediction/Interfaces/ICompetitionPrediction";
import { createModifySlice, IModifySlice } from "./Slices/createModifySlice";
import { CompetitionRuleset } from "../Domain/Enums/CompetitionRuleset";
import { BaseCompetitionPrediction } from "../Domain/Aggregates/CompetitionPrediction/BaseCompetitionPrediction";
import { createAuthSlice, IAuthSlice } from "./Slices/authSlice";
import { createShareModalSlice, IShareModalSlice } from "./Slices/createShareModalSlice";
import { createStatsSlice, IStatsSlice } from "./Slices/createStatsSlice";


export type IPredictionStoreSlice = {
	competitionPrediction: ICompetitionPrediction;
	setCompetitionPrediction: (competitionPrediction: BaseCompetitionPrediction) => void;
	getPredictionForCurrentGroup: () => BaseStagePrediction;
	getPredictionForStage: (stageId: string) => BaseStagePrediction;
}

export type PredictionStoreState = IPredictionStoreSlice
	& IConfirmModalSlice
	& ISettingsSlice
	& IModifySlice
	& IAuthSlice
	& IShareModalSlice
	& IStatsSlice;

export let globalSet: any;

export const useStore = create<PredictionStoreState>()(devtools((set, get) => ({
	competitionPrediction: new Wsm2023Prediction(
		new CompetitionStructure(1, "Loading...", -1, [], CompetitionRuleset.Wsm2023)
	),
	setCompetitionPrediction: (competitionPrediction: BaseCompetitionPrediction) => {
		globalSet = set;
		set(produce((draft: PredictionStoreState) => {
			draft.competitionPrediction = competitionPrediction;
		}));
	},

	getPredictionForCurrentGroup: () => {
		const selectedGroup = get().competitionPrediction.SelectedGroup;

		return get().competitionPrediction.GetPredictionFor(selectedGroup.Id);
	},

	getPredictionForStage: (stageId: string) => {
		return get().competitionPrediction.GetPredictionFor(stageId);
	},

	...createModifySlice(set, get),
	...createSettingsSlice(set, get),
	...createConfirmModalSlice(set, get),
	...createAuthSlice(set, get),
	...createShareModalSlice(set, get),
	...createStatsSlice(set, get)
})));


/**
 * Will this make life easier? Splitting the state and the actions, may get rid of the memoisation issue when calling actions:
 *
 * https://github.com/pmndrs/zustand/blob/main/docs/guides/practice-with-no-store-actions.md
 */
