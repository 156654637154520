/**
 * Adapted from:
 * https://github.com/dcousens/ordinal.js/blob/master/indicator.js
 */
export const appendOrdinal = (i: number) => {
	i = Math.abs(i);
	const cent = i % 100;
	if (cent >= 10 && cent <= 20) return "th";
	const dec = i % 10;
	let ordinal;

	if (dec === 1) {
		ordinal = "st";
	} else if (dec === 2) {
		ordinal = "nd";
	} else if (dec === 3) {
		ordinal = "rd";
	} else {
		ordinal = "th";
	}

	return `${i}${ordinal}`;
};

/**
 * Takes an array of strings and joins them into a single string in which they are correctly separated
 * with commas and the word "and" if the array is longer than 2 items.
 *
 * @example ["James", "Mary", "John"] => "James, Mary and John"
 */
export const convertToStringWithCommasAndAnd = (words: Array<string>) => {
	return new Intl
		.ListFormat("EN-GB")
		.format(words);
};

/**
 * Adapted from:
 * https://stackoverflow.com/questions/10726909/random-alpha-numeric-string-in-javascript
 */
export const getRandomString = (length: number) => {
	const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
	let result = "";

	for (let i = length; i > 0; --i) {
		result += chars[Math.floor(Math.random() * chars.length)];
	}

	return result;
};


export const getAbbreviatedCompetitionName = (name: string): string => {
	if (name.includes("World's Strongest Man")) {
		return "WSM";
	} else if (name.includes("Arnold")) {
		return "ASC";
	} else {
		return name;
	}
};

export const getRandomNumber = (min: number, max: number, decimalPlaces: number = 0): number => {
	const num = Math.random() * (max - min) + min;

	return Number(num.toFixed(decimalPlaces));
};
