import React from "react";
import { Box } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";
import { RowWithContent } from "./RowWithContent";
import { RowWithoutContent } from "./RowWithoutContent";
import { TieIcon } from "./TieIcon";
import { CustomCell } from "./CustomCell";
import { useStore } from "../../../Store/useStore";
import { RowContentWrapper } from "./RowContentWrapper";
import { getRandomNumber } from "../../../Utility/utility.string";
import { StatsIndicator } from "./StatsIndicator";


interface DraggableRowProps {
	athletes: Array<Athlete>;
	rowIndex: number;
	position: number;
	athletesOnNextRow?: Array<Athlete>;
	nextPositionToSelect?: number;
	removeRowFn?: (position: number) => void;
	isLastRow?: boolean;
	areTiesAllowed: boolean;
	applyTieFn?: (fromPosition: number, toPosition: number) => void;
	getStatsPercentageFor: (athletes: Athlete[], position: number) => number | undefined;
}


export const DraggableRow = React.memo(({
	athletes,
	rowIndex,
	position,
	athletesOnNextRow = [],
	nextPositionToSelect,
	removeRowFn,
	areTiesAllowed,
	applyTieFn,
	getStatsPercentageFor
}: DraggableRowProps) => {

	const hasAthletes = athletes.length > 0;
	//console.log(`DraggableRow rendered, isEmpty: ${!hasAthletes}`);

	const onClick = () => {
		if (hasAthletes && removeRowFn) {
			removeRowFn(position);
		}
	};

	const athletesToBeTied = React.useMemo(() => [...athletesOnNextRow, ...athletes], [athletesOnNextRow, athletes]);
	const isReadOnly = useStore((state) => state.isReadOnly);

	const statsPercentage = hasAthletes
		? getStatsPercentageFor(athletes, position)
		: 0;

	return (
		<Draggable draggableId={rowIndex.toString()} index={rowIndex}>
			{provided => (
				<Box
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sx={{ position: "relative" }}
					className="row"
				>
					<RowContentWrapper hasAthletes={hasAthletes} onClick={onClick}>
						<CustomCell sx={{ textAlign: "center" }}>{position}</CustomCell>

						{hasAthletes ? (
							<RowWithContent
								athletes={athletes}
								statsPercentage={statsPercentage}
							/>
						) : (
							<RowWithoutContent
								position={position}
								displayMessage={position === nextPositionToSelect}
							/>
						)}
					</RowContentWrapper>

					{(areTiesAllowed && applyTieFn && hasAthletes && !isReadOnly) && (
						<TieIcon
							athletesToBeTied={athletesToBeTied}
							position={position}
							rowIndex={rowIndex}
							applyTieFn={applyTieFn}
						/>
					)}
				</Box>
			)}
		</Draggable>
	);
});

