import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Card, CardContent, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { UnauthenticatedWarning } from "../../Components/Global/UnauthenticatedWarning";
import { useStore } from "../../Store/useStore";


export const Profile = () => {

	const loggedInUser = useStore((state) => state.loggedInUser);

	return (
		<>
			<AuthenticatedTemplate>
				<Card>
					<CardContent>
						<Typography variant="h5" sx={{ mb: 2 }}>User Information</Typography>
						<Table sx={{ maxWidth: "700px", m: "auto" }}>
							<TableBody>
								<TableRow>
									<TableCell variant="head">Given Name</TableCell>
									<TableCell>{loggedInUser?.GivenName}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Username</TableCell>
									<TableCell>{loggedInUser?.Username}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Unique ID</TableCell>
									<TableCell>{loggedInUser?.UserId}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Signed In At</TableCell>
									<TableCell>{loggedInUser?.SignedInAsString}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Token Expires On</TableCell>
									<TableCell>{loggedInUser?.TokenExpiresOnAsString}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<UnauthenticatedWarning />
			</UnauthenticatedTemplate>
		</>
	);
};

