import React, { useState } from "react";
import {
	Card,
	CardActionArea,
	CardContent,
	Typography,
	useTheme
} from "@mui/material";
import { Stage } from "../../../Domain/Aggregates/CompetitionStructure/Stage";
import { GroupPhoto } from "./Components/GroupPhoto";
import { ListOfAthletes } from "./Components/ListOfAthletes";
import { EditButton } from "./Components/EditButton";


interface GroupCardProps {
	stage: Stage;
	setSelectedGroup: (group: Stage) => void;
	isSelected: boolean;
	onEditButtonClicked: () => void;
}

export const GroupCard = React.memo(({ stage, setSelectedGroup, isSelected, onEditButtonClicked }: GroupCardProps) => {
	//console.log(`GroupCard rendering for: ${stage.Id}`);

	const theme = useTheme();

	const formatGroupName = (name: string) => {
		return `Group ${name[name.length - 1]}`;
	};

	return (
		<Card
			onClick={() => setSelectedGroup(stage)}
			sx={{
				minHeight: "100%",
				border: isSelected ? `1px solid ${theme.palette.warning.main}` : "none",
				position: "relative"
			}}
		>
			{/* Display, alignContent & height are set to fix an issue with MUI's CardActionArea when multiple are displayed in a <Grid>*/}
			<CardActionArea sx={{ display: "grid", alignContent: "start", height: "100%" }}>

				<GroupPhoto stageName={stage.Name} />

				<CardContent>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						color={isSelected ? "warning.main" : "text.primary"}
					>
						{formatGroupName(stage.Name)}
					</Typography>

					<ListOfAthletes athletes={stage.Athletes} stageId={stage.Id} />

				</CardContent>
			</CardActionArea>

			{process.env?.REACT_APP_feature_editGroupsEnabled === "true" && (
				<EditButton onClick={onEditButtonClicked} />
			)}

		</Card>
	);
});
