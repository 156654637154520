import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useStore } from "../../Store/useStore";

export const ConfirmModal = React.memo(() => {

	const confirmModalSettings = useStore((state) => state.confirmModalSettings);
	const onConfirmModalCancelClicked = useStore((state) => state.onConfirmModalCancelClicked);
	const onConfirmModalConfirmClicked = useStore((state) => state.onConfirmModalConfirmClicked);

	return (
		<Dialog open={confirmModalSettings.IsOpen}>
			<DialogTitle>{confirmModalSettings.Title}</DialogTitle>
			<DialogContent sx={{ mt: 1 }}>
				{confirmModalSettings.Messages.map((x: string, index: number) => <DialogContentText sx={{ my: 2 }} key={`modal-msg-${index}`}>{x}</DialogContentText>)}
			</DialogContent>
			<DialogActions>
				<Button color="warning" onClick={() => onConfirmModalCancelClicked()}>Cancel</Button>
				<Button color="success" onClick={() => onConfirmModalConfirmClicked()}>Confirm</Button>
			</DialogActions>
		</Dialog>
	);
});
