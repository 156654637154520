import { Athlete } from "../../../Shared/Athlete";
import { RankedAthleteCollection } from "../../RankedAthleteCollection";
import { Wsm2023LeaderboardPredictionFinalistsSelectionStrategy } from "./Wsm2023LeaderboardPredictionFinalistsSelectionStrategy";
import { immerable } from "immer";
import { CompetitionRuleset } from "../../../../Enums/CompetitionRuleset";


export interface ILeaderboardPredictionFinalistsSelectionStrategy {
	GetFinalists(rankings: RankedAthleteCollection): Array<Athlete>;
}

export class LeaderboardPredictionFinalistsSelectionStrategyFactory {
	[immerable] = true;
	public static Create(ruleset: CompetitionRuleset): ILeaderboardPredictionFinalistsSelectionStrategy {
		switch (ruleset) {
			case CompetitionRuleset.Wsm2023: {
				return new Wsm2023LeaderboardPredictionFinalistsSelectionStrategy();
			}
			default: {
				throw new Error(`FinalistsSelectionStrategyFactory was not able to provide a strategy for ruleset: ${ruleset}`);
			}
		}
	}
}

