import React from "react";
import { PredictionTypeEnum } from "../Domain/Enums/PredictionTypeEnum";
import { useQuery } from "react-query";
import { GetStatsQueryResult, PredictionStatsViewModel } from "../ApplicationServices/Queries/ViewModels/PredictionStatsViewModels";
import { loadStatsQuery } from "../ApplicationServices/Queries/GetStatsQuery/GetStatsQuery";


interface IUseStatsReturnType {
	areStatsLoading: boolean;
	statsForCurrentGroup: PredictionStatsViewModel[];
}


export const useStats = (stageIdToGetStatsFor: string, predictionTypeToGetStatsFor: PredictionTypeEnum): IUseStatsReturnType => {

	const { data, isLoading } = useQuery<GetStatsQueryResult>(
		["stats", `${stageIdToGetStatsFor}${predictionTypeToGetStatsFor}`],
		async () => loadStatsQuery(stageIdToGetStatsFor, predictionTypeToGetStatsFor),
		{
			staleTime: 15 * 60000, // 15 minutes before a new API calls gets made
			cacheTime: 5 * 60000   // once data is stale, it'll stay in the cache for max 5mins whilst fresh data is retrieved from API
		}
	);

	const extractStatsForCurrentGroup = () => {
		if (isLoading || !data) {
			return [];
		}

		return predictionTypeToGetStatsFor === PredictionTypeEnum.Leaderboard
			? data.LeaderboardPredictionStats
			: data.EventsPredictionStats;
	};

	const statsForCurrentGroup = React.useMemo(() => extractStatsForCurrentGroup(), [isLoading, data, predictionTypeToGetStatsFor]);

	return {
		areStatsLoading: isLoading,
		statsForCurrentGroup: statsForCurrentGroup
	};
};
