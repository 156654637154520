import React from "react";
import { appendOrdinal } from "../../../Utility/utility.string";
import { RowMessage } from "./RowMessage";
import { CustomCell } from "./CustomCell";

export const RowWithoutContent = ({ position, displayMessage }: { position: number, displayMessage: boolean }) => {
	return (
		<>
			{displayMessage && (
				<CustomCell>
					<RowMessage
						text={`Click the athlete you think will finish ${appendOrdinal(position)}`}
					/>
				</CustomCell>
			)}
			<CustomCell/>
		</>
	);
};
