import { BaseStagePrediction } from "../Domain/Aggregates/StagePrediction/BaseStagePrediction";
import json2md from "json2md";
import { useStore } from "../Store/useStore";


export const useMarkdown = () => {

	const competitionPrediction = useStore((state) => state.competitionPrediction);
	const selectedCompetition = useStore((state) => state.selectedCompetition);
	// const [markdownText, markDownText] = useState("");

	const convertToMd = () => {
		const heatPredictions = competitionPrediction.Predictions.filter(x => !x.IsFinal);
		const finalPrediction = competitionPrediction.Predictions.find(x => x.IsFinal);

		const mdObj = [];

		if (finalPrediction) {
			mdObj.push({
				h3: "My Predicted Winners:",
				...getFinalPrediction(finalPrediction)
			});
		}

		mdObj.push({
			h3: "My Predicted Group Winners:",
			...getGroupWinnersObj(heatPredictions)
		});

		mdObj.push({
			blockquote: "Generated on www.fantasystrongman.com"
		});

		const output = json2md(mdObj);

		return output;
	};

	const getFinalPrediction = (finalPrediction: BaseStagePrediction) => {
		return {
			table: {
				headers: ["Position", "Athlete"],
				rows: finalPrediction.GetRankings().map(awp => {
					return [awp.Position, awp.Athletes[0].AthleteName];
				})
			}
		};
	};

	const getGroupWinnersObj = (heatPredictions: Array<BaseStagePrediction>) => {
		return {
			table: {
				headers: ["Group", "Athletes (1st, 2nd)"],
				rows: heatPredictions.map(heatPrediction => {
					const groupWinners = heatPrediction
						.GetRankings()
						.slice(0, 2)
						.flatMap(x => x.Athletes.flatMap(y => y.AthleteName))
						.join(", ");

					const stageName = selectedCompetition?.Stages.find(x => x.Id === heatPrediction.StageId)?.Name;

					return [stageName, groupWinners];
				})
			}
		};
	};

	return convertToMd;
};
