import { Wsm2023Prediction } from "../../Wsm2023Prediction";
import { Athlete } from "../../../Shared/Athlete";
import { ModifyPredictionWsm2023Decorator } from "../Base/ModifyPredictionWsm2023Decorator";
import { AddAthleteCommand } from "../AddAthleteCommand";
import { PredictionTypeEnum } from "../../../../Enums/PredictionTypeEnum";
import { ChangePredictionTypeCommand } from "../ChangePredictionTypeCommand";
import { IModifyCommandAbstractFactory } from "./ModifyPredictionCommandAbstractFactory";
import { RemovePositionCommand } from "../RemovePositionCommand";
import { MoveAthleteCommand } from "../MoveAthleteCommand";
import { ApplyTieCommand } from "../ApplyTieCommand";


export class Wsm2023ModifyCommandFactory implements IModifyCommandAbstractFactory {
	private readonly _competition: Wsm2023Prediction;

	constructor(competition: Wsm2023Prediction) {
		this._competition = competition;
	}

	public CreateAddAthleteCommand(athlete: Athlete, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): ModifyPredictionWsm2023Decorator {
		const command = new AddAthleteCommand<Wsm2023Prediction>(
			this._competition,
			athlete,
			stageId,
			promptConfirmed,
			strongmanEventId
		);

		return new ModifyPredictionWsm2023Decorator(command);
	}

	public CreateChangePredictionTypeCommand(predictionType: PredictionTypeEnum, stageId: string, promptConfirmed: boolean): ModifyPredictionWsm2023Decorator {
		const command = new ChangePredictionTypeCommand<Wsm2023Prediction>(
			this._competition,
			predictionType,
			stageId,
			promptConfirmed
		);

		return new ModifyPredictionWsm2023Decorator(command);
	}

	public CreateRemovePositionCommand(position: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): ModifyPredictionWsm2023Decorator {
		const command = new RemovePositionCommand<Wsm2023Prediction>(
			this._competition,
			position,
			stageId,
			promptConfirmed,
			strongmanEventId
		);

		return new ModifyPredictionWsm2023Decorator(command);
	}

	public CreateMovePositionCommand(indexFrom: number, indexTo: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): ModifyPredictionWsm2023Decorator {
		const command = new MoveAthleteCommand<Wsm2023Prediction>(
			this._competition,
			indexFrom,
			indexTo,
			stageId,
			promptConfirmed,
			strongmanEventId
		);

		return new ModifyPredictionWsm2023Decorator(command);
	}


	public CreateApplyTieCommand(indexFrom: number, indexTo: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): ModifyPredictionWsm2023Decorator {
		const command = new ApplyTieCommand<Wsm2023Prediction>(
			this._competition,
			indexFrom,
			indexTo,
			stageId,
			promptConfirmed,
			strongmanEventId
		);

		return new ModifyPredictionWsm2023Decorator(command);
	}
}


