export const basicRowStyle = {
	display: "grid",
	gap: 1,
	height: "3.5em",
	alignItems: "center",
	overflowX: "hidden",
};

export const gridTemplateColumnsFor3Cols = {
	gridTemplateColumns: {
		xs: "2em auto 0.5em",
		sm: "4em auto 0.5em",
		md: "4.5em auto 0.5em",
		lg: "5em auto 0.5em"
	}
};

export const gridTemplateColumnsFor4Cols = {
	gridTemplateColumns: {
		xs: "2.5em 2em auto 2.5em",
		sm: "5em 2.5em auto 5em",
	}
};

export const gridTemplateColumnsFor5Cols = {
	gridTemplateColumns: {
		xs: "2em 1.25em auto 4em 4.5em",
		sm: "4em 2em auto 6em 5.5em",
		md: "4.5em 2em auto 5em 6em",
		lg: "5em 2.5em auto 7em 6em",
	}
};
