import React from "react";
import { Box, Button, Menu, MenuItem, useTheme } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { ICompetitionStructure } from "../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import { useStore } from "../../Store/useStore";
import { settings } from "../../Config/settings";


export const SelectCompetitionDropdown = () => {

	const closedTransform = "rotate(0)";
	const openTransform = "rotate(90deg)";

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [transformString, setTransformString] = React.useState<string>(closedTransform);
	const isMenuOpen = Boolean(anchorEl);
	const closeMenu = () => setAnchorEl(null);
	const theme = useTheme();

	const selectedCompetition = useStore((state) => state.selectedCompetition);
	const setSelectedCompetitionId = useStore((state) => state.setSelectedCompetitionId);

	React.useEffect(() => {
		setTransformString(anchorEl ? openTransform : closedTransform);
	}, [anchorEl]);

	const latestCompetitions = React.useMemo((): Array<ICompetitionStructure> => {
		return [...settings.availableCompetitions]
			.sort((a, b) => b.DisplayOrder - a.DisplayOrder)
			.filter(x => x.Id !== selectedCompetition?.Id);
	}, [settings.availableCompetitions, selectedCompetition]);

	const onMenuItemClicked = (id: number) => {
		setSelectedCompetitionId(id);
		closeMenu();
	};

	if(!selectedCompetition) {
		return (<></>);
	}

	return (
		<Box>
			<Button onClick={(e: any) => setAnchorEl(e.currentTarget)}
				variant="text"
				color="inherit"
				sx={{
					borderRadius: 0,
					borderBottom: anchorEl
						? "1px solid #000"
						: `1px solid ${theme.palette.text.secondary}`
				}}
			>
				{`${selectedCompetition?.Name} ${selectedCompetition?.Year}`}

				{latestCompetitions.length > 0 && (
					<ExpandCircleDownIcon
						sx={{
							ml: 1,
							transform: transformString,
							transition: "100ms linear"
						}}
					/>
				)}
			</Button>

			<Menu
				id="selected-dropdown-menu"
				anchorEl={anchorEl}
				open={isMenuOpen}
				onClose={closeMenu}
				disableScrollLock={true}
			>
				{latestCompetitions.map((item: ICompetitionStructure) => (
					<MenuItem key={item.Id} onClick={() => onMenuItemClicked(item.Id)} sx={{ width: "17em" }}>
						{`${item?.Name} ${item?.Year}`}
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
};
