import { Athlete } from "../Shared/Athlete";
import { StrongmanEvent } from "./StrongmanEvent";
import { newGuid } from "../../../Utility/new-guild";
import { Stage } from "./Stage";

export class StageBuilder {
	private id: string = "";
	private name: string = "";
	private displayOrder: number = -1;
	private imagePath: string = "";
	private athletes: Array<Athlete> = [];
	private events: Array<StrongmanEvent> = [];

	private isFinal: boolean = false;

	public SetNameAndId(name: string, id?: string) {
		this.name = name;
		this.id = id ?? newGuid();
		return this;
	}

	public SetIsFinal(isFinal: boolean) {
		this.isFinal = isFinal;
		return this;
	}

	public SetDisplayOrder(displayOrder: number) {
		this.displayOrder = displayOrder;
		return this;
	}

	public WithAthletesAndEvents(athletes: Array<Athlete>, events: Array<StrongmanEvent>) {
		this.athletes = athletes;
		this.events = events;
		return this;
	}

	public Build(): Stage {
		return new Stage(
			this.id,
			this.athletes,
			this.events,
			this.name,
			this.isFinal,
			this.displayOrder
		);
	}
}
