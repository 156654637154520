import { Athlete } from "../Aggregates/Shared/Athlete";
import { immerable } from "immer";


export class AthletePosition {
	[immerable] = true;
	public readonly Athletes: Array<Athlete>;
	public readonly Position: number = -1;

	constructor(athletes: Array<Athlete>, position: number) {
		this.Athletes = athletes;
		this.Position = position;
	}
}
