import React from "react";
import { Box, Button, TextField } from "@mui/material";


interface TextBoxWithCopyButtonProps {
	text: string;
	multiline?: boolean;
	rows?: number;
}

export const TextBoxWithCopyButton = ({ text, multiline = false, rows = 0 }: TextBoxWithCopyButtonProps) => {
	const handleCopy = () => {
		navigator.clipboard.writeText(text);
	};

	return (
		<Box display="flex">
			<TextField
				value={text}
				InputProps={{
					readOnly: true,
				}}
				size="small"
				multiline={multiline}
				rows={rows}
				fullWidth
			/>
			<Button
				onClick={handleCopy}
				variant="contained"
				color="success"
			>
				Copy
			</Button>
		</Box>
	);
};
