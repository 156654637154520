import { Athlete } from "../../../../Domain/Aggregates/Shared/Athlete";
import { useStore } from "../../../../Store/useStore";
import { IProvidesFinalists } from "../../../../Domain/Aggregates/Shared/IProvidesFinalists";
import { Typography } from "@mui/material";
import React from "react";

import { AthleteNameText } from "./AthleteNameText";


export const ListOfAthletes = ({ athletes, stageId }: { athletes: Array<Athlete>, stageId: string }) => {

	const predictionForCurrentGroup = useStore((store) => store.getPredictionForStage(stageId)) as unknown as IProvidesFinalists;
	const finalists = predictionForCurrentGroup.GetFinalists();

	return (
		<Typography variant="body2" color="text.secondary">
			{athletes.map<React.ReactNode>((athlete: Athlete) =>
				<AthleteNameText
					key={athlete.Id}
					athleteName={athlete.AthleteName}
					isPredictedWinner={finalists.some(y => y.Id === athlete.Id)}
				/>
			).reduce((prev, curr) => [prev, ", ", curr])}
		</Typography>
	);
};
