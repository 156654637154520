import { PredictionTypeEnum } from "../../../Domain/Enums/PredictionTypeEnum";
import { LeaderboardPredictionDto } from "./LeaderboardPredictionDto";
import { EventsPredictionDto } from "./EventsPredictionDto";
import { AthletePosition } from "../../../Domain/ValueObjects/AthletePosition";
import { IAthletePositionDto } from "./IAthletePositionDto";


export class CompetitionPredictionDto {
	constructor(
		public readonly CompetitionId: number,
		public readonly PredictionId: string,
		public readonly LeaderboardPredictionDtos: Array<LeaderboardPredictionDto>,
		public readonly EventsPredictionDtos: Array<EventsPredictionDto>,
		public readonly FinalPlacings: Array<IAthletePositionDto>
	) {
		//
	}

	public get AllPredictionDtos(): Array<IStagePredictionDto> {
		return [...this.LeaderboardPredictionDtos, ...this.EventsPredictionDtos];
	}
}

export interface IStagePredictionDto {
	readonly StageId: string;
	readonly PredictionType: PredictionTypeEnum;
}

// export const mapCompetitionPredictionDtoToEntity = (dto: CompetitionPredictionDto, competitionStructure: CompetitionStructure) => {
// 	const
// }
