import { IAthletePositionDto } from "./IAthletePositionDto";
import { IStagePredictionDto } from "./CompetitionPredictionDto";
import { PredictionTypeEnum } from "../../../Domain/Enums/PredictionTypeEnum";


export class EventsPredictionDto implements IStagePredictionDto {
	constructor(
		public readonly StageId: string,
		public readonly PredictionType: PredictionTypeEnum,
		public readonly IndividualEvents: Array<SingleEventPredictionDto>
	) {
		//
	}
}

export class SingleEventPredictionDto {
	constructor(
		public readonly EventTypeName: string,
		public readonly AthletePositions: Array<IAthletePositionDto>
	) {
		//
	}
}
