import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../Store/useStore";
import toast from "react-hot-toast";


export const ProceedToFinalsDialog = React.memo(({ requiredFinalistsCount, actualFinalistsCount }: { requiredFinalistsCount: number, actualFinalistsCount: number }) => {

	const [hasToastBeenDisplayed, setHasToastBeenDisplayed] = useState(false);
	const haveEnoughFinalistsBeenSelected = actualFinalistsCount === requiredFinalistsCount;

	if(haveEnoughFinalistsBeenSelected && !hasToastBeenDisplayed) {
		toast.success(`${actualFinalistsCount} finalists selected`, {
			duration: 2000,
			position: "bottom-right",
			style: {
				marginTop: "3.5em",
				borderRadius: "10px",
				background: "#333",
				color: "#fff",
			}
		});
		setHasToastBeenDisplayed(true);
	}

	useEffect(() => {
		if(!haveEnoughFinalistsBeenSelected && hasToastBeenDisplayed) {
			setHasToastBeenDisplayed(false);
		}
	}, [actualFinalistsCount]);

	return (
		<Box>
			{actualFinalistsCount < requiredFinalistsCount ? (
				<LockCard requiredFinalistsCount={requiredFinalistsCount} />
			) : (
				<ProceedToFinalQuestion requiredFinalistsCount={requiredFinalistsCount} />
			)}
		</Box>
	);
});

const LockCard = React.memo(({ requiredFinalistsCount }: { requiredFinalistsCount: number }) => {
	//console.log("LockCard rendering...");

	return (
		<Card elevation={2} sx={{ maxWidth: "22.5em", margin: "auto", pt: 1 }}>
			<CardContent>
				<Typography
					variant="h3"
					component="header"
					textAlign="center"
					pb={2}
				>
					🔒
				</Typography>

				<Typography
					variant="subtitle2"
					component="header"
					color="warning.light"
					textAlign="center"
					px={2}
				>
					Please select {requiredFinalistsCount} finalists from the group stages before proceeding to the finals
				</Typography>
			</CardContent>
		</Card>
	);
});

const ProceedToFinalQuestion = React.memo(({ requiredFinalistsCount }: { requiredFinalistsCount: number }) => {
	//console.log("ProceedToFinalQuestion rendering...");

	const createPredictionForFinals = useStore((store) => store.createPredictionForFinals);

	return (
		<Card elevation={2} sx={{ maxWidth: "22.5em", margin: "auto", px: 2 }}>
			<CardContent>

				<Typography
					variant="body1"
					color="text.secondary"
					mb={3}
					mt={1}
				>
					You have selected {requiredFinalistsCount} finalists and are ready to proceed to the finals. Once you proceed, any
					changes made to the group stages will cause your final selections to be reset.
				</Typography>

				<Box sx={{ textAlign:"center" }}>
					<Button
						color="success"
						variant="contained"
						onClick={() => {
							createPredictionForFinals();
						}}
						sx={{ margin:"auto" }}
					>
						Proceed to Finals
					</Button>
				</Box>
			</CardContent>
		</Card>
	);
});
