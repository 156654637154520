import { produce } from "immer";
import { PredictionStoreState } from "../useStore";
import { LoggedInUser } from "../../ApplicationServices/Auth/LoggedInUser";


export type IAuthSlice = {
	loggedInUser: LoggedInUser | undefined;
	setLoggedInUser: (val: LoggedInUser | undefined) => void;

	willAuthenticate: boolean;
	setWillAuthenticate: (val: boolean) => void;

	hasAuthErrored: boolean;
	setHasAuthErrored: (val: boolean) => void;

	isUserAuthenticated: boolean;
	setIsUserAuthenticated: (isAuthenticated: boolean) => void;
}


// eslint-disable-next-line
export const createAuthSlice = (set: any, get: any): IAuthSlice => ({
	loggedInUser: undefined,
	setLoggedInUser: (val: LoggedInUser | undefined) => {
		set(
			produce((draft: PredictionStoreState) => {
				draft.loggedInUser = val;
			})
		);
	},

	willAuthenticate: false,
	setWillAuthenticate: (val: boolean) => {
		set(
			produce((draft: PredictionStoreState) => {
				draft.willAuthenticate = val;
			})
		);
	},

	hasAuthErrored: false,
	setHasAuthErrored: (val: boolean) => {
		set(
			produce((draft: PredictionStoreState) => {
				draft.hasAuthErrored = val;
			})
		);
	},

	isUserAuthenticated: false,
	setIsUserAuthenticated: (isAuthenticated: boolean) => {
		set(produce((draft: PredictionStoreState) => {
			draft.isUserAuthenticated = isAuthenticated;
		}));
	},
});
