import React from "react";
import { Paper } from "@mui/material";
import { ShareButton } from "./Settings/Sharing/ShareButton";
import { useStore } from "../../Store/useStore";
import { SettingsButtonContainer } from "./Settings/SettingsButtonContainer";
import { StatsToggleButton } from "./Settings/StatsToggleButton";


interface ICompetitionPaperProps {
	children: any;
	showShareButton?: boolean;
	showStatsButton?: boolean;
}

export const CompetitionPaper = ({ children, showShareButton = true, showStatsButton = true }: ICompetitionPaperProps) => {

	const isAuthenticated = useStore((state) => state.isUserAuthenticated);
	const isReadOnly = useStore((state) => state.isReadOnly);

	return (
		<Paper sx={{
			p: {
				xs: 2,
				sm: 4
			},
			position: "relative"
		}}>
			<SettingsButtonContainer>
				{showStatsButton && (
					<StatsToggleButton />
				)}

				{showShareButton && isAuthenticated && !isReadOnly && (
					<ShareButton />
				)}
			</SettingsButtonContainer>

			{children}
		</Paper>
	);
};


