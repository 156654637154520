import React from "react";
import { Box, Paper, TableContainer, Typography } from "@mui/material";
import { CircleFlag } from "react-circle-flags";
import { StagePointsTable, AthletePointsTableRow } from "../../../Domain/Aggregates/StagePrediction/Scoring/StagePointsTables";
import { basicRowStyle, gridTemplateColumnsFor4Cols } from "../../../Style/basicRowStyle";
import { CustomCell } from "../Table/CustomCell";

interface Props {
	pointsTable: StagePointsTable;
	title: string;
	text: string;
}

export const OverallPointsTableComponent = ({ pointsTable, title, text }: Props) => {
	//console.log("Rerendering GroupPointsTable");

	return (
		<Paper sx={{ pt: 1.5, pb: 1 }} elevation={4}>

			<TableContainer>
				<Box mb={1} ml={3}>
					<Typography
						variant="h6"
						component="header"
						color="warning.light"
						mb={1}
					>
						{title}
					</Typography>

					<Typography
						variant="body2"
						color="secondary.lighter"
						mb={1}
					>
						{text}
					</Typography>
				</Box>

				{pointsTable.AthletesWithPoints.map((x: AthletePointsTableRow, index: number) => (
					<Box
						key={x.Athlete.AthleteName}
						sx={{
							...basicRowStyle,
							...gridTemplateColumnsFor4Cols,
							borderBottom: "1px solid #515151",
							":last-of-type": {
								borderBottom: "none"
							},
						}}
					>
						<CustomCell sx={{ textAlign: "center" }}>{index + 1}</CustomCell>
						<CustomCell>
							<CircleFlag
								countryCode={x.Athlete.Country.CountryCode}
								height="17"
								style={{ marginBottom: "-0.15em" }}
							/>
						</CustomCell>
						<CustomCell>{x.Athlete.AthleteName}</CustomCell>
						<CustomCell>{x.Points}</CustomCell>
					</Box>
				))}
			</TableContainer>
		</Paper>
	);
};
