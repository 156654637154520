import React from "react";
import { Box } from "@mui/material";
import { useStore } from "../../../Store/useStore";


export const StatsIndicator = ({ percentage }: { percentage?: number; }) => {

	const statsEnabled = useStore((state) => state.statsEnabled);

	const isNumber = (x: any) => {
		if(x === undefined) return false;
		if(typeof x !== "number") return false;

		return true;
	};

	return (
		statsEnabled && isNumber(percentage) ? (
			<Box title={`${percentage?.toFixed(1)}% of people also selected this!`} sx={{
				bgcolor: "#121212",
				opacity: 0.5,
				borderRadius: "4px",
				p: "0.25em",
				boxSizing: "border-box"
			}}>
				<Box sx={{
					bgcolor: "#3b3b3b",
					justifyContent: "center",
					alignContent: "center",
					width: `${percentage}%`,
					pl: 1,
					borderRadius: "4px",
					whiteSpace: "nowrap",
					boxSizing: "border-box"
				}}>
					{percentage?.toFixed(1)}% 
				</Box>
			</Box>
		) : (
			<div>&nbsp;</div>
		)
	);
};
