import { Athlete } from "../../../Shared/Athlete";
import { ICommand } from "../Base/ModifyPredictionCommand";
import { Wsm2023Prediction } from "../../Wsm2023Prediction";
import { Wsm2023ModifyCommandFactory } from "./Wsm2023ModifyCommandFactory";
import { PredictionTypeEnum } from "../../../../Enums/PredictionTypeEnum";
import { ICompetitionPrediction } from "../../Interfaces/ICompetitionPrediction";
import { Asc2023ModifyCommandFactory } from "./Asc2023ModifyCommandFactory";
import { Asc2023Prediction } from "../../Asc2023Prediction";
import { CompetitionRuleset } from "../../../../Enums/CompetitionRuleset";


/**
 *
 * Abstract factory probably isn't what we want here. We need a factory to create the commands and then something to apply
 * the decorator when it's for Wsm2023. If we had one factory that took the competition as a command and had a strategy
 * inside it, that factory could have just one CreateAddAthleteCommand() and it would then call strategy.DoSomething(command),
 * with a strategy for Wsm2023, Asc2023 etc. The Wsm2023 strategy would wrap it in a decorator, the Asc2023 would just return
 * it. This will cut down code duplication which currently exists
 *
 */

export interface IModifyCommandAbstractFactory {
	CreateAddAthleteCommand(athlete: Athlete, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): ICommand;
	CreateChangePredictionTypeCommand(predictionType: PredictionTypeEnum, stageId: string, promptConfirmed: boolean): ICommand;
	CreateRemovePositionCommand(position: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): ICommand;
	CreateMovePositionCommand(indexFrom: number, indexTo: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): ICommand;
	CreateApplyTieCommand(indexFrom: number, indexTo: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): ICommand;
}

export const getModifyCommandFactory = (competition: ICompetitionPrediction): IModifyCommandAbstractFactory => {
	switch (competition.Competition.Ruleset) {
		case CompetitionRuleset.Wsm2023:
			return new Wsm2023ModifyCommandFactory(competition as Wsm2023Prediction);
		case CompetitionRuleset.Asc2023:
			return new Asc2023ModifyCommandFactory(competition as Asc2023Prediction);
		default:
			throw new Error(`Unsupported ruleset in getModifyCommandFactory: ${competition.Competition.Ruleset} for ${competition.Competition.Name}`);
	}
};
