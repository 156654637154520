import { StrongmanEventType } from "./StrongmanEventType";
import { immerable } from "immer";


export class StrongmanEvent {
	[immerable] = true;
	public Id: number;
	public StrongmanEventType: StrongmanEventType;
	public DisplayOrder: number;

	constructor(id: number, strongmanEventType: StrongmanEventType, displayOrder: number) {
		this.Id = id;
		this.StrongmanEventType = strongmanEventType;
		this.DisplayOrder = displayOrder;
	}
}
