import React, { useEffect, useState } from "react";
import { Button, Switch, SwitchProps, styled } from "@mui/material";
import { useStore } from "../../../Store/useStore";


export const StatsToggleButton = () => {

	const [toggleOn, setToggleOn] = useState(true);
	const setStatsEnabled = useStore((store) => store.setStatsEnabled);
	const localStorageKey = "FA_StatsEnabled";

	useEffect(() => {
		const previousValueStr = localStorage.getItem(localStorageKey);
		if (previousValueStr) {
			setToggleOn(previousValueStr === "true");
		}
	}, []);

	useEffect(() => {
		setStatsEnabled(toggleOn);
		localStorage.setItem(localStorageKey, toggleOn.toString());
	}, [toggleOn]);

	return (
		<Button
			color="secondary"
			variant="contained"
			sx={{ ml: 0.75 }}
			onClick={() => setToggleOn(!toggleOn)}
		>
			<IOSSwitch sx={{ m: 1 }} checked={toggleOn} />
			Stats
		</Button>
	);
};


// Copied from MUI "Switch" page; size reduced via ChatGPT
const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 32,  // Reduced width
	height: 18, // Reduced height
	padding: 0,
	marginTop: "0 !important",
	marginBottom: "0 !important",
	marginLeft: "0 !important",
	"& .MuiSwitch-switchBase": {
		padding: 2, // Adjusted padding
		margin: 0,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(14px)", // Adjusted transform
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 14,  // Reduced thumb size
		height: 14, // Reduced thumb size
	},
	"& .MuiSwitch-track": {
		borderRadius: 18 / 2, // Adjusted border radius
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

