import { immerable } from "immer";
import { StrongmanEventTypeEnum } from "../../Enums/StrongmanEventTypeEnum";
import { StrongmanEventTypeViewModel } from "../../../ApplicationServices/Queries/ViewModels/StrongmanEventTypeViewModel";

export class StrongmanEventType {
	[immerable] = true;
	public readonly Name: string;

	constructor(name: string) {
		this.Name = name;
	}

	public get IsStoneOff(): boolean {
		return this.Name === StrongmanEventTypeEnum.StoneOff;
	}

	public static create(data: StrongmanEventTypeViewModel): StrongmanEventType {
		return new StrongmanEventType(data.Name);
	}
}
