import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";
import { Box, Button, Tooltip, useTheme } from "@mui/material";
import { appendOrdinal, convertToStringWithCommasAndAnd } from "../../../Utility/utility.string";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import React from "react";
import { useStore } from "../../../Store/useStore";
import { ConfirmModalSettings } from "../../Global/ConfirmModalSettings";

interface TieIconProps {
	position: number;
	rowIndex: number;
	athletesToBeTied: Array<Athlete>;
	applyTieFn: (fromPosition: number, toPosition: number) => void;
}

export const TieIcon = React.memo(({ position, rowIndex, athletesToBeTied, applyTieFn }: TieIconProps) => {
	//console.log("... TieIcon rendered");

	const theme = useTheme();

	const setConfirmModalSettings = useStore((state) => state.setConfirmModalSettings);
	const setOnConfirmModalConfirmClicked = useStore((state) => state.setOnConfirmModalConfirmClicked);
	const closeModal = useStore((state) => state.closeModal);

	return (
		<Box sx={{
			position: "absolute",
			right: {
				xs: "3em",
				sm: "4em"
			},
			bottom: "-1.16em",
			zIndex: "100",
			opacity: 0.4,
			"&:hover": {
				opacity: 1
			}
		}}>
			<Tooltip title={`Tie for ${appendOrdinal(position)} position`} placement="right">
				<Button
					variant="contained"
					color="secondary"
					sx={{
						py: "0.25em",
						px: "0em",
						minWidth: "unset",
						"&:hover": {
							background: `${theme.palette.warning.main} !important`
						}
					}}
					onClick={() => {
						setConfirmModalSettings(new ConfirmModalSettings(
							true,
							"Confirm tie",
							[`Are you sure you want to tie ${convertToStringWithCommasAndAnd(athletesToBeTied.map((x: Athlete) => x.AthleteName))} for ${appendOrdinal(position)}?`]
						));

						setOnConfirmModalConfirmClicked(() => {
							closeModal();
							applyTieFn(rowIndex + 1, rowIndex);
						});
					}}
				>
					<AllInclusiveIcon sx={{ transform: "rotate(90deg)" }} />
				</Button>
			</Tooltip>
		</Box>
	);
});
