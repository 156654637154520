import {
	BaseEventPredictionsReadModel,
	BaseLeaderboardPredictionReadModel,
	BaseStagePredictionReadModel,
	mapEventPredictionsReadModelToEntity,
	mapGroupStageEventPredictionsReadModelToEntity,
	mapLeaderboardPredictionReadModelToEntity
} from "./StagePredictionReadModels";
import { CompetitionStructure } from "../../../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import { BaseCompetitionPrediction } from "../../../../Domain/Aggregates/CompetitionPrediction/BaseCompetitionPrediction";
import { competitionPredictionFactory } from "../../../../Domain/Aggregates/CompetitionPrediction/Factories/CompetitionPredictionFactory";
import { CompetitionRuleset } from "../../../../Domain/Enums/CompetitionRuleset";
import { IMultiStageCompetitionPrediction } from "../../../../Domain/Aggregates/CompetitionPrediction/Interfaces/ICompetitionPrediction";
import { PredictionTypeEnum } from "../../../../Domain/Enums/PredictionTypeEnum";
import { BaseStagePrediction } from "../../../../Domain/Aggregates/StagePrediction/BaseStagePrediction";
import { Athlete } from "../../../../Domain/Aggregates/Shared/Athlete";


export interface ILoadPredictionForUserReadModel {
	CompetitionPredictionId: string,
	CompetitionId: number,
	OwnerId: string
	LeaderboardPredictions: Array<BaseLeaderboardPredictionReadModel>;
	EventsPredictions: Array<BaseEventPredictionsReadModel>;
}


export const mapLoadPredictionForUserReadModelToBaseCompetitionPredictionEntity = (
	selectedCompetition: CompetitionStructure,
	competitionPredictionReadModel: ILoadPredictionForUserReadModel
): BaseCompetitionPrediction => {

	if(selectedCompetition.Ruleset == CompetitionRuleset.Wsm2023) {
		return mapLoadPredictionForUserReadModelToBaseCompetitionPredictionEntityForWsm23(selectedCompetition, competitionPredictionReadModel);
	} else if(selectedCompetition.Ruleset == CompetitionRuleset.Asc2023) {
		return mapLoadPredictionForUserReadModelToBaseCompetitionPredictionEntityForAsc23(selectedCompetition, competitionPredictionReadModel);
	} else {
		throw new Error(`No ILoadPredictionForUserReadModel mapper exists for ruleset ${selectedCompetition.Ruleset}`);
	}
};

const mapLoadPredictionForUserReadModelToBaseCompetitionPredictionEntityForAsc23 = (
	selectedCompetition: CompetitionStructure,
	competitionPredictionReadModel: ILoadPredictionForUserReadModel
): BaseCompetitionPrediction => {

	const finalStagePredictionReadModel = getFinalStagePredictionReadModel(competitionPredictionReadModel, selectedCompetition.Final.Id);

	if(!finalStagePredictionReadModel) throw new Error("Couldn't find final stage read model for Asc2023");

	const finalStagePrediction = mapFinalStagePredictionReadModelToEntity(selectedCompetition, finalStagePredictionReadModel, selectedCompetition.AllAthletes);

	return competitionPredictionFactory(
		selectedCompetition,
		[finalStagePrediction],
		competitionPredictionReadModel.CompetitionPredictionId
	);
};

const mapLoadPredictionForUserReadModelToBaseCompetitionPredictionEntityForWsm23 = (
	selectedCompetition: CompetitionStructure,
	competitionPredictionReadModel: ILoadPredictionForUserReadModel
): BaseCompetitionPrediction => {

	// Load the leaderboard and event predictions
	const groupLeaderboardPredictions = mapGroupStageLeaderboardPredictions(competitionPredictionReadModel.LeaderboardPredictions, selectedCompetition);
	const groupEventPredictions = mapGroupStageEventPredictions(competitionPredictionReadModel.EventsPredictions, selectedCompetition);

	// Then create an initial CompetitionPrediction so we can generate finalists
	const competitionPrediction = competitionPredictionFactory(
		selectedCompetition,
		[...groupLeaderboardPredictions, ...groupEventPredictions],
		competitionPredictionReadModel.CompetitionPredictionId
	);

	const finalStagePredictionReadModel = getFinalStagePredictionReadModel(competitionPredictionReadModel, selectedCompetition.Final.Id);

	// Then recreate it if it has finalists. We have to do this because we need to supply the initial list of athletes in the stage. See docs
	if (!doesRulesetHaveFinalists(selectedCompetition.Ruleset) || !finalStagePredictionReadModel) {
		return competitionPrediction;
	}

	const finalStagePrediction = recreateFinalStagePredictionWithFinalists(
		selectedCompetition,
		competitionPrediction as unknown as IMultiStageCompetitionPrediction,
		finalStagePredictionReadModel
	);

	// now use that to create the complete CompetitionPrediction
	return competitionPredictionFactory(
		selectedCompetition,
		[...groupLeaderboardPredictions, ...groupEventPredictions, finalStagePrediction],
		competitionPredictionReadModel.CompetitionPredictionId
	);
};

const getFinalStagePredictionReadModel = (
	competitionPredictionReadModel: ILoadPredictionForUserReadModel,
	finalStageId: string
): BaseStagePredictionReadModel | undefined => {
	const allFinalStagePredictionReadModels = [
		...competitionPredictionReadModel.LeaderboardPredictions,
		...competitionPredictionReadModel.EventsPredictions
	];

	return allFinalStagePredictionReadModels.find(x => x.StageId === finalStageId);
};

const doesRulesetHaveFinalists = (ruleset: CompetitionRuleset | undefined) => {
	if (!ruleset) throw new Error("doesRulesetHaveFinalists() was called with a null parameter");

	switch (ruleset) {
		case CompetitionRuleset.Wsm2023: {
			return true;
		}
		default: {
			return false;
		}
	}
};

const recreateFinalStagePredictionWithFinalists = (
	selectedCompetition: CompetitionStructure,
	competitionPrediction: IMultiStageCompetitionPrediction,
	finalStagePredictionReadModel: BaseStagePredictionReadModel
): BaseStagePrediction => {

	return mapFinalStagePredictionReadModelToEntity(
		selectedCompetition,
		finalStagePredictionReadModel,
		competitionPrediction.GetFinalists()
	);
};

const mapFinalStagePredictionReadModelToEntity = (
	selectedCompetition: CompetitionStructure,
	finalStagePredictionReadModel: BaseStagePredictionReadModel,
	athletes: Array<Athlete>
) =>{
	if (finalStagePredictionReadModel.PredictionType === PredictionTypeEnum.Leaderboard) {
		return mapLeaderboardPredictionReadModelToEntity(
			selectedCompetition,
			athletes,
			finalStagePredictionReadModel as BaseLeaderboardPredictionReadModel
		);
	} else {
		const finalEventPrediction = mapEventPredictionsReadModelToEntity(
			selectedCompetition,
			athletes,
			finalStagePredictionReadModel as BaseEventPredictionsReadModel
		);

		finalEventPrediction.UpdatePointsTable();

		return finalEventPrediction;
	}
};

const mapGroupStageLeaderboardPredictions = (
	leaderboardPredictionReadModels: Array<BaseLeaderboardPredictionReadModel>,
	selectedCompetition: CompetitionStructure
): Array<BaseStagePrediction> => {
	return leaderboardPredictionReadModels
		.filter(x => !selectedCompetition.GetStage(x.StageId).IsFinal)
		.map(x => mapLeaderboardPredictionReadModelToEntity(
			selectedCompetition,
			selectedCompetition.GetStage(x.StageId).Athletes,
			x
		));
};

const mapGroupStageEventPredictions = (
	eventPredictionsReadModels: Array<BaseEventPredictionsReadModel>,
	selectedCompetition: CompetitionStructure
): Array<BaseStagePrediction> => {

	const eventPredictions = eventPredictionsReadModels
		.filter(x => !selectedCompetition.GetStage(x.StageId).IsFinal)
		.map(x => mapGroupStageEventPredictionsReadModelToEntity(
			selectedCompetition,
			selectedCompetition.GetStage(x.StageId).Athletes,
			x
		));
	eventPredictions.forEach(x => x.UpdatePointsTable());

	return eventPredictions;
};
