import { msalInstance } from "../../App";
import { requestScopes } from "../../Config/auth-config";


export const performAuthenticatedRequest = async <TData, TResponse>(method: string, endpoint: string, data?: TData): Promise<TResponse> => {
	const { BearerToken, UserId } = await getBearerTokenForAuthorizationHeader();

	const options: RequestInit = {
		method,
		headers: new Headers({
			"Authorization": BearerToken,
			"Content-Type": "application/json"
		})
	};

	if (data) {
		options.body = JSON.stringify(data);
	}

	const baseUrl = `${process.env.REACT_APP_apiBaseUri}/api/${endpoint}`;
	const url = method === "GET"
		? `${baseUrl}/user/${UserId}`
		: `${baseUrl}`;

	try {
		const response = await fetch(url, options);

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const jsonResponse = await response.json();
		return jsonResponse as TResponse;
	} catch (error) {
		console.error(`Error thrown in performAuthenticatedRequest(): ${url}`);
		console.log(error);
		throw error;
	}
};

export const performAuthenticatedGet = async (endpoint: string) => performAuthenticatedRequest("GET", endpoint);

export const performAuthenticatedPost = async <TData, TResponse>(endpoint: string, data: TData): Promise<TResponse> => performAuthenticatedRequest("POST", endpoint, data);

export interface ISaveResult {
	IsSuccessful: boolean;
	ErrorMessage: string;
}

interface IBearerTokenAndUserId {
	BearerToken: string;
	UserId: string;
}

const getBearerTokenForAuthorizationHeader = async (): Promise<IBearerTokenAndUserId> => {
	const account = msalInstance.getActiveAccount();
	if (!account) {
		throw Error("No active account! Verify a user has been signed in before making an authenticated request.");
	}

	const response = await msalInstance.acquireTokenSilent({
		...requestScopes,
		account: account
	});

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const userId = response.idTokenClaims["sub"];

	return {
		BearerToken: `Bearer ${response.accessToken}`,
		UserId: userId
	};
};
