import { BaseStagePrediction } from "../BaseStagePrediction";
import { PredictionTypeEnum } from "../../../Enums/PredictionTypeEnum";
import { SingleEventPrediction } from "./SingleEventPrediction";
import { StagePointsTable } from "../Scoring/StagePointsTables";
import { Stage } from "../../CompetitionStructure/Stage";
import { Athlete } from "../../Shared/Athlete";
import { PointsCalculationStrategyFactory } from "../Scoring/CalculationStrategies/PointCalculationStrategy";
import {
	SingleEventPredictionGenerationStrategyFactory,
	ISingleEventPredictionGenerationStrategy
} from "./SingleEventPredictionStrategies/SingleEventPredictionGenerationStrategy";
import { CompetitionRuleset } from "../../../Enums/CompetitionRuleset";
import { RankedAthleteCollection } from "../RankedAthleteCollection";
import { AthletePosition } from "../../../ValueObjects/AthletePosition";
import { IPointCalculationStrategy } from "../Scoring/CalculationStrategies/BasePointCalculationStrategy";


export class EventPredictions extends BaseStagePrediction {

	public override readonly PredictionType: PredictionTypeEnum = PredictionTypeEnum.Events;
	public override readonly IsFinal: boolean = true;
	public EventPredictions: Array<SingleEventPrediction>;
	public PointsTable: StagePointsTable;

	protected readonly _singleEventPredictionGenerationStrategy: ISingleEventPredictionGenerationStrategy;
	protected readonly _pointsTableCalculationStrategy: IPointCalculationStrategy;

	public constructor(stage: Stage, ruleset: CompetitionRuleset, competingAthletes: Array<Athlete>, existingPredictions: Array<ExistingEventPredictionDto> = []) {
		super(stage.Id, ruleset, competingAthletes);

		this._singleEventPredictionGenerationStrategy = SingleEventPredictionGenerationStrategyFactory.Create(ruleset);
		this.EventPredictions = this._singleEventPredictionGenerationStrategy.Generate(stage, competingAthletes);

		this._pointsTableCalculationStrategy = PointsCalculationStrategyFactory.Create(stage, ruleset, competingAthletes);
		this.PointsTable = this._pointsTableCalculationStrategy.Calculate(this.EventPredictions);

		for (const dto of existingPredictions) {
			const prediction = this.EventPredictions.find(x => x.StrongmanEvent.Id === dto.StrongmanEventId);

			if(prediction) {
				prediction.Rankings = dto.RankedAthleteCollection;
			}
		}
	}

	public HaveSelectionsBeenMade(): boolean {
		return this.EventPredictions.some(x => x.Rankings.AthletePositions.length > 0);
	}

	public AddAthlete(athlete: Athlete, strongmanEventId: number): boolean {
		const prediction = this.GetPredictionByStrongmanEventId(strongmanEventId);
		const res = prediction.AddAthlete(athlete);

		this.UpdatePointsTable(); //observer
		return res;
	}

	public RemovePosition(positionToRemove: number, strongmanEventId: number): boolean {
		const prediction = this.GetPredictionByStrongmanEventId(strongmanEventId);
		const res = prediction.RemoveAthlete(positionToRemove);

		this.UpdatePointsTable(); //observer

		return res;
	}

	public MoveAthlete(fromIndex: number, toIndex: number, strongmanEventId: number): boolean {
		const prediction = this.GetPredictionByStrongmanEventId(strongmanEventId);
		const res = prediction.MoveAthlete(fromIndex, toIndex);

		this.UpdatePointsTable(); // TODO: setup observer

		return res;
	}

	public ApplyTie(indexFrom: number, indexTo: number, strongmanEventId: number): boolean {
		const eventPrediction = this.GetPredictionByStrongmanEventId(strongmanEventId);
		const res = eventPrediction.ApplyTie(indexFrom, indexTo);

		this.UpdatePointsTable(); // TODO: setup observer

		return res;
	}

	public GetPredictionByStrongmanEventId(strongmanEventId: number): SingleEventPrediction {
		const prediction = this
			.EventPredictions
			.find((x: SingleEventPrediction) => x.StrongmanEvent.Id === strongmanEventId);

		if (!prediction) throw new Error(`No prediction found for eventId: ${strongmanEventId}`);

		return prediction;
	}

	public UpdatePointsTable(): void {
		this.PointsTable = this._pointsTableCalculationStrategy.Calculate(this.EventPredictions);
	}

	public GetRankings(): Array<AthletePosition> {
		return this
			.PointsTable
			.SortedAthletesWithPoints
			.filter(x => x.Points > 0)
			.map(x => new AthletePosition([x.Athlete], x.Position));
	}
}

export class ExistingEventPredictionDto {
	constructor(
		public readonly StrongmanEventId: number,
		public readonly RankedAthleteCollection: RankedAthleteCollection
	) {
		//
	}
}
