import { immerable } from "immer";

export class CanExecuteResponse {
	[immerable] = true;
	public ExecutionSuccess: boolean = false;

	constructor(
		public CanExecute: boolean = false,
		public PromptUserToConfirm: boolean = false,
		public PromptMessages: Array<string> = [],
	) {
		// empty ctor
	}

	public AddPromptMessage(msg: string): boolean {
		return this.PromptMessages.push(msg) > 0;
	}
}
