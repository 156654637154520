import { mapStrongmanEventViewModelToEntity, StrongmanEventViewModel } from "./StrongmanEventViewModel";
import { AthleteViewModel, mapAthleteViewModelToEntity } from "./AthleteViewModel";
import { Stage } from "../../../Domain/Aggregates/CompetitionStructure/Stage";
import { StageBuilder } from "../../../Domain/Aggregates/CompetitionStructure/StageBuilder";


export interface StageViewModel {
	Id: string;
	Name: string;
	DisplayOrder: number;
	IsFinal: boolean;
	Athletes: Array<AthleteViewModel>;
	Events: Array<StrongmanEventViewModel>;
}

export const mapStageViewModelToEntity = (viewModel: StageViewModel): Stage => {
	const athletes = viewModel.Athletes.map(x => mapAthleteViewModelToEntity(x));
	const events = viewModel.Events.map(x => mapStrongmanEventViewModelToEntity(x));

	return new StageBuilder()
		.SetNameAndId(viewModel.Name, viewModel.Id)
		.SetIsFinal(viewModel.IsFinal)
		.WithAthletesAndEvents(athletes, events)
		.SetDisplayOrder(viewModel.DisplayOrder)
		.Build();
};
