import { useStore } from "../Store/useStore";
import { useEffect, useState } from "react";
import { loadPredictionsForUserQuery } from "../ApplicationServices/Queries/LoadPredictionsForUserQuery/loadPredictionsForUserQuery";
import { commitCompetitionPrediction } from "../ApplicationServices/Caching/SessionStorageService";
import { useAutoSave } from "./useAutoSave";


export const useLoadPredictionsForLoggedInUser = () => {

	const [areExistingPredictionsLoading, setAreExistingPredictionsLoading] = useState<boolean>(false);
	const [haveExistingPredictionsLoaded, setHaveExistingPredictionsLoaded] = useState<boolean>(false);

	const loggedInUser = useStore((state) => state.loggedInUser);
	const willAuthenticate = useStore((state) => state.willAuthenticate);
	const isAuthenticated = useStore((state) => state.isUserAuthenticated);
	const hasAuthErrored = useStore((state) => state.hasAuthErrored);

	const selectedCompetition = useStore((state) => state.selectedCompetition);
	const setCompetitionPrediction = useStore((state) => state.setCompetitionPrediction);

	useAutoSave(haveExistingPredictionsLoaded);

	useEffect(() => {
		if (isAuthenticated && !!selectedCompetition && !areExistingPredictionsLoading) {
			loadPredictionsForUser();
		}
	}, [isAuthenticated, loggedInUser, selectedCompetition]);

	const loadPredictionsForUser = async () => {
		setAreExistingPredictionsLoading(true);

		if (!selectedCompetition) {
			console.error("selectedCompetition is null when loadPredictionsForUser() is called");
			return;
		}

		const competitionPrediction = await loadPredictionsForUserQuery(selectedCompetition);

		if(competitionPrediction) {
			setCompetitionPrediction(competitionPrediction);
			commitCompetitionPrediction(competitionPrediction);
		}

		setAreExistingPredictionsLoading(false);
		setHaveExistingPredictionsLoaded(true);
	};

	return {
		willAttemptToLoadExistingPredictions: willAuthenticate && !hasAuthErrored,
		areExistingPredictionsLoading,
		haveExistingPredictionsLoaded
	};
};
