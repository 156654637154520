import React from "react";
import { Box, Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import { EventTitle } from "../../PredictionSelection/IndividualEvents/EventTitle";
import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";
import { CircleFlag } from "react-circle-flags";
import questionMarkCircleIcon from "../../../Files/question-mark-circle-icon.png";

// TODO: The FinalistsCard here contains the athlete text which is near-identical to the text in the SelectedFinalistsCard,
// i.e. how we display the athlete names on mobile (whitespaec:nowrap etc). Maybe extract it to a component

export const FinalistsForStageCard = ({ pointsWinner, stoneOffWinner }: { pointsWinner?: Athlete, stoneOffWinner?: Athlete }) => {
	return (
		<Paper sx={{ pt: 1.5, pb: 1.5 }} elevation={2}>
			<Box sx={{ px: 2 }}>
				<EventTitle title="Finalists"  color="warning.light" />
			</Box>

			<Grid container columnSpacing={2} rowSpacing={2} px={1} textAlign="center" justifyContent="center">
				<Grid item md={5} lg={3.5}>
					<FinalistCard athlete={pointsWinner} />
				</Grid>
				<Grid item md={5} lg={3.5}>
					<FinalistCard athlete={stoneOffWinner} />
				</Grid>
			</Grid>
		</Paper>
	);
};

const FinalistCard = ({ athlete }: { athlete: Athlete | undefined }) => {
	return (
		<Card elevation={5} title={athlete?.AthleteName ?? ""}>
			<CardContent sx={{ pb: "1em !important" }}>

				<Box textAlign="center" mb={1.5}>
					{athlete ? (
						<CircleFlag
							countryCode={athlete.Country.CountryCode}
							height="25"
							title={athlete.Country.Name}
						/>
					) : (
						<img src={questionMarkCircleIcon} style={{ height: "25px" }} />
					)}
				</Box>

				<Typography
					variant="subtitle2"
					component="div"
					textAlign="center"
					overflow="hidden"
					textOverflow="ellipsis"
					maxHeight="3em"
					sx={{
						"@media (max-width: 900px)": {
							whiteSpace: "nowrap"
						}
					}}
				>
					{athlete?.AthleteName ?? "To be decided"}
				</Typography>

			</CardContent>
		</Card>
	);
};
