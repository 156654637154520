import React from "react";
import { Box, Chip, Stack } from "@mui/material";
import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";
import { CircleFlag } from "react-circle-flags";


interface Props {
	unselectedAthletes: Array<Athlete>;
	onClick: (athlete: Athlete) => void;
	justifyContent?: string;
}

export const AthleteSelectionChips = React.memo(({ unselectedAthletes, onClick, justifyContent = "center" }: Props) => {
	//console.log(".....AthleteSelectionChips rendering");

	return (
		<Stack
			direction={{ xs: "column", sm: "row" }}
			spacing={1}
			justifyContent={justifyContent}
			flexWrap="wrap"
			sx={{
				maxWidth: {
					xs: "100%",
					md: "50em"
				},
				margin: "auto",
				mb: {
					xs: 1,
					sm: 0
				}
			}}
		>
			{unselectedAthletes.map(athlete => (
				<AthleteChip
					key={athlete.Id}
					athlete={athlete}
					onClick={onClick}
				/>
			))}
		</Stack>
	);
}, (prevProps, nextProps) => {
	return areUnselectedAthletesIdentical(prevProps.unselectedAthletes, nextProps.unselectedAthletes);
});


interface AthleteChipProps {
	athlete: Athlete;
	onClick: (athlete: Athlete) => void;
}

export const AthleteChip = ({ athlete, onClick }: AthleteChipProps) => {
	return (
		<Chip
			key={athlete.AthleteName}
			label={athlete.AthleteName}
			icon={
				<Box component="span" sx={{ pt: "0.25em", width: "1.25em" }}>
					<CircleFlag
						countryCode={athlete.Country.CountryCode}
						height="17"
					/>
				</Box>
			}
			onClick={() => onClick(athlete)}
			sx={{
				mb: {
					xs: "none",
					sm: "0.5em !important"
				},
				ml: "0 !important",
				mr: "0.5rem !important"
			}}
		/>
	);
};

const areUnselectedAthletesIdentical = (prevUnselectedAthletes: Array<Athlete>, newUnselectedAthletes: Array<Athlete>) => {
	const previousUnselectedAthleteIds = prevUnselectedAthletes.flatMap(x => x.Id).join("");
	const newUnselectedAthleteIds = newUnselectedAthletes.flatMap(x => x.Id).join("");

	return previousUnselectedAthleteIds === newUnselectedAthleteIds;
};
