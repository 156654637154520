import { BaseStagePrediction } from "../StagePrediction/BaseStagePrediction";
import { CompetitionStructure } from "../CompetitionStructure/CompetitionStructure";
import { PredictionTypeEnum } from "../../Enums/PredictionTypeEnum";
import { Athlete } from "../Shared/Athlete";
import { StagePredictionFactory } from "../StagePrediction/StagePredictionFactory";
import { Stage } from "../CompetitionStructure/Stage";
import { IMultiStageCompetitionPrediction } from "./Interfaces/ICompetitionPrediction";
import { BaseCompetitionPrediction } from "./BaseCompetitionPrediction";
import { IProvidesFinalists } from "../Shared/IProvidesFinalists";
import { GroupStageEventPredictions } from "../StagePrediction/EventPredictions/GroupStageEventPredictions";


export class Wsm2023Prediction extends BaseCompetitionPrediction implements IMultiStageCompetitionPrediction {
	public override Predictions: Array<BaseStagePrediction>;
	public override SelectedGroup: Stage;
	public readonly RequiredFinalistsCount: number = 10;
	public IsFinalStageActive: boolean = false;

	constructor(competition: CompetitionStructure, existingPredictions?: Array<BaseStagePrediction>, existingId?: string) {
		super(competition, existingId);

		// Create or use existing predictions for stages in heats
		this.Predictions = competition.Heats.map((stage: Stage) => {
			const existingPrediction = existingPredictions?.find(ep => ep.StageId === stage.Id);

			return existingPrediction
				? existingPrediction
				: new GroupStageEventPredictions(stage, competition.Ruleset, stage.Athletes);
		});

		// Include existing predictions for final
		if (existingPredictions) {
			const finalPrediction = existingPredictions.find(x => !competition.Heats.some(heat => heat.Id === x.StageId));

			if (finalPrediction) {
				this.Predictions.push(finalPrediction);
				this.IsFinalStageActive = true;
			}
		}

		this.SelectedGroup = this.Competition.Heats.sort((x: Stage, y: Stage) => x.DisplayOrder - y.DisplayOrder)[0];
	}

	public SetSelectedGroup(stage: Stage): void {
		if (!this.Competition.ContainsStage(stage.Id)) {
			throw new Error(`Cannot set stage ID ${stage.Id} (${stage.Name}) as the selected group as it doesn't exist in competition ${this.Competition.Name}${this.Competition.Year}`);
		}

		if (stage.IsFinal) {
			throw new Error(`Stage ${stage.Id} cannot be called in SetSelectedGroup because it's a final`);
		}

		this.SelectedGroup = stage;
	}

	public GetFinalists(): Array<Athlete> {
		return this.Predictions
			.filter(x => "GetFinalists" in x) // TODO: Check this works
			.flatMap(x => {
				const y = x as unknown as IProvidesFinalists;
				return y.GetFinalists();
			});
	}

	public override CreatePredictionFor(stageId: string, predictionType: PredictionTypeEnum): boolean {
		const stage = this.Competition.GetStage(stageId);

		// Prevent multiple predictions for the same stage
		if (this.Predictions.some(x => x.StageId === stageId)) {
			this.Predictions = this.Predictions.filter(x => x.StageId !== stageId);
		}

		const athletes = stage.IsFinal
			? this.GetFinalists()
			: stage.Athletes;

		return this.Predictions.push(
			StagePredictionFactory.CreatePredictionFor(stage, athletes, predictionType, this.Competition.Ruleset)
		) > 0;
	}

	public CreatePredictionForFinals() {
		const finalistsCount = this.GetFinalists().length;

		if (finalistsCount < this.RequiredFinalistsCount) {
			throw new Error(`Cannot create finals prediction with only ${finalistsCount} finalists selected (required: ${this.RequiredFinalistsCount})`);
		}

		this.CreatePredictionFor(this.Competition.Final.Id, PredictionTypeEnum.Events);
		this.IsFinalStageActive = true;
	}

	public DeleteFinalsPrediction(){
		this.Predictions = this.Predictions.filter(x => !x.IsFinal);
	}
}
