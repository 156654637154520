import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { LoggedInUser } from "../ApplicationServices/Auth/LoggedInUser";
import { useStore } from "../Store/useStore";
import { requestScopes } from "../Config/auth-config";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useInitialUserSetup } from "./useInitialUserSetup";


interface UseLoggedInUserReturn {
	isAuthenticated: boolean;
	loggedInUser: LoggedInUser | undefined;
	willAuthenticate: boolean;
	hasAuthErrored: boolean;
}

export const useLoggedInUser = () => {
	const account = useAccount();
	const isAuthenticated = useIsAuthenticated();
	const { instance, inProgress } = useMsal();

	const [willAuthenticateInternal, setWillAuthenticateInternal] = useState<boolean>(false);
	const [isUserAuthenticatedInternal, setIsUserAuthenticatedInternal] = useState<boolean>(false);

	const setIsUserAuthenticated = useStore((state) => state.setIsUserAuthenticated);
	const loggedInUser = useStore((state) => state.loggedInUser);
	const setLoggedInUser = useStore((state) => state.setLoggedInUser);
	const setHasAuthErrored = useStore((state) => state.setHasAuthErrored);
	const willAuthenticate = useStore((state) => state.willAuthenticate);
	const setWillAuthenticate = useStore((state) => state.setWillAuthenticate);

	const isUserSetup = useInitialUserSetup(loggedInUser);

	React.useEffect(() => {
		handleAuth();
	}, [isAuthenticated, account]);

	useEffect(() => {
		setWillAuthenticate(!!account || willAuthenticateInternal);
	}, [willAuthenticateInternal]);

	useEffect(() => {
		setIsUserAuthenticated(isAuthenticated && isUserSetup);
	}, [isUserAuthenticatedInternal, isUserSetup]);

	/**
	 * When user first signs in, we can detect whether they have an account based on `inProgress` value being set to
	 * certain values. The ultimate issue is that we have no reliable way of knowing whether a user exists in the cache
	 * that MSAL needs to authenticate. The `account` and `isAuthenticated` won't return a value until MSAL has verified
	 * them against the backend and MSAL doesn't report whether this is happening very well. This issue only happens when
	 * a user first signs in, I assume because `acquireTokenSilently()`has not yet been called and cached a token yet
	 */
	React.useEffect(() => {
		if(!willAuthenticate) {
			if(inProgress === InteractionStatus.AcquireToken ||
				inProgress === InteractionStatus.SsoSilent ||
				inProgress === InteractionStatus.HandleRedirect) {
				setWillAuthenticateInternal(true);
			}
		}
	}, [inProgress]);

	const handleAuth = async () => {
		if (isAuthenticated && !loggedInUser) {
			if(await checkTokenIsValid()) {
				setWillAuthenticateInternal(true);
				const newUser = LoggedInUser.Create(account);
				setLoggedInUser(newUser);
				setIsUserAuthenticatedInternal(isAuthenticated);
			}
		}
	};

	const checkTokenIsValid = async () => {
		try {
			if(!account) {
				console.warn("`account` is not set in validateToken()");
				return false;
			}

			await instance.acquireTokenSilent({
				...requestScopes,
				account: account
			});

			return true;
		} catch (error) {
			console.log("error thrown in checkTokenIsValid()");
			console.log(error);

			if (error instanceof InteractionRequiredAuthError) {
				console.log("This InteractionRequiredAuthError error is being thrown and react-msal is failing to handle refesh tokens. Log the user out.");
				// instance.loginRedirect();
				try {
					// NOTE: I removed the `await` from the line below as on prod the error isnt being thrown and its still working as expected
					instance.logout({
						onRedirectNavigate: () => false
					});
				} catch(e) {
					console.log("Even instance.logout() is failing");
					console.log(e);
				}
			} else {
				console.log("Other error was thrown. I tried previously using the instance.logout() method, no luck ");
			}

			setWillAuthenticateInternal(false);
			setLoggedInUser(undefined);
			setHasAuthErrored(true);

			return false;
		}
	};
};
