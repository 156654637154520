import { EventPredictions, ExistingEventPredictionDto } from "./EventPredictions";
import { Stage } from "../../CompetitionStructure/Stage";
import { Athlete } from "../../Shared/Athlete";
import { EventPredictionFinalistsSelectionStrategyFactory, IEventPredictionFinalistsSelectionStrategy } from "./FinalistSelectionStrategies/EventPredictionFinalistsSelectionStrategy";
import { EventUpdaterOnPointsChangeStrategyFactory, IEventUpdaterOnPointsChangeStrategy } from "./EventPredictionUpdater/EventUpdaterOnPointsChangeStrategy";
import { IProvidesFinalists } from "../../Shared/IProvidesFinalists";
import { CompetitionRuleset } from "../../../Enums/CompetitionRuleset";


export class GroupStageEventPredictions extends EventPredictions implements IProvidesFinalists {

	public override readonly IsFinal: boolean = false;

	private readonly _finalistsSelectionStrategy: IEventPredictionFinalistsSelectionStrategy;
	private readonly _eventUpdaterOnPointsChangeStrategy: IEventUpdaterOnPointsChangeStrategy;

	constructor(stage: Stage, ruleset: CompetitionRuleset, competingAthletes: Array<Athlete>, existingPredictions: Array<ExistingEventPredictionDto> = []) {
		super(stage, ruleset, competingAthletes, existingPredictions);

		this._finalistsSelectionStrategy = EventPredictionFinalistsSelectionStrategyFactory.Create(ruleset);
		this._eventUpdaterOnPointsChangeStrategy = EventUpdaterOnPointsChangeStrategyFactory.Create(ruleset);
	}

	public GetFinalists(): Array<Athlete> {
		return this
			._finalistsSelectionStrategy
			.GetFinalists(this.EventPredictions, this.PointsTable);
	}

	public override UpdatePointsTable() {
		super.UpdatePointsTable();
		this.EventPredictions = this._eventUpdaterOnPointsChangeStrategy.UpdateEventPredictionss(
			this.EventPredictions,
			this.PointsTable
		);
	}
}
