import { Button } from "@mui/material";
import React from "react";


export const EditButton = ({ onClick } : { onClick: () => void }) => {
	return (
		<Button
			variant="text"
			color="warning"
			sx={{
				position: "absolute",
				bottom: 0,
				right: 0,
				fontSize: "0.7rem",
				px: "0.5rem",
				minWidth: "unset"
			}}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				onClick();
			}}
		>
			Edit
		</Button>
	);
};
