import React from "react";
import { DragDropContext, Droppable, DroppableProvided } from "react-beautiful-dnd";
import { AthletePosition } from "../../../Domain/ValueObjects/AthletePosition";
import { DraggableRow } from "./DraggableRow";
import { RowMessage } from "./RowMessage";
import { TableContainerWrapper } from "./TableContainerWrapper";
import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";

interface Props {
	rows: Array<AthletePosition>;
	nextPositionToSelect: number;
	emptyRowsToRender: number;
	removeRowFn: (position: number) => void;
	moveAthlete: (fromPosition: number, toPosition: number) => void;
	areTiesAllowed: boolean;
	applyTieFn?: (fromPosition: number, toPosition: number) => void;
	getStatsPercentageFor: (athletes: Athlete[], position: number) => number | undefined;
}

export const AthleteOrderingTable = React.memo(({
	rows,
	nextPositionToSelect,
	emptyRowsToRender,
	removeRowFn,
	moveAthlete,
	areTiesAllowed,
	applyTieFn,
	getStatsPercentageFor
}: Props) => {
	//console.log(".....AthleteOrderingTable rendered");

	const onDragEnd = (result: any) => {
		if (result.destination && result.draggableId) {
			moveAthlete(Number(result.draggableId), result.destination.index);
		}
	};

	const getAthletesInPositionAfter = React.useCallback((index: number) => {
		const nextIndex = index + 1;

		if (rows.length >= (nextIndex + 1)) {
			return rows[nextIndex].Athletes;
		} else {
			return [];
		}
	}, [rows]);

	const emptyArray = React.useMemo(() => [], []);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="list">
				{(provided: DroppableProvided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>

						<TableContainerWrapper rowCount={rows.length + emptyRowsToRender}>
							{rows.map((row: AthletePosition, index: number) => (
								<DraggableRow
									key={`draggable-row-${index}`}
									rowIndex={index}
									athletes={row.Athletes}
									position={row.Position}
									athletesOnNextRow={getAthletesInPositionAfter(index)}
									nextPositionToSelect={nextPositionToSelect}
									removeRowFn={removeRowFn}
									areTiesAllowed={(index < rows.length - 1) && areTiesAllowed}
									applyTieFn={applyTieFn}
									getStatsPercentageFor={getStatsPercentageFor}
								/>
							))}

							{Array.from(Array(emptyRowsToRender)).map((x, index) => (
								<DraggableRow
									key={`undraggable-row-${index + rows.length + 1}`}
									rowIndex={index + rows.length}
									athletes={emptyArray}
									position={nextPositionToSelect + index}
									nextPositionToSelect={nextPositionToSelect}
									areTiesAllowed={false}
									getStatsPercentageFor={getStatsPercentageFor}
								/>
							))}
						</TableContainerWrapper>

						{(rows.length === 0 && emptyRowsToRender === 0) && (
							<RowMessage
								text="Awaiting athletes"
								sx={{ pb: 2 }}
							/>
						)}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
});

