import { CompetitionRuleset } from "../../../Domain/Enums/CompetitionRuleset";
import { mapStageViewModelToEntity, StageViewModel } from "./StageViewModel";
import { CompetitionStructure } from "../../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";


export interface CompetitionViewModel {
	Id: number;
	Name: string;
	Year: number;
	Stages: Array<StageViewModel>;
	Heats: Array<StageViewModel>;
	Ruleset: CompetitionRuleset;
}

export const mapCompetitionViewModelToEntity = (viewModel: CompetitionViewModel): CompetitionStructure => {
	const stages = [];

	if (viewModel.Heats.length > 0) {
		const groupStages = viewModel.Heats.map(x => mapStageViewModelToEntity(x));
		stages.push(...groupStages);
	}

	const finalStageData = viewModel.Stages.find(x => x.IsFinal);

	if (finalStageData) {
		const finalStage = mapStageViewModelToEntity(finalStageData);
		stages.push(finalStage);
	}

	const competition = new CompetitionStructure(
		viewModel.Id,
		viewModel.Name,
		viewModel.Year,
		stages,
		viewModel.Ruleset
	);

	return competition;
};

// const getStageEntities = (stageViewModels: Array<StageViewModel>): Array<Stage> => {
// 	//
// }
