import { Athlete } from "../../../../Domain/Aggregates/Shared/Athlete";
import { AthletePosition } from "../../../../Domain/ValueObjects/AthletePosition";


export interface AthletePositionIdsReadModel {
	AthleteIds: Array<number>;
	Position: number;
}

export const mapAthletePositionIdsReadModelToEntity = (athletesInCompetition: Array<Athlete>, athletePositionIdsReadModel: AthletePositionIdsReadModel) => {
	const athletes = athletePositionIdsReadModel
		.AthleteIds
		.map((athleteId: number) => {
			const athlete = athletesInCompetition.find(y => y.Id === athleteId);

			if(!athlete) {
				throw new Error(`Could not find athlete with ID ${athleteId} in the athletes passed into mapAthletePositionIdsReadModelToEntity`);
			}

			return athlete;
		});

	return new AthletePosition(athletes, athletePositionIdsReadModel.Position);
};

export const mapAthletePositionIdsReadModelsToEntities = (athletePositionIdsReadModels: Array<AthletePositionIdsReadModel>, athletesInCompetition: Array<Athlete>): Array<AthletePosition> => {
	return athletePositionIdsReadModels.map((athletePositionIdsReadModel: AthletePositionIdsReadModel) => mapAthletePositionIdsReadModelToEntity(
		athletesInCompetition,
		athletePositionIdsReadModel
	));
};
