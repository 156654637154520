import { ICompetitionPrediction } from "../../Domain/Aggregates/CompetitionPrediction/Interfaces/ICompetitionPrediction";
import { CompetitionPredictionDtoFactory } from "./CompetitionPredictionDtoFactory";
import { compareCompetitionPredictions, IMutatedPredictionsDto } from "./compareCompetitionPredictions";
import { CompetitionPredictionDtoRepository } from "./CompetitionPredictionDtoRepository";
import { CompetitionPredictionDto } from "./Dtos/CompetitionPredictionDto";


const getCacheNameFor = (competitionId: number) => `FA_CacheForCompetitionId_${competitionId}`;

/**
 * Takes a competition prediction and caches it in session storage against the specified cache name. This
 * should be called as soon as the user clicks "Sign in" to store any predictions they've made before being
 * signed up / signed in.
 */
export const commitCompetitionPrediction = (competitionPrediction: ICompetitionPrediction): void => {
	if (!competitionPrediction.HaveSelectionsBeenMade) {
		console.log("No selections have been made so nothing will be cached in session storage");
		return;
	}

	const latestCompetitionPredictionDto = new CompetitionPredictionDtoFactory().Create(competitionPrediction);
	const localStorageDto = createStorageDto(latestCompetitionPredictionDto);

	new CompetitionPredictionDtoRepository().SetItem(localStorageDto.Key, localStorageDto.Value);
};

/**
 * Checks the cached competition prediction; returns true if there are changes
 * @param competitionPrediction - The competition prediction you want to check
 */
export const identifyChangesSinceLastCommit = (competitionPrediction: ICompetitionPrediction): IMutatedPredictionsDto => {
	const cacheName = getCacheNameFor(competitionPrediction.Competition.Id);
	const dtoFromStorage = new CompetitionPredictionDtoRepository().GetItem(cacheName);
	const latestCompetitionPredictionDto = new CompetitionPredictionDtoFactory().Create(competitionPrediction);

	return compareCompetitionPredictions(dtoFromStorage, latestCompetitionPredictionDto);
};


interface ISessionStorageDto {
	Key: string;
	Value: CompetitionPredictionDto;
}

const createStorageDto = (competitionPrediction: CompetitionPredictionDto) => {
	const dto: ISessionStorageDto = {
		Key: getCacheNameFor(competitionPrediction.CompetitionId),
		Value: competitionPrediction
	};

	return dto;
};
