import { SingleEventPrediction } from "../SingleEventPrediction";
import { StagePointsTable } from "../../Scoring/StagePointsTables";
import { Athlete } from "../../../Shared/Athlete";
import { IEventPredictionFinalistsSelectionStrategy } from "./EventPredictionFinalistsSelectionStrategy";
import { immerable } from "immer";

export class Wsm2023EventPredictionFinalistsSelectionStrategy implements IEventPredictionFinalistsSelectionStrategy {
	[immerable] = true;
	public GetFinalists(eventPredictions: Array<SingleEventPrediction>, pointsTable: StagePointsTable): Array<Athlete> {
		const groupWinner = this.getGroupWinner(pointsTable);
		const stoneOffWinner = this.getStoneOffWinner(eventPredictions, pointsTable);

		return [groupWinner, stoneOffWinner]
			.filter((x): x is Athlete => !!x);
	}

	private getGroupWinner(pointsTable: StagePointsTable): Athlete | undefined {
		return pointsTable
			.AthletesWithPoints
			.find(x => x.IsWinner && x.Points > 0)?.Athlete;
	}

	private getStoneOffWinner(eventPredictions: Array<SingleEventPrediction>, pointsTable: StagePointsTable): Athlete | undefined {
		const stoneOffEventPrediction = eventPredictions.find(x => x.IsStoneOff);

		if (!stoneOffEventPrediction) throw new Error("Wsm2023FinalistsSelectionStrategy was not able to find a stone off event prediction.");

		return stoneOffEventPrediction
			.Rankings
			.AthletePositions
			.find(x => x.Position === 1)?.Athletes[0];
	}
}
