import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Container } from "@mui/material";
import logoT from "../../Files/Images/FS-Logo-transparent.png";
import { ICompetitionStructure } from "../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import { SignInButton } from "./SignInButton";
import { Link } from "react-router-dom";
import { AutoSaveSpinner } from "./AutoSaveSpinner";
import { SelectCompetitionDropdown } from "./SelectCompetitionDropdown";
import { settings } from "../../Config/settings";
import { useLoggedInUser } from "../../Hooks/useLoggedInUser";


const currentYear = new Date().getFullYear();
const drawerWidth = 240;
const bgColor = "#000";

export const Layout = ({ children }: { children: any }) => {

	useLoggedInUser();

	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<Box>
			<Box position="relative" sx={{ backgroundColor: bgColor, backgroundImage: "none" }}>
				<Toolbar sx={{ display: "flex", pr: "1em !important", justifyContent: "space-between" }}>

					<Box sx={{
						width: {
							xs: "190px",
							md: "220px"
						}
					}}>
						<Link to="/">
							<img src={logoT} style={{ marginTop: "5px", width: "100%" }} />
						</Link>
					</Box>

					<Box sx={{
						ml: {
							sm: 0,
							md: "-100px"
						},
						display: {
							xs: "none",
							sm: "block"
						},
					}}>
						<SelectCompetitionDropdown />
					</Box>

					<SignInButton isMobile={false} />

					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ ml: "auto", display: { sm: "none" } }}
					>
						<MenuIcon />
					</IconButton>
				</Toolbar>
			</Box>

			<Box component="nav">
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth
						}
					}}
				>
					<Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
						<List>
							<ListItem sx={{ justifyContent: "center" }}>
								<SignInButton isMobile={true} />
							</ListItem>

							<Divider />

							{settings.availableCompetitions.filter(x => x.Year === currentYear - 1).map((item: ICompetitionStructure) => (
								<ListItem
									key={`mobile-nav-${item.Name}${item.Id}`}
									disablePadding
									onClick={() => {
										//setSelectedCompetitionId(item.Id);
										alert("Not implemented");
									}}
								>
									<ListItemButton sx={{ textAlign: "center" }}>
										<ListItemText primary={item.Name} />
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Box>
				</Drawer>
			</Box>

			<Box component="main" sx={{ pt: 0.75, pb: 3 }}>
				<Container maxWidth="lg">
					{children}
				</Container>
			</Box>

			<AutoSaveSpinner />
		</Box>
	);
};


