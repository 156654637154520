import { ConfirmModalSettings } from "../../Components/Global/ConfirmModalSettings";
import { produce } from "immer";


export type IConfirmModalSlice = {
	confirmModalSettings: ConfirmModalSettings;
	setConfirmModalSettings: (settings: ConfirmModalSettings) => void;
	onConfirmModalCancelClicked: () => void,
	onConfirmModalConfirmClicked: () => void,
	setOnConfirmModalConfirmClicked: (fn: any) => void,
	closeModal: () => void,
}

export const createConfirmModalSlice = (set: any, get: any): IConfirmModalSlice => ({
	confirmModalSettings: new ConfirmModalSettings(false),
	setConfirmModalSettings: (settings: ConfirmModalSettings) => {
		set(
			produce((draft: IConfirmModalSlice) => {
				draft.confirmModalSettings = settings;
			})
		);
	},
	onConfirmModalCancelClicked: () => set(produce((draft: IConfirmModalSlice) => {
		draft.confirmModalSettings.IsOpen = false;
	})),
	onConfirmModalConfirmClicked: () => {
	},
	setOnConfirmModalConfirmClicked: (fn: any) => {
		set(
			produce((draft: IConfirmModalSlice) => {
				draft.onConfirmModalConfirmClicked = fn;
			})
		);
	},
	closeModal: () => {
		set(
			produce((draft: IConfirmModalSlice) => {
				draft.confirmModalSettings.IsOpen = false;
			})
		);
	},
});
