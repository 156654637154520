import React from "react";
import { useFetchCompetition } from "../../Hooks/useFetchCompetition";
import { LoadingSpinner } from "../../Components/Global/LoadingSpinner";
import { ConfirmModal } from "../../Components/Global/ConfirmModal";
import { useStore } from "../../Store/useStore";
import { useLoadPredictionsForLoggedInUser } from "../../Hooks/useLoadPredictionsForLoggedInUser";
import { ShareModal } from "../../Components/PredictionSelection/Settings/Sharing/ShareModal";
import { specificCompetitionComponentFactory } from "./specificCompetitionComponentFactory";


export const Home = () => {

	const { isCompetitionStructureLoading } = useFetchCompetition();
	const competitionPrediction = useStore((state) => state.competitionPrediction);
	const { willAttemptToLoadExistingPredictions, areExistingPredictionsLoading, haveExistingPredictionsLoaded  } = useLoadPredictionsForLoggedInUser();

	const loadingText = willAttemptToLoadExistingPredictions
		? "Loading competition & existing predictions"
		: "Loading...";

	const isReadOnly = useStore((state) => state.isReadOnly);
	const setIsReadOnly = useStore((state) => state.setIsReadOnly);

	if(isReadOnly) {
		setIsReadOnly(false);
	}

	return (
		<>
			{isCompetitionStructureLoading || (willAttemptToLoadExistingPredictions && !haveExistingPredictionsLoaded) || areExistingPredictionsLoading ? (
				<LoadingSpinner text={loadingText} />
			) : (
				<>
					{specificCompetitionComponentFactory(competitionPrediction.Competition.Ruleset)}

					<ConfirmModal />
					<ShareModal />
				</>
			)}
		</>
	);
};
