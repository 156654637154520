import { CompetitionRuleset } from "../../Domain/Enums/CompetitionRuleset";
import { Wsm2023Component } from "./Wsm2023/Wsm2023Component";
import { Asc2023Component } from "./Asc2023/Asc2023Component";
import React from "react";


const CompetitionComponentMap = {
	[CompetitionRuleset.Wsm2023]: <Wsm2023Component />,
	[CompetitionRuleset.Asc2023]: <Asc2023Component />,
};

export const specificCompetitionComponentFactory = (ruleset: CompetitionRuleset) => CompetitionComponentMap[ruleset];
