import { Box, Typography } from "@mui/material";
import React from "react";
import { useStore } from "../../../Store/useStore";
import { AthleteDisplayGrid } from "../../Global/AthleteDisplayGrid";
import { IMultiStageCompetitionPrediction } from "../../../Domain/Aggregates/CompetitionPrediction/Interfaces/ICompetitionPrediction";

export const SelectedFinalistsCard = () => {

	const competitionPrediction = useStore((store) => store.competitionPrediction) as unknown as IMultiStageCompetitionPrediction;
	const finalists = competitionPrediction.GetFinalists();
	const isReadOnly = useStore((state) => state.isReadOnly);

	return (
		<Box>
			<Typography variant="h6" component="div" color="warning.light" mb={2}>
				{`${isReadOnly ? "" : "Your"} Selected Finalists`}
			</Typography>

			<AthleteDisplayGrid athletes={finalists} />
		</Box>
	);
};

