import { ICompetitionStructure } from "../Aggregates/CompetitionStructure/CompetitionStructure";

export enum CompetitionEnum {
	WorldsStrongestMan = 1,
	ArnoldStrongmanClassic = 3
}

export interface IAvailableCompetition extends ICompetitionStructure {
	Competition: CompetitionEnum;

	/**
	 * Should be sorted DESCENDING, i.e. highest comes first
	 * This is so we can add more competitions and have them
	 * appear at the top of the list
	 */
	DisplayOrder: number;
}


