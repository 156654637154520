import React from "react";
import { Box } from "@mui/material";


export const SettingsButtonContainer = ({ children }: { children: any; }) => {
	return (
		<Box sx={{
			position: "absolute",
			right: 0,
			top: 0,
			margin: "0.5em"
		}}>
			{children}
		</Box>
	);
};
