import { Typography } from "@mui/material";
import React from "react";

// TODO: Remove the mb:1.5 off this and put it on the containers using it

export const EventTitle = ({ title, color }: { title: string, color?: string }) => {
	return (
		<Typography
			variant="h6"
			color={color ?? "secondary.lighter"}
			mb={1.5}
		>
			{title}
		</Typography>
	);
};
