import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./Components/Global/Layout";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import palette from "./Style/palette";
import { QueryClient, QueryClientProvider, } from "react-query";
import { Home } from "./Pages/Home";
import { MsalProvider } from "@azure/msal-react";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./Config/auth-config";
import { Profile } from "./Pages/Profile";
import { Toaster } from "react-hot-toast";
import { SharePage } from "./Pages/Share";


const theme = createTheme({
	palette: {
		mode: "dark",
		...palette
	}
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 1
		},
	},
});

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
	msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult;
		const account = payload.account;
		msalInstance.setActiveAccount(account);
	}
});

function App() {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<MsalProvider instance={msalInstance}>
				<QueryClientProvider client={queryClient}>
					<ThemeProvider theme={theme}>
						<Layout>
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/profile" element={<Profile />} />
								<Route path="/share/:param" element={<SharePage />} />
							</Routes>
							<Toaster position="top-right" />
						</Layout>
					</ThemeProvider>
				</QueryClientProvider>
			</MsalProvider>
		</BrowserRouter>
	);
}

export default App;
