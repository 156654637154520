import React from "react";
import { Grid } from "@mui/material";
import { EventCard } from "./EventCard";
import { SingleEventPrediction } from "../../../Domain/Aggregates/StagePrediction/EventPredictions/SingleEventPrediction";
import { useStore } from "../../../Store/useStore";
import { cloneDeep } from "lodash-es";


export const EventCardList = ({ stageId, predictions }: { stageId: string, predictions: Array<SingleEventPrediction> }) => {

	const isReadOnly = useStore((state) => state.isReadOnly);

	const predictionsSorted = isReadOnly
		? cloneDeep(predictions).sort((x, y) => x.StrongmanEvent.DisplayOrder - y.StrongmanEvent.DisplayOrder)
		: predictions;

	return (
		<Grid
			container
			columnSpacing={4}
			rowSpacing={4}
			pt={isReadOnly ? 0 : 2}
			sx={{ fontSize: "0.875em" }}
		>
			{predictionsSorted.map((x: SingleEventPrediction) => (
				<Grid
					key={x.StrongmanEvent.Id}
					item
					xs={12}
					sm={12}
					md={6}
					lg={isReadOnly ? 4 : 6}
				>
					<EventCard
						strongmanEvent={x.StrongmanEvent}
						stageId={stageId}
					/>
				</Grid>
			))}
		</Grid>
	);
};
