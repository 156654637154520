import { CompetitionPredictionDto } from "./Dtos/CompetitionPredictionDto";

interface ISessionStorageRepository<T> {
	GetItem(key: string): T | null;

	SetItem(key: string, item: T): void;

	RemoveItem(key: string): void;
}

export class CompetitionPredictionDtoRepository implements ISessionStorageRepository<CompetitionPredictionDto> {
	public GetItem(key: string): CompetitionPredictionDto | null {
		const item = sessionStorage.getItem(key);

		if (!item) return null;

		// We need to take this JSON and remake it as an actual class so that it has the class methods
		const dtoNotMadeFromClass = JSON.parse(item) as CompetitionPredictionDto;

		return new CompetitionPredictionDto(
			dtoNotMadeFromClass.CompetitionId,
			dtoNotMadeFromClass.PredictionId,
			dtoNotMadeFromClass.LeaderboardPredictionDtos,
			dtoNotMadeFromClass.EventsPredictionDtos,
			dtoNotMadeFromClass.FinalPlacings
		);
	}

	public SetItem(key: string, item: CompetitionPredictionDto): void {
		sessionStorage.setItem(key, JSON.stringify(item));
	}

	public RemoveItem(key: string): void {
		sessionStorage.removeItem(key);
	}
}
