import { CompetitionStructure } from "../../CompetitionStructure/CompetitionStructure";
import { Wsm2023Prediction } from "../Wsm2023Prediction";
import { Asc2023Prediction } from "../Asc2023Prediction";
import { BaseStagePrediction } from "../../StagePrediction/BaseStagePrediction";
import { CompetitionRuleset } from "../../../Enums/CompetitionRuleset";
import { BaseCompetitionPrediction } from "../BaseCompetitionPrediction";


export const competitionPredictionFactory = (competitionStructure: CompetitionStructure, existingPredictions?: Array<BaseStagePrediction>, existingId?: string): BaseCompetitionPrediction => {
	switch(competitionStructure.Ruleset) {
		case CompetitionRuleset.Wsm2023: { return new Wsm2023Prediction(competitionStructure, existingPredictions, existingId); }
		case CompetitionRuleset.Asc2023: {
			const existingPrediction = existingPredictions && existingPredictions.length > 0
				? existingPredictions[0]
				: undefined;

			return new Asc2023Prediction(competitionStructure, existingPrediction, existingId);
		}
		default: {
			throw new Error(`CompetitionStructure with ID ${competitionStructure.Id} and ruleset ${competitionStructure.Ruleset} not recognised in competitionPredictionFactory`);
		}
	}
};
