import React from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useWindowSize } from "usehooks-ts";
import { PredictionTypeEnum } from "../../Domain/Enums/PredictionTypeEnum";


interface Props {
	predictionType: PredictionTypeEnum;
	setPredictionType: (predictionType: PredictionTypeEnum) => void;
	leaveSpaceForButtons?: boolean;
}

export const SelectPredictionTypeTabs = ({ predictionType, setPredictionType, leaveSpaceForButtons }: Props) => {
	// console.log("PredictionTypeTabs rendering");

	return (
		<Box sx={{
			mb: {
				xs: 4,
				sm: 6
			}
		}}>

			<Typography
				variant="h6"
				component="div"
				sx={{
					mb: {
						xs: 2,
						lg: 0
					},
					mt: {
						xs: leaveSpaceForButtons ? 6 : 0,
						sm: 0
					}
				}}
			>
				Select Prediction Type:
			</Typography>

			<TabsWrapper predictionType={predictionType}>
				<Tab
					label="Select for each event"
					id="tab-3"
					onClick={() => {
						setPredictionType(PredictionTypeEnum.Events);
					}}
					value={2}
				/>

				<Tab label="...or..." id="tab-2" disabled value={-1} />

				<Tab
					label="Select final leaderboard"
					id="tab-1"
					onClick={() => {
						setPredictionType(PredictionTypeEnum.Leaderboard);
					}}
					value={1}
				/>
			</TabsWrapper>
		</Box>
	);
};

const TabsWrapper = ({ children, predictionType }: { children: any, predictionType: PredictionTypeEnum }) => {

	const { width } = useWindowSize();

	return (
		<Tabs
			className="prediction-type-tabs"
			value={predictionType}
			centered
			orientation={width < 600 ? "vertical" : "horizontal"}
		>
			{children}
		</Tabs>
	);
};
