import { PredictionTypeEnum } from "../../Enums/PredictionTypeEnum";
import { immerable } from "immer";
import { CompetitionRuleset } from "../../Enums/CompetitionRuleset";
import { Athlete } from "../Shared/Athlete";
import { AthletePosition } from "../../ValueObjects/AthletePosition";


export abstract class BaseStagePrediction {
	[immerable] = true;
	// TODO: I think we stopped using PredictionId on stage because re-creating it meant we alays
	// had to pass the `existingId` in. We are doing this for CompPred though so maybe we should for this
	// public readonly PredictionId: string = newGuid();
	public readonly StageId: string;
	public readonly CompetingAthletes: Array<Athlete>;
	public readonly Ruleset: CompetitionRuleset;
	public abstract readonly PredictionType: PredictionTypeEnum;
	public abstract readonly IsFinal: boolean;

	protected constructor(stageId: string, ruleset: CompetitionRuleset, competingAthletes: Array<Athlete>) {
		this.StageId = stageId;
		this.Ruleset = ruleset;
		this.CompetingAthletes = competingAthletes;
	}

	public abstract HaveSelectionsBeenMade(): boolean;

	public abstract GetRankings(): Array<AthletePosition>;
}
