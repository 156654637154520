import { Box } from "@mui/material";
import { basicRowStyle, gridTemplateColumnsFor3Cols, gridTemplateColumnsFor4Cols, gridTemplateColumnsFor5Cols } from "../../../Style/basicRowStyle";
import React from "react";
import { useStore } from "../../../Store/useStore";


interface Props {
	children: any;
	hasAthletes: boolean;
	onClick?: () => void;
}

export const RowContentWrapper = ({ children, hasAthletes, onClick }: Props) => {

	const handleOptionalOnClick = onClick || (() => {});
	const isReadOnly = useStore((state) => state.isReadOnly);

	const gridTemplateColumns = React.useMemo(() => {
		if(hasAthletes) {
			if(isReadOnly) {
				// this is only used here and im in a rush
				return {
					gridTemplateColumns: {
						xs: "2em 2em auto 5em 0.5em",
						sm: "4em 2em auto 5em 0.5em",
						md: "4.5em 2em auto 4em 0.5em",
						lg: "5em 2.5em auto 5em 0.5em",
					}
				};
			} else {
				return gridTemplateColumnsFor5Cols;
			}
		} else {
			return gridTemplateColumnsFor3Cols;
		}
	}, [hasAthletes, isReadOnly]);

	return (
		<Box
			sx={{
				...basicRowStyle,
				...gridTemplateColumns
			}}
			onClick={handleOptionalOnClick}
		>
			{children}
		</Box>
	);
};
