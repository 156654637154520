import toast from "react-hot-toast";

export const displayErrorToast = (msg: string) => {
	toast.error(msg, {
		duration: 10000,
		position: "bottom-right",
		style: {
			marginTop: "3.5em",
			borderRadius: "10px",
			background: "#D32F2F", // You could opt for a light background
			color: "#FFF", // Red color text for the error message
			border: "1px solid #FF6B6B", // Red border to draw attention
		}
	});
};
