import React from "react";
import { Box, Paper } from "@mui/material";
import { AthleteSelectionChips } from "../Table/AthleteSelectionChips";
import { EventTitle } from "./EventTitle";
import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";
import { StrongmanEvent } from "../../../Domain/Aggregates/CompetitionStructure/StrongmanEvent";
import { AthleteOrderingTable } from "../Table/AthleteOrderingTable";
import { useStore } from "../../../Store/useStore";
import { EventPredictions } from "../../../Domain/Aggregates/StagePrediction/EventPredictions/EventPredictions";
import { AthleteOrderingTableReadOnly } from "../Table/AthleteOrderingTableReadOnly";
import { useStats } from "../../../Hooks/useStats";
import { EventsPredictionsStatsViewModel } from "../../../ApplicationServices/Queries/ViewModels/PredictionStatsViewModels";
import { PredictionTypeEnum } from "../../../Domain/Enums/PredictionTypeEnum";


interface Props {
	strongmanEvent: StrongmanEvent;
	stageId: string;
}

// TODO: A lot of the memoisation can, I think, be removed. The add/remove/move methods
// do not need it any more

export const EventCard = React.memo(({ strongmanEvent, stageId }: Props) => {
	//console.log(`EventCard2 rendering for ${strongmanEvent.strongmanEventType.name}`);

	const addAthleteToPrediction = useStore((state) => state.addAthleteToPrediction);
	const removePositionFromPrediction = useStore((state) => state.removePositionFromPrediction);
	const movePositionWithinPrediction = useStore((state) => state.movePositionWithinPrediction);
	const applyTieBetweenAthletes = useStore((state) => state.applyTieBetweenAthletesToPrediction);
	const predictionForCurrentGroup = useStore((state) => state.getPredictionForStage(stageId)) as EventPredictions;
	const predictionForThisEvent = predictionForCurrentGroup.GetPredictionByStrongmanEventId(strongmanEvent.Id);
	const isReadOnly = useStore((state) => state.isReadOnly);


	const onAthleteChipClicked = React.useCallback((athlete: Athlete) => {
		addAthleteToPrediction(athlete, stageId, strongmanEvent.Id);
	}, []);

	const deleteRowFromListFn = React.useCallback((position: number) => {
		removePositionFromPrediction(position, stageId, strongmanEvent.Id);
	}, []);

	const moveAthleteFn = React.useCallback((fromIndex: number, toIndex: number) => {
		movePositionWithinPrediction(fromIndex, toIndex, stageId, strongmanEvent.Id);
	}, []);

	const applyTieFn = React.useCallback((fromPosition: number, toPosition: number) => {
		applyTieBetweenAthletes(fromPosition, toPosition, stageId, strongmanEvent.Id);
	}, []);

	const { areStatsLoading, statsForCurrentGroup } = useStats(stageId, PredictionTypeEnum.Events);

	const getStatsPercentageFor = React.useCallback((athletes: Athlete[], position: number): number | undefined => {
		// We don't have stats for ties, so return undefined when more than 1 athlete is at the position
		if(athletes.length > 1) {
			return undefined;
		}

		const statsViewModel = (statsForCurrentGroup as EventsPredictionsStatsViewModel[]).find(x =>
			x.AthleteName === athletes[0].AthleteName
			&& x.Position === position
			&& x.StrongmanEventTypeName === strongmanEvent.StrongmanEventType.Name);

		return statsViewModel?.PositionPercentage ?? 0;
	}, [areStatsLoading, statsForCurrentGroup]);

	return (
		<Paper sx={{ pb: 0, pt: 1.5 }} elevation={3}>

			<Box px={2}>
				<EventTitle title={strongmanEvent.StrongmanEventType.Name} />
				{!isReadOnly && (

					<AthleteSelectionChips
						unselectedAthletes={predictionForThisEvent.UnselectedAthletes}
						onClick={onAthleteChipClicked}
						justifyContent="left"
					/>
				)}
			</Box>

			{isReadOnly ? (
				<AthleteOrderingTableReadOnly
					rows={predictionForThisEvent.Rankings.AthletePositions}
					nextPositionToSelect={predictionForThisEvent.Rankings.GetNextPosition()}
					emptyRowsToRender={predictionForThisEvent.UnselectedAthletes.length}
					getStatsPercentageFor={getStatsPercentageFor}
				/>
			) : (
				<AthleteOrderingTable
					rows={predictionForThisEvent.Rankings.AthletePositions}
					nextPositionToSelect={predictionForThisEvent.Rankings.GetNextPosition()}
					emptyRowsToRender={predictionForThisEvent.UnselectedAthletes.length}
					removeRowFn={deleteRowFromListFn}
					moveAthlete={moveAthleteFn}
					areTiesAllowed={!predictionForThisEvent.IsStoneOff}
					applyTieFn={applyTieFn}
					getStatsPercentageFor={getStatsPercentageFor}
				/>
			)}

		</Paper>
	);
});
