import { LoggedInUser } from "../ApplicationServices/Auth/LoggedInUser";
import React, { useState } from "react";
import { ISaveResult, performAuthenticatedPost } from "../ApplicationServices/Auth/protected-api-call";


export const useInitialUserSetup = (loggedInUser: LoggedInUser | undefined) => {
	const [hasRequestBeenSent, setHasRequestBeenSent] = useState<boolean>(false);
	const [isUserSetup, setIsUserSetup] = useState<boolean>(false);

	React.useEffect(() => {
		if (loggedInUser && !hasRequestBeenSent) {
			setHasRequestBeenSent(true);

			performAuthenticatedPost<LoggedInUser, ISaveResult>("User", loggedInUser).then(() => {
				setIsUserSetup(true);
			});
		}
	}, [loggedInUser, hasRequestBeenSent]);

	return isUserSetup;
};
