import { SingleEventPrediction } from "../SingleEventPrediction";
import { StagePointsTable } from "../../Scoring/StagePointsTables";
import { Athlete } from "../../../Shared/Athlete";
import { IEventUpdaterOnPointsChangeStrategy } from "./EventUpdaterOnPointsChangeStrategy";
import { immerable } from "immer";

/**
 * This is only used during the Wsm group stage but there's currently nothing enforcing that. The
 * factory should never be called to return it outside of a group stage setting, but we may need
 * to add that restriction into the factory / strategy somewhere at some point.
 */
export class Wsm2023EventUpdaterOnPointsChangeStrategy implements IEventUpdaterOnPointsChangeStrategy {
	[immerable] = true;

	public UpdateEventPredictionss(eventPredictions: Array<SingleEventPrediction>, pointsTable: StagePointsTable): Array<SingleEventPrediction> {

		if (!this.haveAthletesForStoneOffChanged(eventPredictions, pointsTable)) {
			return eventPredictions;
		}

		const stoneOffPrediction = this.getStoneOffPrediction(eventPredictions);

		const existingPredictions = this.shouldOriginalPredictionsBeRetained(stoneOffPrediction)
			? stoneOffPrediction.Rankings
			: undefined;

		const newStoneOffPrediction = new SingleEventPrediction(
			stoneOffPrediction.StrongmanEvent,
			this.getAthletesForStoneOff(pointsTable),
			existingPredictions
		);

		return [
			...eventPredictions.filter(x => !x.IsStoneOff),
			newStoneOffPrediction
		];
	}

	private haveAthletesForStoneOffChanged(eventPredictions: Array<SingleEventPrediction>, pointsTable: StagePointsTable): boolean {
		const stoneOffPrediction = this.getStoneOffPrediction(eventPredictions);
		const signatureOfExistingStoneOffAthletes = this.createSignatureOfOrderedAthleteIds(stoneOffPrediction.Athletes);
		const signatureOfNewStoneOffAthletes = this.createSignatureOfOrderedAthleteIds(this.getAthletesForStoneOff(pointsTable));

		return signatureOfExistingStoneOffAthletes !== signatureOfNewStoneOffAthletes;
	}

	private getAthletesForStoneOff(pointsTable: StagePointsTable): Array<Athlete> {
		return pointsTable
			.AthletesWithPoints
			.filter((x) => x.Position === 2 || x.Position === 3)
			.map(x => x.Athlete);
	}

	private createSignatureOfOrderedAthleteIds(athletes: Array<Athlete>): string {
		return athletes
			.sort((a, b) => a.AthleteName.localeCompare(b.AthleteName))
			.map(x => x.Id)
			.join("");
	}

	private getStoneOffPrediction(eventPredictions: Array<SingleEventPrediction>): SingleEventPrediction{
		const stoneOffPrediction = eventPredictions.find(x => x.IsStoneOff);

		if (!stoneOffPrediction) {
			throw new Error("No stoneOff prediction found in Wsm2023EventUpdaterOnPointsChangeStrategy");
		}

		return stoneOffPrediction;
	}

	/**
	 * If the stoneoff has existingPredictions but no athletes then we want to retain those predictions. This may
	 * happen if the prediction has been re-created from the API.
	 */
	private shouldOriginalPredictionsBeRetained(stoneOffPrediction: SingleEventPrediction): boolean {
		return stoneOffPrediction.Rankings.AthletePositions.length > 0 && stoneOffPrediction.Athletes.length === 0;
	}
}
