import { ModifyPredictionCommand } from "./Base/ModifyPredictionCommand";
import { PredictionTypeEnum } from "../../../Enums/PredictionTypeEnum";
import { CanExecuteResponse } from "./Base/CanExecuteResponse";
import { BaseStagePrediction } from "../../StagePrediction/BaseStagePrediction";
import { ICompetitionPrediction } from "../Interfaces/ICompetitionPrediction";


export class ChangePredictionTypeCommand<T extends ICompetitionPrediction> extends ModifyPredictionCommand<T> {

	private readonly _newPredictionType: number;

	constructor(wsm: T, newPredictionType: PredictionTypeEnum, stageId: string, promptConfirmed: boolean) {
		super(wsm, stageId, promptConfirmed);

		this._newPredictionType = newPredictionType;
	}

	public override CanExecute(): CanExecuteResponse {
		const response = new CanExecuteResponse(true);
		const predictionForStage = this.CompetitionPrediction.GetPredictionFor(this.Stage.Id);

		if(this.isNewPredictionTypeTheSameAsTheExistingPredictionType(predictionForStage)) {
			response.CanExecute = false;
		} else if (predictionForStage.HaveSelectionsBeenMade() && !this.HasPromptBeenConfirmed) {
			response.CanExecute = false;
			response.PromptUserToConfirm = true;
			response.AddPromptMessage("Are you sure you want to change the prediction type? You will lose any " +
				"predictions you've made for this group by changing the prediction type.");
		}

		return response;
	}

	private isNewPredictionTypeTheSameAsTheExistingPredictionType(prediction: BaseStagePrediction): boolean {
		return prediction.PredictionType === this._newPredictionType;
	}

	public override Execute(): boolean {
		return this.CompetitionPrediction.ChangePredictionTypeFor(
			this.Stage.Id,
			this._newPredictionType
		);
	}
}
