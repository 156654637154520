import { Athlete } from "../../Shared/Athlete";
import { ModifyPredictionCommand } from "./Base/ModifyPredictionCommand";
import { CanExecuteResponse } from "./Base/CanExecuteResponse";
import { PredictionTypeEnum } from "../../../Enums/PredictionTypeEnum";
import { EventPredictions } from "../../StagePrediction/EventPredictions/EventPredictions";
import { LeaderboardPrediction } from "../../StagePrediction/LeaderboardPredictions/LeaderboardPrediction";
import { ICompetitionPrediction } from "../Interfaces/ICompetitionPrediction";


export class AddAthleteCommand<T extends ICompetitionPrediction> extends ModifyPredictionCommand<T> {

	private readonly _athlete: Athlete;

	constructor(wsm: T, athlete: Athlete, stageId: string, promptConfirmed: boolean, strongmanEventId?: number) {
		super(wsm, stageId, promptConfirmed, strongmanEventId);

		this._athlete = athlete;
	}

	public override CanExecute(): CanExecuteResponse {
		return new CanExecuteResponse(true);
	}

	public override Execute(): boolean {

		const predictionForStage = this.CompetitionPrediction.GetPredictionFor(this.Stage.Id);

		// TODO: on the base command, have two protected methods: ExecuteForEvents and ExecuteForLEaderboard
		// Would be far better than doing this check in eery single command type
		if(predictionForStage.PredictionType === PredictionTypeEnum.Events) {
			if(!this.StrongmanEventId) {
				throw new Error("StrongmanEventId must be supplied for Events prediction type");
			}

			return (predictionForStage as EventPredictions).AddAthlete(this._athlete, this.StrongmanEventId);
		} else {
			if(this.StrongmanEventId) {
				throw new Error("StrongmanEventId was supplied but isn't applicable for Leaderboard predictions");
			}

			return (predictionForStage as LeaderboardPrediction).AddAthlete(this._athlete);
		}
	}
}

