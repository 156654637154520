import React from "react";
import { LoadingSpinner } from "./LoadingSpinner";


interface IsLoadingBoxProps {
	isLoading: boolean;
	loadingText?: string;
	children: React.ReactNode;
}

export const IsLoadingBox = ({ isLoading, loadingText, children }: IsLoadingBoxProps) => {
	return (
		<>
			{isLoading ? (
				<LoadingSpinner text={loadingText} />
			) : (
				children
			)}
		</>
	);
};
