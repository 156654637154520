import { Country } from "../../ValueObjects/Country";
import { immerable } from "immer";

export class Athlete {
	[immerable] = true;
	public readonly Id: number;
	public readonly AthleteName: string;
	public readonly Country: Country;

	constructor(id: number, athleteName: string, country: Country) {
		this.Id = id;
		this.AthleteName = athleteName;
		this.Country = country;
	}

	public equals(athlete: Athlete): boolean {
		return athlete.AthleteName === this.AthleteName;
	}
}
