import { performAuthenticatedGet } from "../../Auth/protected-api-call";
import { CompetitionStructure } from "../../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import {
	ILoadPredictionForUserReadModel,
	mapLoadPredictionForUserReadModelToBaseCompetitionPredictionEntity
} from "./ReadModels/ILoadPredictionForUserReadModel";
import { BaseCompetitionPrediction } from "../../../Domain/Aggregates/CompetitionPrediction/BaseCompetitionPrediction";


/**
 * Performs an API call to get the predictions for the current logged-in user,
 * then maps the response to an array of the CompetitionPrediction entities.
 */
export const loadPredictionsForUserQuery = async (selectedCompetition: CompetitionStructure): Promise<BaseCompetitionPrediction | undefined> => {

	const res = await performAuthenticatedGet(`Prediction/competition/${selectedCompetition.Id}`) as Array<ILoadPredictionForUserReadModel>;

	if(!res) {
		console.info("No result returned from loadPredictionsForUserQuery");
		return undefined;
	}

	const predictionForSelectedCompetition = res.find(x => x.CompetitionId === selectedCompetition.Id);

	if (!predictionForSelectedCompetition) {
		console.info(`The API returned no prediction for the selected competition (ID: ${selectedCompetition.Id})`);
		return undefined;
	}

	return mapLoadPredictionForUserReadModelToBaseCompetitionPredictionEntity(selectedCompetition, predictionForSelectedCompetition);
};

