import { immerable } from "immer";
import { CanExecuteResponse } from "./CanExecuteResponse";
import { ICommand, ModifyPredictionCommand } from "./ModifyPredictionCommand";
import { Wsm2023Prediction } from "../../Wsm2023Prediction";


/**
 * This is a decorator that provides Wsm2023 / compWithGroupsAndFinals
 * functionality on top of the ModifyPredictionCommand
 */
export class ModifyPredictionWsm2023Decorator implements ICommand {
	[immerable] = true;

	private readonly _command: ModifyPredictionCommand<Wsm2023Prediction>;
	private readonly _isGroupStageBeingModifiedDuringFinal: boolean;

	constructor(command: ModifyPredictionCommand<Wsm2023Prediction>) {
		this._command = command;
		this._isGroupStageBeingModifiedDuringFinal = this._command.CompetitionPrediction.IsFinalStageActive && !this._command.Stage.IsFinal;
	}

	public CanExecute(): CanExecuteResponse {
		const canExecuteResult = this._command.CanExecute();

		if (this._isGroupStageBeingModifiedDuringFinal && !this._command.HasPromptBeenConfirmed) {
			return new CanExecuteResponse(false, true, [
				...canExecuteResult.PromptMessages,
				"Are you sure you want to modify a group? Your predictions for the final will be lost!"
			]);
		}

		return canExecuteResult;
	}

	public Execute(): boolean {
		const res = this._command.Execute();

		if (this._isGroupStageBeingModifiedDuringFinal) {
			// TODO: This is sus, at least put it in a method on the CompetitionPrediction like .SetFinalStageNotActive()
			this._command.CompetitionPrediction.IsFinalStageActive = false;
			this._command.CompetitionPrediction.DeleteFinalsPrediction();
		}

		return res;
	}
}
