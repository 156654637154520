import { SingleEventPrediction } from "../../EventPredictions/SingleEventPrediction";
import { AthletePointsTableRow, StagePointsTable } from "../StagePointsTables";
import { immerable } from "immer";
import { Stage } from "../../../CompetitionStructure/Stage";
import { Athlete } from "../../../Shared/Athlete";
import { AthletePoints, EventPointsTable, EventPointsTableFactory } from "../EventPointsTable";

export interface IPointCalculationStrategy {
	Calculate(eventPredictions: Array<SingleEventPrediction>): StagePointsTable;
}

export abstract class BasePointsCalculationStrategy implements IPointCalculationStrategy {
	[immerable] = true;

	private readonly _stage: Stage;
	private readonly _competingAthletes: Array<Athlete> = [];
	private _eventPredictions: Array<SingleEventPrediction> = [];

	protected constructor(stage: Stage, competingAthletes: Array<Athlete>) {
		this._stage = stage;
		this._competingAthletes = competingAthletes;
	}

	public Calculate(eventPredictions: Array<SingleEventPrediction>): StagePointsTable {
		this._eventPredictions = eventPredictions;

		const pointsTablesForEachEvent = this.calculatePointsTablesForEachEvent();
		const overallAthletePoints = this.calculatePointsForAthletes(pointsTablesForEachEvent);
		const orderPointsRows = this.convertAthletePointsToOrderedRow(overallAthletePoints);

		return new StagePointsTable(this._stage.Id, orderPointsRows);
	}

	protected calculatePointsTablesForEachEvent(): Array<EventPointsTable> {
		return this._eventPredictions
			.filter(x => !x.IsStoneOff)
			.map((singleEventPrediction: SingleEventPrediction) => {
				const eventPointsTableFactory = new EventPointsTableFactory(
					singleEventPrediction.StrongmanEvent.StrongmanEventType,
					singleEventPrediction.Rankings.AthletePositions,
					this._competingAthletes.length
				);

				return eventPointsTableFactory.Create();
			});
	}

	protected calculatePointsForAthletes(singleEventPointsTables: Array<EventPointsTable>): Array<AthletePoints> {
		return this._competingAthletes.map((athlete: Athlete) => {
			const totalPointsForAthlete = this.getTotalPointsForAnAthlete(athlete, singleEventPointsTables);

			return new AthletePoints(athlete, totalPointsForAthlete);
		});
	}

	protected getTotalPointsForAnAthlete(athlete: Athlete, singleEventPointsTables: Array<EventPointsTable>): number {
		return singleEventPointsTables
			.map(eventPointsTable => {
				const athletePointsForThisEvent = eventPointsTable.AthletesWithPoints.find(x => x.Athlete.equals(athlete));

				return athletePointsForThisEvent?.Points ?? 0;
			})
			.reduce((acc, current) => acc + current, 0);
	}

	protected convertAthletePointsToOrderedRow(athletePoints: Array<AthletePoints>): Array<AthletePointsTableRow> {
		return athletePoints
			.sort((a, b) => b.Points - a.Points)
			.map((x, i) => {
				const position = i + 1;
				const isWinner = position === 1;

				return new AthletePointsTableRow(
					x.Athlete,
					x.Points,
					position,
					isWinner
				);
			});
	}

}
