import { produce } from "immer";


export type IStatsSlice = {
	statsEnabled: boolean,
	setStatsEnabled: (x: boolean) => void
}

export const createStatsSlice = (set: any, get: any): IStatsSlice => ({
	statsEnabled: true,
	setStatsEnabled: (x: boolean) => {
		set(
			produce((draft: IStatsSlice) => {
				draft.statsEnabled = x;
			})
		);
	},
});