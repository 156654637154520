import { Stage } from "../../../CompetitionStructure/Stage";
import { SingleEventPrediction } from "../SingleEventPrediction";
import {
	Asc2023SingleEventPredictionGenerationStrategy,
	Wsm2023SingleEventPredictionGenerationStrategy
} from "./Wsm2023SingleEventPredictionGenerationStrategy";
import { CompetitionRuleset } from "../../../../Enums/CompetitionRuleset";
import { Athlete } from "../../../Shared/Athlete";

export interface ISingleEventPredictionGenerationStrategy {
	Generate(stage: Stage, competingAthletes: Array<Athlete>): Array<SingleEventPrediction>;
}

export class SingleEventPredictionGenerationStrategyFactory {
	public static Create(ruleset: CompetitionRuleset): ISingleEventPredictionGenerationStrategy {
		switch (ruleset) {
			case CompetitionRuleset.Wsm2023: {
				return new Wsm2023SingleEventPredictionGenerationStrategy();
			}
			case CompetitionRuleset.Asc2023: {
				return new Asc2023SingleEventPredictionGenerationStrategy();
			}
			default: {
				throw new Error(`EventGenerationStrategyFactory was not able to provide a strategy for ruleset #${ruleset}`);
			}
		}
	}
}
