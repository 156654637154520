export default {
	secondary: {
		lighter: "#A7A7A7",
		light: "#4a4a4a",
		main: "#353535",
		dark: "#191919"
	},
	warning: {
		main: "#f9c23c"
	}
};


// Some of the basic palette colors here:
// https://mui.com/system/palette/
// https://mui.com/material-ui/customization/dark-mode/
