import { PredictionTypeEnum } from "../../Enums/PredictionTypeEnum";
import { Stage } from "../CompetitionStructure/Stage";
import { GroupStageEventPredictions } from "./EventPredictions/GroupStageEventPredictions";
import { EventPredictions } from "./EventPredictions/EventPredictions";
import { GroupStageLeaderboardPrediction } from "./LeaderboardPredictions/GroupStageLeaderboardPrediction";
import { LeaderboardPrediction } from "./LeaderboardPredictions/LeaderboardPrediction";
import { CompetitionRuleset } from "../../Enums/CompetitionRuleset";
import { BaseStagePrediction } from "./BaseStagePrediction";
import { RankedAthleteCollection } from "./RankedAthleteCollection";
import { Athlete } from "../Shared/Athlete";


export class StagePredictionFactory {
	public static CreatePredictionFor(stage: Stage, athletes: Array<Athlete>, predictionType: PredictionTypeEnum, ruleset: CompetitionRuleset, existingRankings?: RankedAthleteCollection): BaseStagePrediction {
		if (predictionType === PredictionTypeEnum.Events) {
			if(stage.IsFinal) {
				return new EventPredictions(stage, ruleset, athletes);
			} else {
				return new GroupStageEventPredictions(stage, ruleset, athletes);
			}
		} else {
			if (stage.IsFinal) {
				return new LeaderboardPrediction(stage.Id, ruleset, athletes, existingRankings);
			} else {
				return new GroupStageLeaderboardPrediction(stage.Id, ruleset, athletes, existingRankings);
			}
		}
	}
}
