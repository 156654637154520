import { ModifyPredictionCommand } from "./Base/ModifyPredictionCommand";
import { CanExecuteResponse } from "./Base/CanExecuteResponse";
import { PredictionTypeEnum } from "../../../Enums/PredictionTypeEnum";
import { EventPredictions } from "../../StagePrediction/EventPredictions/EventPredictions";
import { LeaderboardPrediction } from "../../StagePrediction/LeaderboardPredictions/LeaderboardPrediction";
import { ICompetitionPrediction } from "../Interfaces/ICompetitionPrediction";


export class RemovePositionCommand<T extends ICompetitionPrediction> extends ModifyPredictionCommand<T> {

	private readonly _position: number;

	constructor(wsm: T, position: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number) {
		super(wsm, stageId, promptConfirmed, strongmanEventId);

		this._position = position;
	}

	public override CanExecute(): CanExecuteResponse {
		return new CanExecuteResponse(true);
	}

	public override Execute(): boolean {
		const predictionForStage = this.CompetitionPrediction.GetPredictionFor(this.Stage.Id);

		if(predictionForStage.PredictionType === PredictionTypeEnum.Events) {
			if(!this.StrongmanEventId) {
				throw new Error("StrongmanEventId must be supplied for Events prediction type");
			}

			return (predictionForStage as EventPredictions).RemovePosition(this._position, this.StrongmanEventId);
		} else {
			if(this.StrongmanEventId) {
				throw new Error("StrongmanEventId was supplied but isn't applicable for Leaderboard predictions");
			}

			return (predictionForStage as LeaderboardPrediction).RemovePosition(this._position);
		}
	}
}
