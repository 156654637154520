import { Box } from "@mui/material";
import React from "react";

export const CustomCell = ({ children, sx }: { children?: any, sx?: any }) => {
	return (
		<Box sx={{
			py: "0.5em",
			px: {
				xs: "0px",
				sm: "0.5em"
			},
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflowX: "hidden",
			overflowY: "hidden",
			...sx
		}}>
			{children}
		</Box>
	);
};
