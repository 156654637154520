import { Paper, TableContainer } from "@mui/material";
import React from "react";


export const TableContainerWrapper = ({ children, rowCount }: { children: any, rowCount: number }) => {

	/**
	 * We are setting the height like this due to the TableContainer's height resizing during drag and drop. During drag and
	 * drop, the dragged element gets `position: fixed` applied. This removes it from the flow of divs and would naturally
	 * cause every row beneath it to shift up into the now empty space. To prevent this and to give the appearance of the
	 * dragged item having been lifted, every element below the dragged element has `transform: translate(0, 49px)` applied
	 * to it, where 49px is the computed height of the element being dragged.
	 *
	 * The only way I can see to get around this is to set the height manually. However, we have another problem. The height
	 * was previously set to 21.5em, but that was done on the assumption that there would always be 6 rows in the table. With
	 * the tie functionality implemented, that is no longer true. We could have just one row in the table if everyone ties
	 * for 1st place. Because of this we must compute the height of this div based on the number of rows being printed in the
	 * table.
	 */
	const getHeightForTable = () => 3.58 * rowCount;

	return (
		<TableContainer
			component={Paper}
			elevation={5}
			sx={{
				mt: 1,
				// Set max width and centering for final standing display
				mx: "auto",
				maxWidth: {
					lg: "735px",
					sm: "100%"
				},
				// Affects child classes with className="row"
				".row": {
					borderTop: "1px solid #515151",
				},
				".row:first-of-type": {
					borderTop: "none"
				},
				height: `${getHeightForTable()}em`,
				overflowY: "hidden"
			}}
		>
			{children}
		</TableContainer>
	);
};
