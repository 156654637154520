import { Typography } from "@mui/material";
import React from "react";

export const RowMessage = ({ text, sx = {} }: { text: string, sx?: any }) => {
	return (
		<Typography component="div" sx={{
			color: "text.secondary",
			textAlign: "center",
			width: "100% !important",
			fontSize: {
				xs: 13,
				sm: "unset"
			},
			...sx
		}}>
			{text}
		</Typography>
	);
};
