import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Box, CircularProgress, Menu, MenuItem } from "@mui/material";
import { requestScopes } from "../../Config/auth-config";
import { useStore } from "../../Store/useStore";


export const SignInButton = ({ isMobile }: { isMobile: boolean }) => {

	const { instance } = useMsal();
	const [isRedirecting, setIsRedirecting] = useState(false);

	const willAuthenticate = useStore((state) => state.willAuthenticate);
	const isAuthenticated = useStore((state) => state.isUserAuthenticated);
	const hasAuthErrored = useStore((state) => state.hasAuthErrored);

	const onSignInClick = () => {
		if (!isAuthenticated || hasAuthErrored) {
			setIsRedirecting(true);
			instance.loginRedirect({
				...requestScopes,
				prompt: "login"
			});
		}
	};

	const onLogoutClick = () => {
		if(isAuthenticated) {
			setIsRedirecting(true);
			instance.logoutRedirect(requestScopes);
		}
	};

	return (
		<IconButton
			color="inherit"
			aria-label="open drawer"
			edge="start"
			onClick={() => onSignInClick()}
			sx={{
				ml: isMobile ? "inherit" : 0,
				fontSize: "1em",
				display: isMobile ? {
					xs: "flex",
					sm: "none"
				} : {
					xs: "none",
					sm: "flex"
				},
				minWidth: "90px",
				justifyContent: "end"
			}}
		>
			{willAuthenticate && isAuthenticated && !hasAuthErrored ? (
				<SignedInMenuButton
					onLogoutClick={onLogoutClick}
					isRedirecting={isRedirecting}
				/>
			) : isRedirecting ? (
				<CircularProgress size="1.5rem" color="warning" />
			) : (
				<>
					<AccountCircleIcon />
					<SignInButtonText text="Sign in" />
				</>
			)}
		</IconButton>
	);
};

interface SignedInMenuButtonProps {
	onLogoutClick: () => void;
	isRedirecting: boolean;
}

const SignedInMenuButton = ({ onLogoutClick, isRedirecting }: SignedInMenuButtonProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [signInText, setSignInText] = useState("Loading...");

	const loggedInUser = useStore((state) => state.loggedInUser);
	const willAuthenticate = useStore((state) => state.willAuthenticate);

	const open = Boolean(anchorEl);
	const onClose = () => setAnchorEl(null);

	useEffect(() => {
		if(loggedInUser) {
			setSignInText(loggedInUser.GivenName ?? loggedInUser.Username);
		} else if(willAuthenticate) {
			setSignInText("Loading...");
		} else {
			setSignInText("N/A");
		}
	}, [loggedInUser, loggedInUser?.GivenName, loggedInUser?.Username, willAuthenticate]);

	return (
		<>
			<Box onClick={(e: any) => setAnchorEl(e.currentTarget)} display="flex">
				{!isRedirecting && (
					<AccountCircleIcon />
				)}

				<SignInButtonText text={signInText} />
			</Box>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={onClose}
				disableScrollLock={true}
			>
				<MenuItem onClick={onClose}>
					<Link to="/profile">
						Profile
					</Link>
				</MenuItem>

				<MenuItem onClick={() => {
					onLogoutClick();
					onClose();
				}}>
					Logout
				</MenuItem>
			</Menu>
		</>
	);
};

const SignInButtonText = ({ text }: { text: string }) => {
	return (
		<Typography
			fontFamily="Roboto"
			component="span"
			ml={0.75}
			fontWeight={500}
			fontSize="0.875em"
			pt="3px"
		>
			{text}
		</Typography>
	);
};
