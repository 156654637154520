import { produce } from "immer";
import { globalSet, PredictionStoreState } from "../useStore";
import { ICompetitionPrediction, IMultiStageCompetitionPrediction } from "../../Domain/Aggregates/CompetitionPrediction/Interfaces/ICompetitionPrediction";
import { Athlete } from "../../Domain/Aggregates/Shared/Athlete";
import { getModifyCommandFactory } from "../../Domain/Aggregates/CompetitionPrediction/Commands/Factories/ModifyPredictionCommandAbstractFactory";
import { PredictionTypeEnum } from "../../Domain/Enums/PredictionTypeEnum";
import { ICommand } from "../../Domain/Aggregates/CompetitionPrediction/Commands/Base/ModifyPredictionCommand";
import { displayModal, hideModal } from "../useStore.utility";
import toast from "react-hot-toast";

const executeCommand = (createCommand: (competitionPrediction: ICompetitionPrediction, promptConfirmed: boolean) => ICommand) => {
	globalSet(produce((draft: PredictionStoreState) => {
		const command = createCommand(draft.competitionPrediction, false);
		const res = draft.competitionPrediction.RunModifyCommand(command);

		if(res.PromptUserToConfirm) {
			displayModal(draft, res.PromptMessages, () => {
				globalSet(produce((draft2: PredictionStoreState) => {
					const command2 = createCommand(draft2.competitionPrediction, true);
					draft2.competitionPrediction.RunModifyCommand(command2);
					hideModal(draft2);
				}));
			});
		}
	}));
};

export type IModifySlice = {
	addAthleteToPrediction: (athlete: Athlete, stageId: string, strongmanEventId?: number) => void;
	removePositionFromPrediction: (position: number, stageId: string, strongmanEventId?: number) => void;
	movePositionWithinPrediction: (indexFrom: number, indexTo: number, stageId: string, strongmanEventId?: number) => void;
	applyTieBetweenAthletesToPrediction: (indexFrom: number, indexTo: number, stageId: string, strongmanEventId?: number) => void;
	changePredictionTypeFor: (groupId: string, predictionType: PredictionTypeEnum) => void;
	createPredictionForFinals: () => void;
	hasSignUpToastBeenShown: boolean;
}

export const createModifySlice = (set: any, get: any): IModifySlice => ({
	addAthleteToPrediction: (athlete: Athlete, stageId: string, strongmanEventId?: number) => {
		if(!get().isUserAuthenticated && !get().hasSignUpToastBeenShown) {
			toast("Sign up to save your selections!", {
				duration: 3000,
				style: {
					borderRadius: "10px",
					background: "#4f4f4f",
					color: "#fff",
					display: "flex",
					alignItems: "center",
				},
			});

			set(produce((draft: PredictionStoreState) => {
				draft.hasSignUpToastBeenShown = true;
			}));
		}

		const createAddAthleteCommand = (competitionPrediction: ICompetitionPrediction, promptConfirmed: boolean) => {
			const factory = getModifyCommandFactory(competitionPrediction);
			return factory.CreateAddAthleteCommand(athlete, stageId, promptConfirmed, strongmanEventId);
		};

		executeCommand(createAddAthleteCommand);
	},

	removePositionFromPrediction: (position: number, stageId: string, strongmanEventId?: number) => {
		const createRemovePositionCommand = (competitionPrediction: ICompetitionPrediction, promptConfirmed: boolean) => {
			const factory = getModifyCommandFactory(competitionPrediction);
			return factory.CreateRemovePositionCommand(position, stageId, promptConfirmed, strongmanEventId);
		};

		executeCommand(createRemovePositionCommand);
	},

	movePositionWithinPrediction: (indexFrom: number, indexTo: number, stageId: string, strongmanEventId?: number) => {
		const createMovePositionCommand = (competitionPrediction: ICompetitionPrediction, promptConfirmed: boolean) => {
			const factory = getModifyCommandFactory(competitionPrediction);
			return factory.CreateMovePositionCommand(indexFrom, indexTo, stageId, promptConfirmed, strongmanEventId);
		};

		executeCommand(createMovePositionCommand);
	},

	applyTieBetweenAthletesToPrediction: (indexFrom: number, indexTo: number, stageId: string, strongmanEventId?: number) => {
		const createApplyTieCommand = (competitionPrediction: ICompetitionPrediction, promptConfirmed: boolean) => {
			const factory = getModifyCommandFactory(competitionPrediction);
			return factory.CreateApplyTieCommand(indexFrom, indexTo, stageId, promptConfirmed, strongmanEventId);
		};

		executeCommand(createApplyTieCommand);
	},

	changePredictionTypeFor: (stageId: string, predictionType: PredictionTypeEnum) => {
		const createAddAthleteCommand = (competitionPrediction: ICompetitionPrediction, promptConfirmed: boolean) => {
			const factory = getModifyCommandFactory(competitionPrediction);
			return factory.CreateChangePredictionTypeCommand(predictionType, stageId, promptConfirmed);
		};

		executeCommand(createAddAthleteCommand);
	},

	createPredictionForFinals: () => {
		set(
			produce((draft: PredictionStoreState) => {
				// we need to do an error check here to make sure the competition is multistage
				(draft.competitionPrediction as unknown as IMultiStageCompetitionPrediction).CreatePredictionForFinals();
			})
		);
	},

	hasSignUpToastBeenShown: false
});
