import { IStagePredictionDto } from "./CompetitionPredictionDto";
import { PredictionTypeEnum } from "../../../Domain/Enums/PredictionTypeEnum";
import { IAthletePositionDto } from "./IAthletePositionDto";

export class LeaderboardPredictionDto implements IStagePredictionDto {
	constructor(
		public readonly StageId: string,
		public readonly PredictionType: PredictionTypeEnum,
		public readonly AthletePositions: Array<IAthletePositionDto>
	) {
		//
	}
}
