import { produce } from "immer";
import { Stage } from "../../Domain/Aggregates/CompetitionStructure/Stage";
import { AddAthleteCommand } from "../../Domain/Aggregates/CompetitionPrediction/Commands/AddAthleteCommand";
import { PredictionStoreState } from "../useStore";
import { IMultiStageCompetitionPrediction } from "../../Domain/Aggregates/CompetitionPrediction/Interfaces/ICompetitionPrediction";
import { CompetitionStructure } from "../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";


export type ISettingsSlice = {
	selectedCompetitionId: number;
	setSelectedCompetitionId: (id: number) => void;
	selectedCompetition: CompetitionStructure | undefined;
	setSelectedCompetition: (newCompetition: CompetitionStructure) => void;
	setSelectedStage: (group: Stage) => void;
	autofillGroups: () => void;
	isAutoSaving: boolean;
	setIsAutoSaving: (isSaving: boolean) => void;
	autoSaveErrorCount: number;
	setAutoSaveErrorCount: (errorCount: number) => void;
	hasAutoSavingBeenDisabled: boolean;
	disableAutoSaving: () => void;
}

// eslint-disable-next-line
export const createSettingsSlice = (set: any, get: any): ISettingsSlice => ({
	selectedCompetitionId: 5,
	setSelectedCompetitionId: (id: number) => {
		set(
			produce((draft: PredictionStoreState) => {
				draft.selectedCompetitionId = id;
			})
		);
	},
	selectedCompetition: undefined,
	setSelectedCompetition: (newCompetition: CompetitionStructure) => {
		set(
			produce((draft: PredictionStoreState) => {
				draft.selectedCompetition = newCompetition;
			})
		);
	},
	setSelectedStage: (group: Stage) => {
		set(
			produce((draft: PredictionStoreState) => {
				(draft.competitionPrediction as unknown as IMultiStageCompetitionPrediction).SetSelectedGroup(group);
			})
		);
	},
	autofillGroups: () => {
		set(
			produce((draft: PredictionStoreState) => {

				draft.competitionPrediction.Competition.Heats.forEach((stage: Stage) => {
					// Get two distinct indexes
					const index1 = Math.floor(Math.random() * stage.Athletes.length);
					let index2;
					do {
						index2 = Math.floor(Math.random() * stage.Athletes.length);
					} while (index1 === index2);

					const athlete1 = stage.Athletes[index1];
					const athlete2 = stage.Athletes[index2];

					draft.competitionPrediction.RunModifyCommand(
						new AddAthleteCommand(draft.competitionPrediction, athlete1, stage.Id, true)
					);

					draft.competitionPrediction.RunModifyCommand(
						new AddAthleteCommand(draft.competitionPrediction, athlete2, stage.Id,  true)
					);
				});
			})
		);
	},
	isAutoSaving: false,
	setIsAutoSaving: (isSaving: boolean) => {
		set(
			produce((draft: PredictionStoreState) => {
				draft.isAutoSaving = isSaving;
			})
		);
	},

	hasAutoSavingBeenDisabled: false,
	disableAutoSaving: () => {
		set(
			produce((draft: PredictionStoreState) => {
				draft.hasAutoSavingBeenDisabled = true;
			})
		);
	},

	autoSaveErrorCount: 0,
	setAutoSaveErrorCount: (errorCount: number) => {
		set(
			produce((draft: PredictionStoreState) => {
				draft.autoSaveErrorCount = errorCount;
			})
		);
	},
});
