import { Stage } from "../../../CompetitionStructure/Stage";
import { Athlete } from "../../../Shared/Athlete";
import { BasePointsCalculationStrategy } from "./BasePointCalculationStrategy";

/**
 * Generating points is currently the same for both Wsm and Asc, but it may be that
 * we implement some competition-specific algorithm in future, so I've split it into
 * different strategies here.
 */
export class Wsm2023PointsCalculationStrategy extends BasePointsCalculationStrategy {
	constructor(stage: Stage, competingAthletes: Array<Athlete>) {
		super(stage, competingAthletes);
	}
}


export class Asc2023PointsCalculationStrategy extends BasePointsCalculationStrategy {
	constructor(stage: Stage, competingAthletes: Array<Athlete>) {
		super(stage, competingAthletes);
	}
}

