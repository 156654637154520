import { CompetitionStructure } from "../CompetitionStructure/CompetitionStructure";
import { BaseStagePrediction } from "../StagePrediction/BaseStagePrediction";
import { Stage } from "../CompetitionStructure/Stage";
import { PredictionTypeEnum } from "../../Enums/PredictionTypeEnum";
import { StagePredictionFactory } from "../StagePrediction/StagePredictionFactory";
import { BaseCompetitionPrediction } from "./BaseCompetitionPrediction";
import { EventPredictions } from "../StagePrediction/EventPredictions/EventPredictions";


export class Asc2023Prediction extends BaseCompetitionPrediction {

	public readonly Competition: CompetitionStructure;
	public Predictions: Array<BaseStagePrediction>;
	public SelectedGroup: Stage;

	constructor(competition: CompetitionStructure, existingPrediction?: BaseStagePrediction, existingId?: string) {
		super(competition, existingId);
		this.Competition = competition;
		this.SelectedGroup = competition.Final;

		if (existingPrediction) {
			this.Predictions = [existingPrediction];
		} else {
			const stage = competition.Stages[0];

			this.Predictions = [new EventPredictions(stage, competition.Ruleset, stage.Athletes)];
		}
	}

	public override CreatePredictionFor(stageId: string, predictionType: PredictionTypeEnum): boolean {
		const stage = this.Competition.GetStage(stageId);

		const newPrediction = StagePredictionFactory.CreatePredictionFor(stage, stage.Athletes, predictionType, this.Competition.Ruleset);
		this.Predictions = [newPrediction];

		return true;
	}
}
