import { useCallback, useEffect, useRef } from "react";
import { commitCompetitionPrediction, identifyChangesSinceLastCommit, } from "../ApplicationServices/Caching/SessionStorageService";
import { useStore } from "../Store/useStore";
import { ISaveResult, performAuthenticatedPost } from "../ApplicationServices/Auth/protected-api-call";
import { IMutatedPredictionsDto } from "../ApplicationServices/Caching/compareCompetitionPredictions";
import { displayErrorToast } from "../Utility/display-error-toast";


export const useAutoSave = (isAutoSaveEnabled: boolean) => {

	const isSaveInProgress = useRef(false);
	const intervalId = useRef<number>();
	const setIsAutoSaving = useStore((state) => state.setIsAutoSaving);
	const setAutoSaveErrorCount = useStore((state) => state.setAutoSaveErrorCount);
	const disableAutoSaving = useStore((state) => state.disableAutoSaving);

	const saveData = useCallback(async () => {
		const state = useStore.getState();

		const autoSaveErrorCount = state.autoSaveErrorCount;
		const hasAutoSavingBeenDisabled = state.hasAutoSavingBeenDisabled;

		if (!isSaveInProgress.current && state.competitionPrediction.HaveSelectionsBeenMade && !hasAutoSavingBeenDisabled) {
			isSaveInProgress.current = true;
			setIsAutoSaving(true);

			try {
				const comparatorResult = identifyChangesSinceLastCommit(state.competitionPrediction);

				if (comparatorResult.MutationsFound) {
					if(autoSaveErrorCount >= 2) {
						disableAutoSaving();
						displayErrorToast("Saving predictions has failed 2 times. Something is broken.");
					} else {
						// TODO: Put these urls into a common location
						const res = await performAuthenticatedPost<IMutatedPredictionsDto, ISaveResult>("Prediction", comparatorResult);

						if (res.IsSuccessful) {
							commitCompetitionPrediction(state.competitionPrediction);
							setAutoSaveErrorCount(0);
						}
					}
				}
			} catch(e) {
				console.error("Error when saving in useAutoSave():");
				console.error(e);
				setAutoSaveErrorCount(autoSaveErrorCount + 1);
			} finally {
				isSaveInProgress.current = false;
				setIsAutoSaving(false);
			}
		}
	}, []);

	useEffect(() => {
		if (isAutoSaveEnabled && !intervalId.current) {
			intervalId.current = window.setInterval(() => {
				saveData();
			}, 5000);
		}

		return () => {
			clearInterval(intervalId.current);
			intervalId.current = undefined;
		};
	}, [isAutoSaveEnabled, saveData]);
};
