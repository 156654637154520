import { SingleEventPrediction } from "../SingleEventPrediction";
import { StagePointsTable } from "../../Scoring/StagePointsTables";
import { Athlete } from "../../../Shared/Athlete";
import { Wsm2023EventPredictionFinalistsSelectionStrategy } from "./Wsm2023EventPredictionFinalistsSelectionStrategy";
import { CompetitionRuleset } from "../../../../Enums/CompetitionRuleset";

export interface IEventPredictionFinalistsSelectionStrategy {
	GetFinalists(eventPredictions: Array<SingleEventPrediction>, pointsTable: StagePointsTable): Array<Athlete>;
}

export class EventPredictionFinalistsSelectionStrategyFactory {
	public static Create(ruleset: CompetitionRuleset): IEventPredictionFinalistsSelectionStrategy {
		switch (ruleset) {
			case CompetitionRuleset.Wsm2023: {
				return new Wsm2023EventPredictionFinalistsSelectionStrategy();
			}
			default: {
				throw new Error(`FinalistsSelectionStrategyFactory was not able to provide a strategy for ruleset: ${ruleset}`);
			}
		}
	}
}
