import { produce } from "immer";


export type IShareModalSlice = {
	isShareModalOpen: boolean;
	openShareModal: () => void;
	closeShareModal: () => void;
	isShareModalLoading: boolean;
	setIsShareModalLoading: (x: boolean) => void;
	shareLinkModalMemorablePhrase: string;
	setShareLinkModalMemorablePhrase: (x: string) => void;
	isReadOnly: boolean;
	setIsReadOnly: (x: boolean) => void;
}

export const createShareModalSlice = (set: any, get: any): IShareModalSlice => ({
	isShareModalOpen: false,

	openShareModal: () => {
		set(
			produce((draft: IShareModalSlice) => {
				draft.isShareModalOpen = true;
			})
		);
	},

	closeShareModal: () => {
		set(
			produce((draft: IShareModalSlice) => {
				draft.isShareModalOpen = false;
			})
		);
	},

	isShareModalLoading: true,

	setIsShareModalLoading: (x: boolean) => {
		set(
			produce((draft: IShareModalSlice) => {
				draft.isShareModalLoading = x;
			})
		);
	},

	shareLinkModalMemorablePhrase: "",

	setShareLinkModalMemorablePhrase: (x: string) => {
		set(
			produce((draft: IShareModalSlice) => {
				draft.shareLinkModalMemorablePhrase = x;
			})
		);
	},

	isReadOnly: false,
	setIsReadOnly: (x: boolean) => {
		set(
			produce((draft: IShareModalSlice) => {
				draft.isReadOnly = x;
			})
		);
	}
});
