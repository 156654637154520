import { IAthletePositionDto } from "./Dtos/IAthletePositionDto";
import { CompetitionPredictionDto, IStagePredictionDto } from "./Dtos/CompetitionPredictionDto";
import { LeaderboardPredictionDto } from "./Dtos/LeaderboardPredictionDto";
import { EventsPredictionDto, SingleEventPredictionDto } from "./Dtos/EventsPredictionDto";
import { AthletePosition } from "../../Domain/ValueObjects/AthletePosition";

/**
 * TODO:
 *
 * - Move all this into a compare() function within each entity
 * - Maybe that compare() function just calls a separate method
 * - Ask GPT about a good pattern to implement here
 */


// On the backend this is called SaveModifiedPredictionsDto (for the command of same name), should we have that name here?
export interface IMutatedPredictionsDto {
	MutationsFound: boolean;
	CompetitionId: number;
	CompetitionPredictionId: string;
	StagePredictionIdsToDelete?: Array<string>;
	MutatedLeaderboardPredictions?: Array<LeaderboardPredictionDto>;
	MutatedEventsPredictions?: Array<EventsPredictionDto>;
	FinalPlacings: Array<IAthletePositionDto>
}

/**
 * Compares an existing competition prediction against a new competition prediction and returns a result which states if changes
 * have been made and what stage predictions contain changes.
 */
export const compareCompetitionPredictions = (oldPrediction: CompetitionPredictionDto | null, newPrediction: CompetitionPredictionDto): IMutatedPredictionsDto => {
	if (!oldPrediction) {
		console.log("There are no old predictions, so the entire newPrediction needs saving");
		return {
			MutationsFound: true,
			CompetitionId: newPrediction.CompetitionId,
			CompetitionPredictionId: newPrediction.PredictionId,
			MutatedLeaderboardPredictions: newPrediction.LeaderboardPredictionDtos,
			MutatedEventsPredictions: newPrediction.EventsPredictionDtos,
			FinalPlacings: newPrediction.FinalPlacings
		};
	}

	const stagePredictionIdsToDelete = getPredictionIdsToDelete(oldPrediction.AllPredictionDtos, newPrediction.AllPredictionDtos);
	const mutatedLeaderboardPredictionDtos = getMutatedLeaderboardPredictions(oldPrediction.LeaderboardPredictionDtos, newPrediction.LeaderboardPredictionDtos);
	const mutatedEventsPredictionDtos = compareEventsPredictions(oldPrediction.EventsPredictionDtos, newPrediction.EventsPredictionDtos);

	return {
		MutationsFound: stagePredictionIdsToDelete.length > 0 || mutatedLeaderboardPredictionDtos.length > 0 || mutatedEventsPredictionDtos.length > 0,
		CompetitionId: newPrediction.CompetitionId,
		CompetitionPredictionId: newPrediction.PredictionId,
		StagePredictionIdsToDelete: stagePredictionIdsToDelete,
		MutatedLeaderboardPredictions: mutatedLeaderboardPredictionDtos,
		MutatedEventsPredictions: mutatedEventsPredictionDtos,
		FinalPlacings: newPrediction.FinalPlacings
	};
};

/**
 * Compares old and new predictions based on a combination of StageId and PredictionType. Returns an array of
 * StageIds from the old predictions that don't have a matching entry in the new predictions
 *
 * TODO: can PredictionId actually be used here? Are we using the frontend-generated PredictionIds on backend? I think we are, in which
 * case we can return to using PredictionId here
 */
const getPredictionIdsToDelete = (oldPredictions: Array<IStagePredictionDto>, newPredictions: Array<IStagePredictionDto>): Array<string> => {
	const newPredictionKeys = new Set(newPredictions.map(p => `${p.StageId}${p.PredictionType}`));

	return oldPredictions
		.filter(op => !newPredictionKeys.has(`${op.StageId}${op.PredictionType}`))
		.map(op => op.StageId);
};

/**
 * Leaderboard predictions
 */
const getMutatedLeaderboardPredictions = (existingDtos: Array<LeaderboardPredictionDto>, latestDtos: Array<LeaderboardPredictionDto>): Array<LeaderboardPredictionDto> => {
	return latestDtos.filter((latestDto: LeaderboardPredictionDto) => {
		const existingDto = existingDtos.find(x => x.StageId === latestDto.StageId);

		if (!existingDto) return true;

		return !areAthletePositionsIdentical(existingDto.AthletePositions, latestDto.AthletePositions);
	});
};


/**
 * Events predictions
 */
const compareEventsPredictions = (existingDtos: Array<EventsPredictionDto>, latestDtos: Array<EventsPredictionDto>): Array<EventsPredictionDto> => {
	return latestDtos.map((latestDto: EventsPredictionDto) => {

		const existingDto = existingDtos.find(x => x.StageId === latestDto.StageId);

		if (!existingDto) return latestDto;

		const mutatedSingleEventDtos = getMutatedSingleEventDtos(latestDto, existingDto);

		if (mutatedSingleEventDtos.length === 0) return undefined;

		return new EventsPredictionDto(
			latestDto.StageId,
			latestDto.PredictionType,
			mutatedSingleEventDtos
		);
	}).filter((x): x is EventsPredictionDto => x !== undefined);
};

const getMutatedSingleEventDtos = (existingDto: EventsPredictionDto, newPrediction: EventsPredictionDto): Array<SingleEventPredictionDto> => {
	return existingDto.IndividualEvents.filter((existingSingleEventDto: SingleEventPredictionDto) => {
		const newSingleEventDto = newPrediction.IndividualEvents.find(x => x.EventTypeName === existingSingleEventDto.EventTypeName);

		return !areAthletePositionsIdentical(existingSingleEventDto.AthletePositions, newSingleEventDto?.AthletePositions);
	});
};


const areAthletePositionsIdentical = (a?: Array<IAthletePositionDto>, b?: Array<IAthletePositionDto>): boolean => {
	if (!a || !b) {			// If one of them is falsey...
		return a === b;		// ...return true if they're both falsey (i.e. no change) or false is one is truthy and the other not
	}

	const aSorted = sortAthletePositions(a);
	const bSorted = sortAthletePositions(b);

	if (aSorted.length !== bSorted.length) return false;

	for (let i = 0; i < aSorted.length; i++) {
		if (aSorted[i].Position !== bSorted[i].Position) return false;
		if (aSorted[i].AthleteIds.length !== bSorted[i].AthleteIds.length) return false;

		// Check the array of athleteIds is identical
		for (let j = 0; j < aSorted[i].AthleteIds.length; j++) {
			if (aSorted[i].AthleteIds[j] !== bSorted[i].AthleteIds[j]) return false;
		}
	}

	return true;
};

// GPT Generated
const sortAthletePositions = (athletes: Array<IAthletePositionDto>): Array<IAthletePositionDto> => {
	return athletes
		.slice()                                                            // Create a copy of the array to avoid mutating the original
		.sort((a, b) => a.Position - b.Position)                            // Sort by position
		.map(athlete => ({ 													// Create a new object
			...athlete, 													// Copy all existing properties
			AthleteIds: athlete.AthleteIds.slice().sort((a, b) => a - b), 	// Copy and sort the AthleteIds
		}));
};
