import { CompetitionStructure } from "../../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import { CompetitionViewModel, mapCompetitionViewModelToEntity } from "../ViewModels/CompetitionViewModel";


export const loadCompetitionQuery = async (selectedCompetitionId: number): Promise<CompetitionStructure> => {
	// const data = getCompetitionLocal(selectedCompetitionId);
	const data = await performApiCall(selectedCompetitionId);

	const competitionStructure = mapCompetitionViewModelToEntity(data);

	return competitionStructure;
};


const performApiCall = async (selectedCompetitionId: number): Promise<CompetitionViewModel> => {
	const res = await fetch(`${process.env.REACT_APP_apiBaseUri}/api/Competition/${selectedCompetitionId}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json"
		}
	});

	const data = await res.json() as CompetitionViewModel;

	return data;
};

const getLocalJsonWsm23 = (): CompetitionViewModel => {
	return {
		"Id": 1, // was 1, set to 2
		"Name": "World's Strongest Man",
		"Year": 2022,
		"Ruleset": 1,
		"Stages": [
			{
				"Id": "e6720317-6342-4b0d-b63c-1f06f5a64a5f",
				"Name": "Final",
				"DisplayOrder": 6,
				"IsFinal": true,
				"Athletes": [],
				"Events": [
					{
						"Id": 31,
						"StrongmanEventType": {
							"Name": "KNAACK Giant's Medley"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 32,
						"StrongmanEventType": {
							"Name": "Deadlift"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 33,
						"StrongmanEventType": {
							"Name": "Flintstone Barbell"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 34,
						"StrongmanEventType": {
							"Name": "Vehicle Pull"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 35,
						"StrongmanEventType": {
							"Name": "Power Stairs"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 36,
						"StrongmanEventType": {
							"Name": "Atlas Stones"
						},
						"DisplayOrder": 60
					}
				]
			},
			{
				"Id": "dc45be69-f985-4c83-bacf-45c96712b3e1",
				"Name": "Group 2",
				"DisplayOrder": 2,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 7,
						"AthleteName": "Mark Felix",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 8,
						"AthleteName": "Bobby Thompson",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 9,
						"AthleteName": "Mitchell Hooper",
						"Country": {
							"Name": "Canada",
							"CountryCode": "ca"
						}
					},
					{
						"Id": 10,
						"AthleteName": "Brian Shaw",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 11,
						"AthleteName": "Gabriel Peña",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 12,
						"AthleteName": "Konstantine Janashia",
						"Country": {
							"Name": "Georgia",
							"CountryCode": "ge"
						}
					}
				],
				"Events": [
					{
						"Id": 7,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 8,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 9,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 10,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 11,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 12,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			},
			{
				"Id": "3bce55f2-12d7-42ab-9a1b-6a82e2b55067",
				"Name": "Group 3",
				"DisplayOrder": 3,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 13,
						"AthleteName": "Oleksii Novikov",
						"Country": {
							"Name": "Ukraine",
							"CountryCode": "ua"
						}
					},
					{
						"Id": 14,
						"AthleteName": "Trey Mitchell",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 15,
						"AthleteName": "Adam Bishop",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 16,
						"AthleteName": "Mika Törrö",
						"Country": {
							"Name": "Finland",
							"CountryCode": "fi"
						}
					},
					{
						"Id": 17,
						"AthleteName": "Rob Kearney",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 18,
						"AthleteName": "Grzegorz Szymanski",
						"Country": {
							"Name": "Poland",
							"CountryCode": "pl"
						}
					}
				],
				"Events": [
					{
						"Id": 13,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 14,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 15,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 16,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 17,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 18,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			},
			{
				"Id": "c4f3b5e1-2aa9-4b38-95c5-b1c40fd5d4f1",
				"Name": "Group 5",
				"DisplayOrder": 5,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 25,
						"AthleteName": "Luke Stoltman",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 26,
						"AthleteName": "Eythor Melsted",
						"Country": {
							"Name": "Iceland",
							"CountryCode": "is"
						}
					},
					{
						"Id": 27,
						"AthleteName": "Jean-Stephen Coraboeuf",
						"Country": {
							"Name": "France",
							"CountryCode": "fr"
						}
					},
					{
						"Id": 28,
						"AthleteName": "Kelvin De Ruiter",
						"Country": {
							"Name": "Netherlands",
							"CountryCode": "nl"
						}
					},
					{
						"Id": 29,
						"AthleteName": "Evan Singleton",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 30,
						"AthleteName": "Kim Ujarak",
						"Country": {
							"Name": "Greenland",
							"CountryCode": "gl"
						}
					}
				],
				"Events": [
					{
						"Id": 25,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 26,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 27,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 28,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 29,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 30,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			},
			{
				"Id": "a2c61e99-df99-4fc3-8f73-b22dc244474b",
				"Name": "Group 1",
				"DisplayOrder": 1,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 1,
						"AthleteName": "Tom Stoltman",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 2,
						"AthleteName": "Andy Black",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 3,
						"AthleteName": "Kevin Faires",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 4,
						"AthleteName": "Aivars Smaukstelis",
						"Country": {
							"Name": "Latvia",
							"CountryCode": "lv"
						}
					},
					{
						"Id": 5,
						"AthleteName": "Manuel Angulo",
						"Country": {
							"Name": "Chile",
							"CountryCode": "cl"
						}
					},
					{
						"Id": 6,
						"AthleteName": "Gabriel Rheaume",
						"Country": {
							"Name": "Canada",
							"CountryCode": "ca"
						}
					}
				],
				"Events": [
					{
						"Id": 1,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 2,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 3,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 4,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 5,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 6,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			},
			{
				"Id": "a2d7aa51-3c8f-409b-9acf-f3354e8c84a2",
				"Name": "Group 4",
				"DisplayOrder": 4,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 19,
						"AthleteName": "Martins Licis",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 20,
						"AthleteName": "Maxime Boudreault",
						"Country": {
							"Name": "Canada",
							"CountryCode": "ca"
						}
					},
					{
						"Id": 21,
						"AthleteName": "Pavlo Kordiyaka",
						"Country": {
							"Name": "Ukraine",
							"CountryCode": "ua"
						}
					},
					{
						"Id": 22,
						"AthleteName": "Gavin Bilton",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 23,
						"AthleteName": "Nedžmin Ambešković",
						"Country": {
							"Name": "Bosnia And Herzegovina",
							"CountryCode": "ba"
						}
					},
					{
						"Id": 24,
						"AthleteName": "Shane Flowers",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					}
				],
				"Events": [
					{
						"Id": 19,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 20,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 21,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 22,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 23,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 24,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			}
		],
		"Heats": [
			{
				"Id": "dc45be69-f985-4c83-bacf-45c96712b3e1",
				"Name": "Group 2",
				"DisplayOrder": 2,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 7,
						"AthleteName": "Mark Felix",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 8,
						"AthleteName": "Bobby Thompson",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 9,
						"AthleteName": "Mitchell Hooper",
						"Country": {
							"Name": "Canada",
							"CountryCode": "ca"
						}
					},
					{
						"Id": 10,
						"AthleteName": "Brian Shaw",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 11,
						"AthleteName": "Gabriel Peña",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 12,
						"AthleteName": "Konstantine Janashia",
						"Country": {
							"Name": "Georgia",
							"CountryCode": "ge"
						}
					}
				],
				"Events": [
					{
						"Id": 7,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 8,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 9,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 10,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 11,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 12,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			},
			{
				"Id": "3bce55f2-12d7-42ab-9a1b-6a82e2b55067",
				"Name": "Group 3",
				"DisplayOrder": 3,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 13,
						"AthleteName": "Oleksii Novikov",
						"Country": {
							"Name": "Ukraine",
							"CountryCode": "ua"
						}
					},
					{
						"Id": 14,
						"AthleteName": "Trey Mitchell",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 15,
						"AthleteName": "Adam Bishop",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 16,
						"AthleteName": "Mika Törrö",
						"Country": {
							"Name": "Finland",
							"CountryCode": "fi"
						}
					},
					{
						"Id": 17,
						"AthleteName": "Rob Kearney",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 18,
						"AthleteName": "Grzegorz Szymanski",
						"Country": {
							"Name": "Poland",
							"CountryCode": "pl"
						}
					}
				],
				"Events": [
					{
						"Id": 13,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 14,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 15,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 16,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 17,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 18,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			},
			{
				"Id": "c4f3b5e1-2aa9-4b38-95c5-b1c40fd5d4f1",
				"Name": "Group 5",
				"DisplayOrder": 5,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 25,
						"AthleteName": "Luke Stoltman",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 26,
						"AthleteName": "Eythor Melsted",
						"Country": {
							"Name": "Iceland",
							"CountryCode": "is"
						}
					},
					{
						"Id": 27,
						"AthleteName": "Jean-Stephen Coraboeuf",
						"Country": {
							"Name": "France",
							"CountryCode": "fr"
						}
					},
					{
						"Id": 28,
						"AthleteName": "Kelvin De Ruiter",
						"Country": {
							"Name": "Netherlands",
							"CountryCode": "nl"
						}
					},
					{
						"Id": 29,
						"AthleteName": "Evan Singleton",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 30,
						"AthleteName": "Kim Ujarak",
						"Country": {
							"Name": "Greenland",
							"CountryCode": "gl"
						}
					}
				],
				"Events": [
					{
						"Id": 25,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 26,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 27,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 28,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 29,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 30,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			},
			{
				"Id": "a2c61e99-df99-4fc3-8f73-b22dc244474b",
				"Name": "Group 1",
				"DisplayOrder": 1,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 1,
						"AthleteName": "Tom Stoltman",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 2,
						"AthleteName": "Andy Black",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 3,
						"AthleteName": "Kevin Faires",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 4,
						"AthleteName": "Aivars Smaukstelis",
						"Country": {
							"Name": "Latvia",
							"CountryCode": "lv"
						}
					},
					{
						"Id": 5,
						"AthleteName": "Manuel Angulo",
						"Country": {
							"Name": "Chile",
							"CountryCode": "cl"
						}
					},
					{
						"Id": 6,
						"AthleteName": "Gabriel Rheaume",
						"Country": {
							"Name": "Canada",
							"CountryCode": "ca"
						}
					}
				],
				"Events": [
					{
						"Id": 1,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 2,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 3,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 4,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 5,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 6,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			},
			{
				"Id": "a2d7aa51-3c8f-409b-9acf-f3354e8c84a2",
				"Name": "Group 4",
				"DisplayOrder": 4,
				"IsFinal": false,
				"Athletes": [
					{
						"Id": 19,
						"AthleteName": "Martins Licis",
						"Country": {
							"Name": "USA",
							"CountryCode": "us"
						}
					},
					{
						"Id": 20,
						"AthleteName": "Maxime Boudreault",
						"Country": {
							"Name": "Canada",
							"CountryCode": "ca"
						}
					},
					{
						"Id": 21,
						"AthleteName": "Pavlo Kordiyaka",
						"Country": {
							"Name": "Ukraine",
							"CountryCode": "ua"
						}
					},
					{
						"Id": 22,
						"AthleteName": "Gavin Bilton",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					},
					{
						"Id": 23,
						"AthleteName": "Nedžmin Ambešković",
						"Country": {
							"Name": "Bosnia And Herzegovina",
							"CountryCode": "ba"
						}
					},
					{
						"Id": 24,
						"AthleteName": "Shane Flowers",
						"Country": {
							"Name": "UK",
							"CountryCode": "gb"
						}
					}
				],
				"Events": [
					{
						"Id": 19,
						"StrongmanEventType": {
							"Name": "Loading Race"
						},
						"DisplayOrder": 10
					},
					{
						"Id": 20,
						"StrongmanEventType": {
							"Name": "Deadlift Ladder"
						},
						"DisplayOrder": 20
					},
					{
						"Id": 21,
						"StrongmanEventType": {
							"Name": "Car Walk"
						},
						"DisplayOrder": 30
					},
					{
						"Id": 22,
						"StrongmanEventType": {
							"Name": "Log Lift"
						},
						"DisplayOrder": 40
					},
					{
						"Id": 23,
						"StrongmanEventType": {
							"Name": "Wrecking Ball Hold"
						},
						"DisplayOrder": 50
					},
					{
						"Id": 24,
						"StrongmanEventType": {
							"Name": "Stone Off"
						},
						"DisplayOrder": 60
					}
				]
			}
		]
	};
};

const getLocalJsonAsc23 = () => {
	return {
		"id": 3,
		"name": "Arnold Strongman Classic",
		"year": 2023,
		"ruleset": 2,
		"stages": [
			{
				"id": "7c8ee4a5-6507-4f4e-9fb8-56481a45a3b5",
				"name": "Finals",
				"displayOrder": 1,
				"isFinal": true,
				"athletes": [
					{
						"id": 1,
						"athleteName": "Tom Stoltman",
						"country": {
							"name": "UK",
							"countryCode": "gb"
						}
					},
					{
						"id": 3,
						"athleteName": "Kevin Faires",
						"country": {
							"name": "USA",
							"countryCode": "us"
						}
					},
					{
						"id": 8,
						"athleteName": "Bobby Thompson",
						"country": {
							"name": "USA",
							"countryCode": "us"
						}
					},
					{
						"id": 9,
						"athleteName": "Mitchell Hooper",
						"country": {
							"name": "Canada",
							"countryCode": "ca"
						}
					},
					{
						"id": 14,
						"athleteName": "Trey Mitchell",
						"country": {
							"name": "USA",
							"countryCode": "us"
						}
					},
					{
						"id": 17,
						"athleteName": "Rob Kearney",
						"country": {
							"name": "USA",
							"countryCode": "us"
						}
					},
					{
						"id": 25,
						"athleteName": "Luke Stoltman",
						"country": {
							"name": "UK",
							"countryCode": "gb"
						}
					},
					{
						"id": 34,
						"athleteName": "Mateusz Kieliszkowski",
						"country": {
							"name": "Poland",
							"countryCode": "pl"
						}
					},
					{
						"id": 43,
						"athleteName": "Thomas Evans",
						"country": {
							"name": "USA",
							"countryCode": "us"
						}
					},
					{
						"id": 44,
						"athleteName": "Pavlo Nakonechnyy",
						"country": {
							"name": "Ukraine",
							"countryCode": "ua"
						}
					}
				],
				"events": [
					{
						"id": 73,
						"strongmanEventType": {
							"name": "Wheel of Pain"
						},
						"displayOrder": 10
					},
					{
						"id": 74,
						"strongmanEventType": {
							"name": "Austrian Oak Log Press"
						},
						"displayOrder": 20
					},
					{
						"id": 75,
						"strongmanEventType": {
							"name": "Elephant Bar Deadlift"
						},
						"displayOrder": 30
					},
					{
						"id": 76,
						"strongmanEventType": {
							"name": "Unspunnen Stone Throw"
						},
						"displayOrder": 40
					},
					{
						"id": 77,
						"strongmanEventType": {
							"name": "Timber Frame Carry"
						},
						"displayOrder": 50
					}
				]
			}
		],
		"heats": []
	};
};

const jsonMap: Array<any> = [];
jsonMap[1] = getLocalJsonWsm23();
jsonMap[2] = getLocalJsonWsm23();
jsonMap[3] = getLocalJsonAsc23();

const getCompetitionLocal = (competitionId: number) => {
	return jsonMap[competitionId];
};
