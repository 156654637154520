import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CompetitionPaper } from "../../Components/PredictionSelection/CompetitionPaper";
import { useQuery } from "react-query";
import { CompetitionStructure } from "../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import {
	ILoadPredictionForUserReadModel, mapLoadPredictionForUserReadModelToBaseCompetitionPredictionEntity
} from "../../ApplicationServices/Queries/LoadPredictionsForUserQuery/ReadModels/ILoadPredictionForUserReadModel";
import { LoadingSpinner } from "../../Components/Global/LoadingSpinner";
import { useStore } from "../../Store/useStore";
import { mapCompetitionViewModelToEntity } from "../../ApplicationServices/Queries/ViewModels/CompetitionViewModel";
import { specificCompetitionComponentFactory } from "../Home/specificCompetitionComponentFactory";
import { Alert } from "@mui/material";


interface ISharedPredictionResponse {
	Competition: CompetitionStructure;
	CompetitionPrediction: ILoadPredictionForUserReadModel;
}

export const SharePage = () => {

	const { param } = useParams();

	const setSelectedCompetition = useStore((state) => state.setSelectedCompetition);
	const selectedCompetition = useStore((state) => state.selectedCompetition);
	const setCompetitionPrediction = useStore((state) => state.setCompetitionPrediction);
	const competitionPrediction = useStore((state) => state.competitionPrediction);
	const setIsReadOnly = useStore((state) => state.setIsReadOnly);
	const setSelectedStage = useStore((state) => state.setSelectedStage);

	setIsReadOnly(true);

	const { data, isLoading } = useQuery<ISharedPredictionResponse>(
		["shared-competition", param],
		async () => performGet()
	);

	const performGet = async (): Promise<ISharedPredictionResponse> => {
		const res = await fetch(`${process.env.REACT_APP_apiBaseUri}/api/share/get-share-link/${param}`, {
			method: "GET",
			mode: "cors",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			}
		});

		return await res.json() as ISharedPredictionResponse;
	};

	useEffect(() => {
		if(data) {
			const competition = mapCompetitionViewModelToEntity(data.Competition);
			const prediction = mapLoadPredictionForUserReadModelToBaseCompetitionPredictionEntity(competition, data.CompetitionPrediction);
			setSelectedCompetition(competition);
			setCompetitionPrediction(prediction);
		}
	}, [data]);

	useEffect(() => {
		if(selectedCompetition && !competitionPrediction.SelectedGroup) {
			setSelectedStage(selectedCompetition.Heats[0]);
		}
	}, [selectedCompetition, competitionPrediction]);

	return (
		isLoading || !selectedCompetition ? (
			<LoadingSpinner />
		) : (
			<>
				<Alert severity="warning">You are viewing a shared prediction in a read-only mode</Alert>


				{/*{data?.CompetitionPrediction?.OwnerId}*/}
				{specificCompetitionComponentFactory(competitionPrediction.Competition.Ruleset)}
			</>
		)
	);
};
