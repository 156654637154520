import { AthletePosition } from "../../../Domain/ValueObjects/AthletePosition";
import React from "react";
import { TableContainerWrapper } from "./TableContainerWrapper";
import { RowContentWrapper } from "./RowContentWrapper";
import { CustomCell } from "./CustomCell";
import { RowWithContent } from "./RowWithContent";
import { RowWithoutContent } from "./RowWithoutContent";
import { Box } from "@mui/material";
import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";


interface AthleteOrderingTableReadOnlyProps {
	rows: Array<AthletePosition>;
	nextPositionToSelect: number;
	emptyRowsToRender: number;
	getStatsPercentageFor: (athletes: Athlete[], position: number) => number | undefined;
}

export const AthleteOrderingTableReadOnly = React.memo(({
	rows,
	nextPositionToSelect,
	emptyRowsToRender,
	getStatsPercentageFor
}: AthleteOrderingTableReadOnlyProps) => {
	// console.log(".....AthleteOrderingTableReadOnly rendered");

	return (
		<TableContainerWrapper rowCount={rows.length + emptyRowsToRender}>
			{rows.map((row: AthletePosition) => (
				<Box key={`readonly-row-${row.Position}`} className="row">
					<RowContentWrapper hasAthletes={row.Athletes.length > 0}>
						<CustomCell sx={{ textAlign: "center" }}>{row.Position}</CustomCell>

						{row.Athletes.length > 0 ? (
							<RowWithContent athletes={row.Athletes} statsPercentage={getStatsPercentageFor(row.Athletes, row.Position)} />
						) : (
							<RowWithoutContent
								position={row.Position}
								displayMessage={row.Position === nextPositionToSelect}
							/>
						)}
					</RowContentWrapper>
				</Box>
			))}

			{Array.from(Array(emptyRowsToRender)).map((x, index) => (
				<Box key={`readonly-row-${index}`} className="row">
					<RowContentWrapper hasAthletes={false}>
						<CustomCell sx={{ textAlign: "center" }}>{nextPositionToSelect + index}</CustomCell>
						<RowWithoutContent
							position={nextPositionToSelect + index}
							displayMessage={false}
						/>
					</RowContentWrapper>
				</Box>
			))}
		</TableContainerWrapper>
	);
});
