import { mapStrongmanEventTypeViewModelToEntity, StrongmanEventTypeViewModel } from "./StrongmanEventTypeViewModel";
import { StrongmanEvent } from "../../../Domain/Aggregates/CompetitionStructure/StrongmanEvent";

export interface StrongmanEventViewModel {
	Id: number;
	StrongmanEventType: StrongmanEventTypeViewModel;
	DisplayOrder: number;
}

export const mapStrongmanEventViewModelToEntity = (viewModel: StrongmanEventViewModel): StrongmanEvent => {
	return new StrongmanEvent(
		viewModel.Id,
		mapStrongmanEventTypeViewModelToEntity(viewModel.StrongmanEventType),
		viewModel.DisplayOrder
	);
};
