import React from "react";
import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";
import { CircleFlag } from "react-circle-flags";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Typography } from "@mui/material";
import { CustomCell } from "./CustomCell";
import { useStore } from "../../../Store/useStore";
import { StatsIndicator } from "./StatsIndicator";


export const RowWithContent = ({ athletes, statsPercentage }: { athletes: Array<Athlete>, statsPercentage?: number }) => {

	const isReadOnly = useStore((state) => state.isReadOnly);

	return (
		<>
			<CustomCell sx={{ px: 0, textAlign: "center" }}>
				{athletes.length === 1 ? (

					<CircleFlag
						countryCode={athletes[0].Country.CountryCode}
						height="17"
						style={{ marginBottom: "-0.15em" }}
					/>
				) : (
					<Typography
						fontSize="0.85em"
						fontWeight={600}
						color="warning.main"
					>
						TIE
					</Typography>
				)}
			</CustomCell>

			<CustomCell>{athletes.map(x => x.AthleteName).join(", ")}</CustomCell>

			<StatsIndicator percentage={statsPercentage} />

			{!isReadOnly && (
				<CustomCell sx={{ 
					textAlign: "right", 
					px: "1em" 
				}}>
					<DragHandleIcon
						sx={{
							":hover": {
								cursor: "pointer"
							}
						}}
					/>
				</CustomCell>
			)}
		</>
	);
};
