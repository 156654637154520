import Box from "@mui/material/Box";
import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStore } from "../../Store/useStore";

const textDuringSave = "Saving";
const textAfterSave = "Saved";

export const AutoSaveSpinner = React.memo(() => {

	const isAutoSaving = useStore((state) => state.isAutoSaving);
	const [text, setText] = useState<string>("Saving");
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		if(isAutoSaving) {
			setIsVisible(true);
			setText(textDuringSave);
		} else {
			setText(textAfterSave);

			// Set a timeout of 1s so the component doesn't just flash on/off
			const timeoutId = setTimeout(() => {
				setIsVisible(false);
			}, 1000);

			return () => clearTimeout(timeoutId);
		}
	}, [isAutoSaving]);

	return (
		<Box sx={{
			position: "fixed",
			right: 0,
			bottom: 0,
			display: "flex",
			alignItems: "center",
			visibility: isVisible ? "visible" : "hidden"
		}}>
			<Typography fontSize="0.8rem" color="secondary.lighter" marginBottom="0.2rem" marginRight="0.3rem">
				{text}
			</Typography>

			<CircularProgress
				size="1rem"
				sx={{
					display: text === textAfterSave
						? "none"
						: "block"
				}}
			/>
		</Box>
	);
});
