import { IModifyCommandAbstractFactory } from "./ModifyPredictionCommandAbstractFactory";
import { Asc2023Prediction } from "../../Asc2023Prediction";
import { Athlete } from "../../../Shared/Athlete";
import { AddAthleteCommand } from "../AddAthleteCommand";
import { PredictionTypeEnum } from "../../../../Enums/PredictionTypeEnum";
import { ChangePredictionTypeCommand } from "../ChangePredictionTypeCommand";
import { RemovePositionCommand } from "../RemovePositionCommand";
import { MoveAthleteCommand } from "../MoveAthleteCommand";
import { ApplyTieCommand } from "../ApplyTieCommand";

export class Asc2023ModifyCommandFactory implements IModifyCommandAbstractFactory {
	private readonly _competition: Asc2023Prediction;

	constructor(competition: Asc2023Prediction) {
		this._competition = competition;
	}

	public CreateAddAthleteCommand(athlete: Athlete, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): AddAthleteCommand<Asc2023Prediction> {
		return new AddAthleteCommand<Asc2023Prediction>(
			this._competition,
			athlete,
			stageId,
			promptConfirmed,
			strongmanEventId
		);
	}

	public CreateChangePredictionTypeCommand(predictionType: PredictionTypeEnum, stageId: string, promptConfirmed: boolean): ChangePredictionTypeCommand<Asc2023Prediction> {
		return new ChangePredictionTypeCommand<Asc2023Prediction>(
			this._competition,
			predictionType,
			stageId,
			promptConfirmed
		);
	}

	public CreateRemovePositionCommand(position: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): RemovePositionCommand<Asc2023Prediction> {
		return new RemovePositionCommand<Asc2023Prediction>(
			this._competition,
			position,
			stageId,
			promptConfirmed,
			strongmanEventId
		);
	}

	public CreateMovePositionCommand(indexFrom: number, indexTo: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): MoveAthleteCommand<Asc2023Prediction> {
		return new MoveAthleteCommand<Asc2023Prediction>(
			this._competition,
			indexFrom,
			indexTo,
			stageId,
			promptConfirmed,
			strongmanEventId
		);
	}


	public CreateApplyTieCommand(indexFrom: number, indexTo: number, stageId: string, promptConfirmed: boolean, strongmanEventId?: number): ApplyTieCommand<Asc2023Prediction> {
		return new ApplyTieCommand<Asc2023Prediction>(
			this._competition,
			indexFrom,
			indexTo,
			stageId,
			promptConfirmed,
			strongmanEventId
		);
	}
}
