import { AccountInfo } from "@azure/msal-browser";


export class LoggedInUser {
	public readonly GivenName: string;
	public readonly Username: string;
	public readonly Country: string;
	public readonly UserId: string;
	public readonly SignedInAt: Date;
	public TokenExpiresOn: Date;
	public readonly IdentityProvider: string;
	public readonly Email: string;

	constructor(givenName: string, username: string, country: string, userId: string, signedInAt: number, tokebExpireOn: number, idp: string, email: string) {
		this.GivenName = givenName;
		this.Username = username;
		this.Country = country;
		this.UserId = userId;
		this.SignedInAt = new Date(signedInAt * 1000);
		this.TokenExpiresOn = new Date(tokebExpireOn * 1000);
		this.IdentityProvider = idp;
		this.Email = email;
	}

	public get SignedInAsString(): string {
		return this.getDateAsString(this.SignedInAt);
	}

	public get TokenExpiresOnAsString(): string {
		return this.getDateAsString(this.TokenExpiresOn);
	}

	private getDateAsString(date: Date): string {
		return date
			.toISOString()
			.replace(".000Z", "")
			.replace("T", " @ ");
	}

	public static Create(account: AccountInfo | null): LoggedInUser {

		if (!account) {
			throw new Error("Cannot create logged in user from null accounts");
		}

		if (!account?.idTokenClaims) {
			throw new Error("Account or ID token claims are missing");
		}

		const uniqueId = account.idTokenClaims.sub ?? "";

		if(uniqueId == "") {
			throw new Error("sub cannot be empty");
		}

		const country = account.idTokenClaims.country as string ?? "N/A";
		const givenName = account.idTokenClaims.given_name as string ?? "Anon";
		const name = account.idTokenClaims.name ?? "Anon";
		const signedInAt = account.idTokenClaims.iat ?? -1;
		const expiresOn = account.idTokenClaims.exp ?? -1;
		const idp = account.idTokenClaims.idp ?? "No IDP";
		const emails = account.idTokenClaims.emails ?? [];
		const email = emails.length === 0 ? "N/A" : emails[0];

		return new LoggedInUser(
			givenName,
			name,
			country,
			uniqueId,
			signedInAt,
			expiresOn,
			idp,
			email
		);
	}
}

