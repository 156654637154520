import { CountryViewModel, mapCountryViewModelToEntity } from "./CountryViewModel";
import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";

export interface AthleteViewModel {
	Id: number;
	AthleteName: string;
	Country: CountryViewModel;
}

export const mapAthleteViewModelToEntity = (viewModel: AthleteViewModel): Athlete => {
	const country = mapCountryViewModelToEntity(viewModel.Country);

	return new Athlete(viewModel.Id, viewModel.AthleteName, country);
};
