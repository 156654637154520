import React from "react";
import { useStore } from "../../../Store/useStore";
import { Asc2023Prediction } from "../../../Domain/Aggregates/CompetitionPrediction/Asc2023Prediction";
import { AthleteDisplayGrid } from "../../../Components/Global/AthleteDisplayGrid";
import { FinalStageSection } from "../../../Components/StagedSelection/FinalStage/FinalStageSection";
import { CompetitionPaper } from "../../../Components/PredictionSelection/CompetitionPaper";

export const Asc2023Component = () => {

	const competitionPrediction = useStore((state) => state.competitionPrediction) as Asc2023Prediction;

	return (
		<CompetitionPaper>
			<AthleteDisplayGrid athletes={competitionPrediction.Competition.Final.Athletes} />

			<FinalStageSection />
		</CompetitionPaper>
	);
};

