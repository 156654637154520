import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { GroupSelectionRow } from "../../../Components/StagedSelection/GroupStage/GroupSelectionRow";
import { SelectPredictionTypeTabs } from "../../../Components/PredictionSelection/SelectPredictionTypeTabs";
import { PredictionTypeEnum } from "../../../Domain/Enums/PredictionTypeEnum";
import { LeaderboardCard } from "../../../Components/PredictionSelection/Leaderboard/LeaderboardCard";
import { EventCardListWithStoneOff } from "../../../Components/PredictionSelection/IndividualEvents/EventCardListWithStoneOff";
import { useStore } from "../../../Store/useStore";
import { Wsm2023Prediction } from "../../../Domain/Aggregates/CompetitionPrediction/Wsm2023Prediction";
import { CompetitionPaper } from "../../../Components/PredictionSelection/CompetitionPaper";


export const Wsm2023GroupsComponent = () => {

	const competitionPrediction = useStore((state) => state.competitionPrediction) as Wsm2023Prediction;
	const predictionForCurrentGroup = useStore((state) => state.getPredictionForCurrentGroup());
	const changePredictionTypeFor = useStore((state) => state.changePredictionTypeFor);
	const isReadOnly = useStore((state) => state.isReadOnly);

	const changePredictionType = React.useCallback((predictionType: PredictionTypeEnum) => {
		changePredictionTypeFor(competitionPrediction.SelectedGroup.Id, predictionType);
	}, [competitionPrediction.SelectedGroup.Id]);

	return (
		<Box>
			<GroupSelectionRow groups={competitionPrediction.Competition.Heats} />

			<CompetitionPaper>

				{!isReadOnly && (
					<Box>
						<SelectPredictionTypeTabs
							predictionType={predictionForCurrentGroup.PredictionType}
							setPredictionType={changePredictionType}
							leaveSpaceForButtons={true}
						/>

						<Typography variant="h6" component="div">
							Your
							<Typography
								variant="h6"
								component="span"
								color="warning.light"
							>
								{` ${competitionPrediction.SelectedGroup.Name} `}
							</Typography>
							Predictions:
						</Typography>
					</Box>
				)}

				{predictionForCurrentGroup.PredictionType === PredictionTypeEnum.Leaderboard ? (
					<LeaderboardCard
						stageId={competitionPrediction.SelectedGroup.Id}
						isFinal={competitionPrediction.SelectedGroup.IsFinal}
					/>
				) : predictionForCurrentGroup.PredictionType === PredictionTypeEnum.Events ? (
					<EventCardListWithStoneOff stage={competitionPrediction.SelectedGroup} />
				) : (
					<h6>Prediction type not recognised or set</h6>
				)}

			</CompetitionPaper>
		</Box>
	);
};

