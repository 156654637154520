import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { CompetitionStructure } from "../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import { useStore } from "../Store/useStore";
import { loadCompetitionQuery } from "../ApplicationServices/Queries/LoadCompetitionQuery/loadCompetitionQuery";
import { competitionPredictionFactory } from "../Domain/Aggregates/CompetitionPrediction/Factories/CompetitionPredictionFactory";


export const useFetchCompetition = () => {

	const [isSetup, setIsSetup] = useState<boolean>(false);
	const [isMinLoadingTimeMet, setIsMinLoadingTimeMet] = useState<boolean>(false);

	const selectedCompetitionId = useStore((state) => state.selectedCompetitionId);
	const setSelectedCompetition = useStore((state) => state.setSelectedCompetition);
	const setCompetitionPrediction = useStore((state) => state.setCompetitionPrediction);

	const { data, isLoading } = useQuery<CompetitionStructure>(
		["competition", selectedCompetitionId],
		async () => loadCompetitionQuery(selectedCompetitionId)
	);

	useEffect(() => {
		if (data) {
			setSelectedCompetition(data);

			// Maybe we shouldn't be setting the CompetitionPrediction here, this is useFetchCompetition that could be
			// used elsewhere, but leave it for now
			const competitionPrediction = competitionPredictionFactory(data);
			setCompetitionPrediction(competitionPrediction);

			setIsSetup(true);
		}
	}, [data]);

	useEffect(() => {
		setIsMinLoadingTimeMet(false);
		const timer = setTimeout(() => setIsMinLoadingTimeMet(true), 300);

		return () => clearTimeout(timer);
	}, [selectedCompetitionId]);

	return {
		isCompetitionStructureLoading: isLoading || !isSetup || !isMinLoadingTimeMet
	};
};
