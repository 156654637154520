import { useStore } from "../../../../Store/useStore";
import { CardMedia } from "@mui/material";
import React from "react";


export const GroupPhoto = ({ stageName }: { stageName: string }) => {

	const selectedCompetitionId = useStore((store) => store.selectedCompetitionId);

	const getPhotoPath = (competitionId: number, groupName: string) => {
		return `/images/competition/${competitionId}/${groupName.replace(" ", "").toLowerCase()}.jpg`;
	};

	return (
		<CardMedia
			component="img"
			image={getPhotoPath(selectedCompetitionId, stageName)}
			alt="Group Photo"
			sx={{
				opacity: 0.8,
				display: {
					xs: "none",
					sm: "block"
				},
				height: {
					xs: 0,
					sm: "7em",
					md: "11em"
				}
			}}
		/>
	);
};
