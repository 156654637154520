import { immerable } from "immer";
import { StrongmanEvent } from "../../CompetitionStructure/StrongmanEvent";
import { Athlete } from "../../Shared/Athlete";
import { RankedAthleteCollection } from "../RankedAthleteCollection";


export class SingleEventPrediction {
	[immerable] = true;
	public Rankings: RankedAthleteCollection;
	public readonly StrongmanEvent: StrongmanEvent;
	public readonly Athletes: Array<Athlete>;

	constructor(strongmanEvent: StrongmanEvent, athletes: Array<Athlete>, existingPredictions?: RankedAthleteCollection) {
		this.Rankings = existingPredictions ?? new RankedAthleteCollection();
		this.StrongmanEvent = strongmanEvent;
		this.Athletes = athletes;
	}

	public get SelectedAthletes(): Array<Athlete> {
		return this.Rankings.AthletePositions.flatMap(x => x.Athletes);
	}

	public get UnselectedAthletes(): Array<Athlete> {
		const selectedAthletes = this.SelectedAthletes;

		return this.Athletes.filter(x => !selectedAthletes.some(y => y.Id === x.Id));
	}

	public get IsStoneOff(): boolean {
		return this.StrongmanEvent.StrongmanEventType.IsStoneOff;
	}

	public AddAthlete(athlete: Athlete): boolean {
		return this.Rankings.AddAthlete(athlete);
	}

	public RemoveAthlete(positionToRemove: number): boolean {
		return this.Rankings.RemoveAthlete(positionToRemove);
	}

	public MoveAthlete(fromIndex: number, toIndex: number): boolean {
		return this.Rankings.MoveAthlete(fromIndex, toIndex);
	}

	public ApplyTie(indexFrom: number, indexTo: number): boolean {
		return this.Rankings.ApplyTie(indexFrom, indexTo);
	}
}
