import { AthletePosition } from "../../ValueObjects/AthletePosition";
import { Athlete } from "../Shared/Athlete";
import { immerable } from "immer";


export class RankedAthleteCollection {
	[immerable] = true;
	private _athletePositions: Array<AthletePosition>;

	public get AthletePositions(): Array<AthletePosition> {
		return this._athletePositions;
	}

	constructor(existingAthletePositions?: Array<AthletePosition>) {
		this._athletePositions = existingAthletePositions
			? existingAthletePositions
			: [];
	}

	public AddAthlete(athlete: Athlete): boolean {
		if (this._athletePositions.some(x => x.Athletes.some(y => y.Id === athlete.Id))) {
			console.error(`${athlete.AthleteName} is already in this RankedAthleteCollection and cannot be added again`);
			return false;
		}
		const res = this._athletePositions.push(new AthletePosition([athlete], this.GetNextPosition()));

		return res > 0;
	}

	public MoveAthlete(fromIndex: number, toIndex: number): boolean {
		if (fromIndex === toIndex) return false;

		const newAthletePositions = [...this._athletePositions];
		const removedAthlete = newAthletePositions.splice(fromIndex, 1)[0];

		if (!removedAthlete) return false;

		newAthletePositions.splice(toIndex, 0, removedAthlete);

		this._athletePositions = newAthletePositions;
		this.rebuildAthletePositions();

		return true;
	}

	public RemoveAthlete(positionToRemove: number): boolean {
		this._athletePositions = this._athletePositions.filter(x => x.Position !== positionToRemove);
		this.rebuildAthletePositions();

		return true;
	}

	public ApplyTie(indexFrom: number, indexTo: number): boolean {
		const mergedAthletes = [
			...this._athletePositions[indexTo].Athletes,
			...this._athletePositions[indexFrom].Athletes
		];

		this._athletePositions[indexTo] = new AthletePosition(
			mergedAthletes,
			this._athletePositions[indexTo].Position
		);

		this._athletePositions.splice(indexFrom, 1);
		this.rebuildAthletePositions();

		return true;
	}

	public GetNextPosition(): number {
		let currentPosition = 0;

		this._athletePositions.forEach((x: AthletePosition) => {
			currentPosition += x.Athletes.length;
		});

		return currentPosition === 0
			? 1
			: currentPosition + 1;
	}

	public DoesPositionExist(position: number): boolean {
		return this._athletePositions.some(x => x.Position === position);
	}

	private rebuildAthletePositions() {
		let currentPosition = 1;

		this._athletePositions = this._athletePositions.map((x: AthletePosition) => {
			const y = new AthletePosition(x.Athletes, currentPosition);
			currentPosition += x.Athletes.length;

			return y;
		});
	}
}
