import { BaseStagePrediction } from "../BaseStagePrediction";
import { PredictionTypeEnum } from "../../../Enums/PredictionTypeEnum";
import { RankedAthleteCollection } from "../RankedAthleteCollection";
import { Athlete } from "../../Shared/Athlete";
import { CompetitionRuleset } from "../../../Enums/CompetitionRuleset";
import { AthletePosition } from "../../../ValueObjects/AthletePosition";

export class LeaderboardPrediction extends BaseStagePrediction {

	public override readonly IsFinal: boolean = true;
	public readonly PredictionType: PredictionTypeEnum = PredictionTypeEnum.Leaderboard;
	public Rankings: RankedAthleteCollection;

	constructor(stageId: string, ruleset: CompetitionRuleset, competingAthletes: Array<Athlete>, existingRankings?: RankedAthleteCollection) {
		super(stageId, ruleset, competingAthletes);

		this.Rankings = existingRankings
			? existingRankings
			: new RankedAthleteCollection();
	}

	public HaveSelectionsBeenMade(): boolean {
		return this.Rankings.AthletePositions.length > 0;
	}

	public AddAthlete(athlete: Athlete): boolean {
		if (!this.CompetingAthletes.some(x => x.Id === athlete.Id)) {
			console.error(`${athlete.AthleteName} can't be added to prediction because it isn't in stage ${this.StageId}`);
			return false;
		}
		return this.Rankings.AddAthlete(athlete);
	}

	public RemovePosition(positionToRemove: number): boolean {
		if (!this.Rankings.DoesPositionExist(positionToRemove)) {
			console.error(`Cannot remove position #${positionToRemove} because it has yet to be set`);
			return false;
		}
		return this.Rankings.RemoveAthlete(positionToRemove);
	}

	public MoveAthlete(fromIndex: number, toIndex: number): boolean {
		const maxIndexAllowed = this.Rankings.AthletePositions.length - 1;
		const maxIndexRequested = Math.max(fromIndex, toIndex);

		if (maxIndexRequested > maxIndexAllowed) {
			console.error(`Cannot perform a move on index of ${maxIndexRequested} because it exceeds the max index of ${maxIndexAllowed}`);
			return false;
		}

		return this.Rankings.MoveAthlete(fromIndex, toIndex);
	}

	public get SelectedAthletes(): Array<Athlete> {
		return this.Rankings.AthletePositions.flatMap(x => x.Athletes);
	}

	public get UnselectedAthletes(): Array<Athlete> {
		const selectedAthletes = this.SelectedAthletes;

		return this.CompetingAthletes.filter(x => !selectedAthletes.some(y => y.Id === x.Id));
	}

	public GetRankings(): Array<AthletePosition> {
		return this.Rankings.AthletePositions;
	}
}
