import React from "react";
import { useStore } from "../../../Store/useStore";
import { SelectPredictionTypeTabs } from "../../PredictionSelection/SelectPredictionTypeTabs";
import { PredictionTypeEnum } from "../../../Domain/Enums/PredictionTypeEnum";
import { EventPredictions } from "../../../Domain/Aggregates/StagePrediction/EventPredictions/EventPredictions";
import { EventCardList } from "../../PredictionSelection/IndividualEvents/EventCardList";
import { Box, Grid, Typography } from "@mui/material";
import { BaseStagePrediction } from "../../../Domain/Aggregates/StagePrediction/BaseStagePrediction";
import { LeaderboardCard } from "../../PredictionSelection/Leaderboard/LeaderboardCard";
import { OverallPointsTableComponent } from "../../PredictionSelection/IndividualEvents/OverallPointsTableComponent";
import { ICompetitionPrediction } from "../../../Domain/Aggregates/CompetitionPrediction/Interfaces/ICompetitionPrediction";
import { LeaderboardPrediction } from "../../../Domain/Aggregates/StagePrediction/LeaderboardPredictions/LeaderboardPrediction";
import { CompetitionStructure } from "../../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import { getAbbreviatedCompetitionName } from "../../../Utility/utility.string";


type PredictionType = LeaderboardPrediction & EventPredictions;

export const FinalStageSection = () => {

	const selectedCompetition = useStore((store) => store.selectedCompetition) as CompetitionStructure;
	const competitionPrediction = useStore((store) => store.competitionPrediction) as ICompetitionPrediction;
	const finalPrediction = competitionPrediction.Predictions.find((x: BaseStagePrediction) => x.IsFinal) as PredictionType;
	const changePredictionTypeFor = useStore((state) => state.changePredictionTypeFor);
	const isReadOnly = useStore((state) => state.isReadOnly);

	const changePredictionType = React.useCallback((predictionType: PredictionTypeEnum) => {
		changePredictionTypeFor(finalPrediction.StageId, predictionType);
	}, [finalPrediction.StageId]);

	return (
		<Box py={4}>
			{/*TODO: This is repeated in the Wsm2024GroupComponent, make a component from it?*/}
			{!isReadOnly && (
				<Box>
					<SelectPredictionTypeTabs
						predictionType={finalPrediction.PredictionType}
						setPredictionType={changePredictionType}
					/>

					<Typography variant="h6" component="div">
						Your
						<Typography variant="h6" component="span" color="warning.light"> Finals </Typography>
						Prediction:
					</Typography>
				</Box>
			)}

			{finalPrediction.PredictionType === PredictionTypeEnum.Leaderboard ? (
				<LeaderboardCard stageId={finalPrediction.StageId} isFinal={finalPrediction.IsFinal} />
			) : finalPrediction.PredictionType === PredictionTypeEnum.Events ? (
				<Box>
					{!isReadOnly && (
						<Typography
							variant="body2"
							color="text.secondary"
							my={1}
						>
							Select the winners of each individual event.
						</Typography>
					)}

					<EventCardList
						stageId={finalPrediction.StageId}
						predictions={finalPrediction.EventPredictions}
					/>

					<Grid container pt={4} justifyContent="center">
						<Grid item xs={12} md={6}>
							<OverallPointsTableComponent
								pointsTable={finalPrediction.PointsTable}
								title={`${isReadOnly ? "Predicted" : "Your"} ${getAbbreviatedCompetitionName(selectedCompetition.Name)} ${selectedCompetition.Year} Results`}
								text={`The final leaderboard for ${getAbbreviatedCompetitionName(selectedCompetition.Name)} ${selectedCompetition.Year}, calculated from ${isReadOnly ? "the above" : "your"} event predictions`}
							/>
						</Grid>
					</Grid>
				</Box>
			) : (
				<h6>Prediction type not recognised or set</h6>
			)}
		</Box>
	);
};

