import React from "react";
import { useStore } from "../../../../Store/useStore";
import { Alert, Button, Dialog, DialogActions, DialogContent, Divider, Typography } from "@mui/material";
import { TextBoxWithCopyButton } from "./TextBoxWithCopyButton";
import { IsLoadingBox } from "../../../Global/IsLoadingBox";
import { useMarkdown } from "../../../../Hooks/useMarkdown";


export const ShareModal = () => {

	const isOpen = useStore((state) => state.isShareModalOpen);
	const close = useStore((state) => state.closeShareModal);
	const shareLinkModalMemorablePhrase = useStore((state) => state.shareLinkModalMemorablePhrase);
	const isLoading = useStore((state) => state.isShareModalLoading);
	const convertToMd = useMarkdown();

	return (
		<Dialog open={isOpen} maxWidth="sm" fullWidth>
			<DialogContent sx={{ mt: 1 }}>
				<IsLoadingBox isLoading={isLoading}>
					<Typography variant="h6" mb={3}>
						Share your predictions with this link:
					</Typography>

					<TextBoxWithCopyButton text={`${process.env.REACT_APP_redirectUri}/share/${shareLinkModalMemorablePhrase}`} />

					<Divider sx={{ mt: 4, mb: 3 }} />

					<Typography variant="h6" mb={3}>
						Or share a markdown version:
					</Typography>

					<TextBoxWithCopyButton
						text={convertToMd()}
						multiline={true}
						rows={8}
					/>

					<Divider sx={{ mt: 4, mb: 3 }} />

					<Alert severity="info">Note: shared results are cached and refreshed every 15 minutes</Alert>
				</IsLoadingBox>
			</DialogContent>

			<DialogActions>
				<Button color="success" onClick={convertToMd}>Markdown</Button>
				<Button color="warning" onClick={close}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};
