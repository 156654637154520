import React from "react";
import { Button } from "@mui/material";
import { useStore } from "../../../../Store/useStore";
import { performAuthenticatedGet } from "../../../../ApplicationServices/Auth/protected-api-call";
import ShareIcon from "@mui/icons-material/Share";


interface ICreateShareLinkResponse {
	IsSuccessful: boolean;
	ErrorMessage?: string;
	ShareLink: string;
}

export const ShareButton = () => {

	const openShareModal = useStore((state) => state.openShareModal);
	const competitionPrediction = useStore((state) => state.competitionPrediction);
	const setIsShareModalLoading = useStore((state) => state.setIsShareModalLoading);
	const setShareLinkModalMemorablePhrase = useStore((state) => state.setShareLinkModalMemorablePhrase);

	const onClick = async () => {
		setIsShareModalLoading(true);
		openShareModal();
		const res = await performGet();

		if (res.IsSuccessful) {
			setShareLinkModalMemorablePhrase(res.ShareLink);
		} else {
			console.error(res.ErrorMessage);
		}

		setIsShareModalLoading(false);
	};

	const performGet = async (): Promise<ICreateShareLinkResponse> => {
		const url = `share/create-share-link/${competitionPrediction.CompetitionPredictionId}`;
		const res = await performAuthenticatedGet(url) as ICreateShareLinkResponse;

		return res;
	};

	return (
		<Button
			color="secondary"
			variant="contained"
			sx={{ ml: 0.75 }}
			onClick={onClick}
			startIcon={<ShareIcon />}
		>
			Share
		</Button>
	);
};


