import { Stage } from "../../../CompetitionStructure/Stage";
import { Wsm2023PointsCalculationStrategy } from "./ConcretePointsCalculationStrategies";
import { immerable } from "immer";
import { CompetitionRuleset } from "../../../../Enums/CompetitionRuleset";
import { Athlete } from "../../../Shared/Athlete";
import { IPointCalculationStrategy } from "./BasePointCalculationStrategy";


export class PointsCalculationStrategyFactory {
	[immerable] = true;
	public static Create(stage: Stage, ruleset: CompetitionRuleset, competingAthletes: Array<Athlete>): IPointCalculationStrategy {
		switch (ruleset) {
			case CompetitionRuleset.Wsm2023: {
				return new Wsm2023PointsCalculationStrategy(stage, competingAthletes);
			}
			case CompetitionRuleset.Asc2023: {
				return new Wsm2023PointsCalculationStrategy(stage, competingAthletes);
			}
			default: {
				throw new Error(`PointsCalculationStrategyFactory was not able to provide a strategy for ruleset: ${ruleset}`);
			}
		}
	}
}
