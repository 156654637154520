import { CompetitionStructure } from "../../../../Domain/Aggregates/CompetitionStructure/CompetitionStructure";
import { PredictionTypeEnum } from "../../../../Domain/Enums/PredictionTypeEnum";
import { RankedAthleteCollectionReadModel } from "./RankedAthleteCollectionReadModel";
import { SingleEventPredictionReadModel } from "./SingleEventPredictionReadModel";
import { RankedAthleteCollection } from "../../../../Domain/Aggregates/StagePrediction/RankedAthleteCollection";
import { CompetitionRuleset } from "../../../../Domain/Enums/CompetitionRuleset";
import { StagePredictionFactory } from "../../../../Domain/Aggregates/StagePrediction/StagePredictionFactory";
import { Athlete } from "../../../../Domain/Aggregates/Shared/Athlete";
import {
	EventPredictions,
	ExistingEventPredictionDto
} from "../../../../Domain/Aggregates/StagePrediction/EventPredictions/EventPredictions";
import { GroupStageEventPredictions } from "../../../../Domain/Aggregates/StagePrediction/EventPredictions/GroupStageEventPredictions";
import { AthletePositionIdsReadModel, mapAthletePositionIdsReadModelsToEntities } from "./IAthletePositionIdsReadModel";


export interface BaseStagePredictionReadModel {
	StagePredictionId: string;
	StageId: string;
	Ruleset: CompetitionRuleset;
	PredictionType: PredictionTypeEnum;
}

export interface BaseLeaderboardPredictionReadModel extends BaseStagePredictionReadModel {
	RankedAthleteCollection: RankedAthleteCollectionReadModel<AthletePositionIdsReadModel>;
}

export interface BaseEventPredictionsReadModel extends BaseStagePredictionReadModel {
	SingleEventPredictions: Array<SingleEventPredictionReadModel>;
}

export const mapLeaderboardPredictionReadModelToEntity = (selectedCompetition: CompetitionStructure, athletesInStage: Array<Athlete>, leaderboardPredictionReadModel: BaseLeaderboardPredictionReadModel) => {
	const stage = selectedCompetition.GetStage(leaderboardPredictionReadModel.StageId);
	const athletesInCompetition = selectedCompetition.AllAthletes;
	const athletePositions = mapAthletePositionIdsReadModelsToEntities(leaderboardPredictionReadModel.RankedAthleteCollection.AthletePositions, athletesInCompetition);
	const rac = new RankedAthleteCollection(athletePositions);

	return StagePredictionFactory.CreatePredictionFor(
		stage,
		athletesInStage,
		PredictionTypeEnum.Leaderboard,
		selectedCompetition.Ruleset,
		rac
	);
};

export const mapEventPredictionsReadModelToEntity = (selectedCompetition: CompetitionStructure, athletesInStage: Array<Athlete>, eventPredictionsReadModel: BaseEventPredictionsReadModel) => {
	const stage = selectedCompetition.GetStage(eventPredictionsReadModel.StageId);
	const dtos = getExistingEventPredictionDtos(eventPredictionsReadModel.SingleEventPredictions, athletesInStage);

	return new EventPredictions(stage, selectedCompetition.Ruleset, athletesInStage, dtos);
};

export const mapGroupStageEventPredictionsReadModelToEntity = (selectedCompetition: CompetitionStructure, athletesInStage: Array<Athlete>, eventPredictionsReadModel: BaseEventPredictionsReadModel) => {
	const stage = selectedCompetition.GetStage(eventPredictionsReadModel.StageId);
	const dtos = getExistingEventPredictionDtos(eventPredictionsReadModel.SingleEventPredictions, athletesInStage);
	const groupStageEventPrediction = new GroupStageEventPredictions(stage, selectedCompetition.Ruleset, stage.Athletes, dtos);
	groupStageEventPrediction.UpdatePointsTable();

	return groupStageEventPrediction;
};

const getExistingEventPredictionDtos = (singleEventPredictionReadModels: Array<SingleEventPredictionReadModel>, athletesInStage: Array<Athlete>): Array<ExistingEventPredictionDto> => {
	return singleEventPredictionReadModels.map((x: SingleEventPredictionReadModel) => {
		const aps = mapAthletePositionIdsReadModelsToEntities(x.RankedAthleteCollection.AthletePositions, athletesInStage);
		const rac = new RankedAthleteCollection(aps);

		return new ExistingEventPredictionDto(x.StrongmanEvent.Id, rac);
	});
};
