import { PredictionTypeEnum } from "../../../Domain/Enums/PredictionTypeEnum";
import { GetStatsQueryResult } from "../ViewModels/PredictionStatsViewModels";
import leaderboardStatsJson from "../../../dev/dev-leaderboard-stats.json";
import eventsStatsJson from "../../../dev/dev-events-stats.json";


export const loadStatsQuery = async (stageId: string, predictionType: PredictionTypeEnum): Promise<GetStatsQueryResult> => {
	// const stats = getLocalStats(stageId, predictionType); // DEV
	const stats = await performApiCall(stageId, predictionType);

	return stats;
};


const performApiCall = async (stageId: string, predictionType: PredictionTypeEnum): Promise<GetStatsQueryResult> => {
	const res = await fetch(`${process.env.REACT_APP_apiBaseUri}/api/Stats/stage/${stageId}/predictionType/${predictionType}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json"
		}
	});

	return await res.json();
};

const getLocalStats = (stageId: string, predictionType: PredictionTypeEnum): GetStatsQueryResult => {

	if (predictionType === PredictionTypeEnum.Leaderboard) {
		return leaderboardStatsJson as GetStatsQueryResult;
	} else {
		return eventsStatsJson as GetStatsQueryResult;
	}
};
