import { CountryViewModel } from "../../ApplicationServices/Queries/ViewModels/CountryViewModel";

export class Country {

	public readonly Name: string;
	public readonly CountryCode: string;

	constructor(name: string, countryCode: string) {
		this.Name = name;
		this.CountryCode = countryCode;
	}

	public equals(country: Country): boolean {
		return country.Name === this.Name;
	}
}
