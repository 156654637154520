import { StrongmanEventType } from "../../../Domain/Aggregates/CompetitionStructure/StrongmanEventType";


export interface StrongmanEventTypeViewModel {
	Name: string;
}

export const mapStrongmanEventTypeViewModelToEntity = (viewModel: StrongmanEventTypeViewModel): StrongmanEventType => {
	return new StrongmanEventType(viewModel.Name);
};
