import { immerable } from "immer";
import { Athlete } from "../../Shared/Athlete";


export class StagePointsTable {
	[immerable] = true;

	public readonly StageId: string;
	public readonly AthletesWithPoints: Array<AthletePointsTableRow>;

	constructor(stageId: string, athletePoints: Array<AthletePointsTableRow>) {
		this.StageId = stageId;
		this.AthletesWithPoints = athletePoints;
	}

	public get SortedAthletesWithPoints(): Array<AthletePointsTableRow> {
		return this.AthletesWithPoints
			.slice()
			.sort((a, b) => a.Position - b.Position);
	}
}


/**
 * GroupStageAthletePoints is used when calculating the overall points table for a whole stage
 */
export class AthletePointsTableRow {
	[immerable] = true;
	public readonly Athlete: Athlete;
	public readonly Points: number;
	public readonly Position: number;
	public readonly IsWinner: boolean;

	constructor(athlete: Athlete, points: number, position: number, isWinner: boolean) {
		this.Athlete = athlete;
		this.Points = points;
		this.Position = position;
		this.IsWinner = isWinner;
	}
}

