import { RankedAthleteCollection } from "../../RankedAthleteCollection";
import { Athlete } from "../../../Shared/Athlete";
import { ILeaderboardPredictionFinalistsSelectionStrategy } from "./LeaderboardPredictionFinalistsSelectionStrategy";
import { immerable } from "immer";


export class Wsm2023LeaderboardPredictionFinalistsSelectionStrategy implements ILeaderboardPredictionFinalistsSelectionStrategy {
	[immerable] = true;
	public GetFinalists(rankings: RankedAthleteCollection): Array<Athlete> {
		const firstPlace = rankings.AthletePositions.find(x => x.Position === 1)?.Athletes[0];
		const secondPlace = rankings.AthletePositions.find(x => x.Position === 2)?.Athletes[0];

		return [firstPlace, secondPlace]
			.filter((x): x is Athlete => !!x)
			.sort((a, b) => a.AthleteName.localeCompare(b.AthleteName));
	}
}
