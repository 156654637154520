import { BrowserCacheLocation, Configuration, SilentRequest } from "@azure/msal-browser";


export const b2cPolicies = {
	names: {
		signUpSignIn: "B2C_1_SignUpSignIn",
		editProfile: "B2C_1_EditProfile"
	},
	authorities: {
		signUpSignIn: {
			authority: "https://fantasystrongman.b2clogin.com/fantasystrongman.onmicrosoft.com/B2C_1_SignUpSignIn"
		},
		editProfile: {
			authority: "https://fantasystrongman.b2clogin.com/fantasystrongman.onmicrosoft.com/B2C_1_EditProfile"
		}
	},
	authorityDomain: "fantasystrongman.b2clogin.com"
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId: "0b6ad533-78ca-440c-b757-1f6d91b45411",
		authority: b2cPolicies.authorities.signUpSignIn.authority,
		knownAuthorities: [b2cPolicies.authorityDomain],
		redirectUri: process.env.REACT_APP_redirectUri,
		postLogoutRedirectUri: "/"
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage,
		storeAuthStateInCookie: true
	},
	system: {
		allowNativeBroker: false // Disables WAM Broker
	}
};

export const requestScopes: SilentRequest = {
	scopes: [
		"openid",
		"profile",
		"https://fantasystrongman.onmicrosoft.com/fantasy-strongman-api/tasks.read",
		"https://fantasystrongman.onmicrosoft.com/fantasy-strongman-api/tasks.write",
		"offline_access"
	]
};
