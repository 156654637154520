import { CanExecuteResponse } from "./CanExecuteResponse";
import { Stage } from "../../../CompetitionStructure/Stage";
import { immerable } from "immer";
import { ICompetitionPrediction } from "../../Interfaces/ICompetitionPrediction";


export interface ICommand {
	CanExecute(): CanExecuteResponse;
	Execute(): boolean;
}


/**
 * This is a generic command that will be able to handle all competition
 * types, not just Wsm2023
 */
export abstract class ModifyPredictionCommand<T extends ICompetitionPrediction> implements ICommand {
	[immerable] = true;
	public CompetitionPrediction: T;
	public readonly Stage: Stage;
	public readonly HasPromptBeenConfirmed: boolean;
	public readonly StrongmanEventId?: number;

	protected constructor(wsm: T, stageId: string, promptConfirmed: boolean, strongmanEventId?: number) {
		this.CompetitionPrediction = wsm;
		this.Stage = this.CompetitionPrediction.Competition.GetStage(stageId);
		this.HasPromptBeenConfirmed = promptConfirmed;
		this.StrongmanEventId = strongmanEventId;
	}

	public abstract CanExecute(): CanExecuteResponse;

	public abstract Execute(): boolean;
}
