import { immerable } from "immer";

export class ConfirmModalSettings {
	[immerable] = true;
	public IsOpen: boolean;
	public readonly Title: string;
	public readonly Messages: Array<string>;

	constructor(isOpen: boolean, title?: string, prompts?: Array<string>) {
		this.IsOpen = isOpen;
		this.Title = title ?? "";
		this.Messages = prompts ?? [];
	}
}

