import React from "react";
import { AthleteSelectionChips } from "../Table/AthleteSelectionChips";
import { useStore } from "../../../Store/useStore";
import { Athlete } from "../../../Domain/Aggregates/Shared/Athlete";
import { AthleteOrderingTable } from "../Table/AthleteOrderingTable";
import { LeaderboardPrediction } from "../../../Domain/Aggregates/StagePrediction/LeaderboardPredictions/LeaderboardPrediction";
import { Grid } from "@mui/material";
import { AthleteOrderingTableReadOnly } from "../Table/AthleteOrderingTableReadOnly";
import { PredictionStatsViewModel } from "../../../ApplicationServices/Queries/ViewModels/PredictionStatsViewModels";
import { useStats } from "../../../Hooks/useStats";
import { PredictionTypeEnum } from "../../../Domain/Enums/PredictionTypeEnum";


export const LeaderboardSelectionTable = ({ stageId }: { stageId: string }) => {

	const predictionForCurrentGroup = useStore((state) => state.getPredictionForStage(stageId)) as LeaderboardPrediction;
	const isReadOnly = useStore((state) => state.isReadOnly);

	const [
		addAthleteToPrediction,
		removePositionFromPrediction,
		movePositionWithinPrediction
	] = useStore((state) => [
		state.addAthleteToPrediction,
		state.removePositionFromPrediction,
		state.movePositionWithinPrediction
	]);

	const onAthleteChipClicked = React.useCallback((athlete: Athlete) => {
		addAthleteToPrediction(athlete, stageId);
	}, [stageId]);

	const deleteRowFromListFn = React.useCallback((position: number) => {
		removePositionFromPrediction(position, stageId);
	}, [stageId]);

	const moveAthleteFn = React.useCallback((fromIndex: number, toIndex: number) => {
		movePositionWithinPrediction(fromIndex, toIndex, stageId);
	}, [stageId]);

	const { areStatsLoading, statsForCurrentGroup } = useStats(stageId, PredictionTypeEnum.Leaderboard);

	const getStatsPercentageFor = React.useCallback((athletes: Athlete[], position: number): number | undefined => {
		const statsViewModel = (statsForCurrentGroup as PredictionStatsViewModel[]).find(x =>
			x.AthleteName === athletes[0].AthleteName
			&& x.Position === position);

		return statsViewModel?.PositionPercentage ?? 0;
	}, [areStatsLoading, statsForCurrentGroup]);

	return (
		<>
			{!isReadOnly && (
				<AthleteSelectionChips
					unselectedAthletes={predictionForCurrentGroup.UnselectedAthletes}
					onClick={onAthleteChipClicked}
				/>
			)}

			<Grid container justifyContent="center">
				<Grid item xs={12} sm={10} md={9} lg={7}>
					{isReadOnly ? (
						<AthleteOrderingTableReadOnly
							rows={predictionForCurrentGroup.Rankings.AthletePositions}
							nextPositionToSelect={predictionForCurrentGroup.Rankings.GetNextPosition()}
							emptyRowsToRender={predictionForCurrentGroup.UnselectedAthletes.length}
							getStatsPercentageFor={getStatsPercentageFor}
						/>
					) : (
						<AthleteOrderingTable
							rows={predictionForCurrentGroup.Rankings.AthletePositions}
							nextPositionToSelect={predictionForCurrentGroup.Rankings.GetNextPosition()}
							emptyRowsToRender={predictionForCurrentGroup.CompetingAthletes.length - predictionForCurrentGroup.Rankings.AthletePositions.length}
							removeRowFn={deleteRowFromListFn}
							moveAthlete={moveAthleteFn}
							areTiesAllowed={false}
							getStatsPercentageFor={getStatsPercentageFor}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
};
