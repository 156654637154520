import React from "react";
import { Wsm2023GroupsComponent } from "./Wsm2023GroupsComponent";
import { Divider } from "@mui/material";
import { Wsm2023FinalsComponent } from "./Wsm2023FinalsComponent";


export const Wsm2023Component = () => {
	return (
		<>
			<Wsm2023GroupsComponent/>

			<Divider sx={{ my: 3 }}/>

			<Wsm2023FinalsComponent/>
		</>
	);
};
