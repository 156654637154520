import { Stage } from "../../../CompetitionStructure/Stage";
import { SingleEventPrediction } from "../SingleEventPrediction";
import { StrongmanEvent } from "../../../CompetitionStructure/StrongmanEvent";
import { ISingleEventPredictionGenerationStrategy } from "./SingleEventPredictionGenerationStrategy";
import { immerable } from "immer";
import { Athlete } from "../../../Shared/Athlete";


export class Wsm2023SingleEventPredictionGenerationStrategy implements ISingleEventPredictionGenerationStrategy {
	[immerable] = true;
	public Generate(stage: Stage, competingAthletes: Array<Athlete>): Array<SingleEventPrediction> {
		return stage
			.Events
			.map((strongmanEvent: StrongmanEvent) => new SingleEventPrediction(
				strongmanEvent,
				strongmanEvent.StrongmanEventType.IsStoneOff ? [] : competingAthletes
			));
	}
}

export class Asc2023SingleEventPredictionGenerationStrategy implements ISingleEventPredictionGenerationStrategy {
	[immerable] = true;
	public Generate(stage: Stage, competingAthletes: Array<Athlete>): Array<SingleEventPrediction> {
		return stage
			.Events
			.map((strongmanEvent: StrongmanEvent) => new SingleEventPrediction(
				strongmanEvent,
				competingAthletes
			));
	}
}
