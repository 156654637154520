import { Athlete } from "../../Domain/Aggregates/Shared/Athlete";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { CircleFlag } from "react-circle-flags";
import React from "react";


export const AthleteDisplayGrid = ({ athletes }: { athletes: Array<Athlete> }) => {
	return (
		<Grid
			container
			spacing={2}
			justifyContent="center"
		>

			{athletes.map((finalist: Athlete, index: number) => (
				<Grid xs={4} sm={4} md={1.8} lg={1.2} key={`finalists-row-${index}`} item>
					<Card elevation={3} title={finalist.AthleteName}>
						<CardContent sx={{
							p: {
								xs: "0.75em !important",
								sm: "1em  !important"
							}
						}}>

							<Box textAlign="center" mb={1.5}>
								<CircleFlag
									countryCode={finalist.Country.CountryCode}
									height="25"
									title={finalist.Country.Name}
								/>
							</Box>

							<Typography
								variant="subtitle2"
								component="div"
								textAlign="center"
								overflow="hidden"
								textOverflow="ellipsis"
								maxHeight="3em"
								sx={{
									fontSize: {
										xs: "0.75em !important",
										sm: "0.875em !important"
									},
									"@media (max-width: 1200px)": {
										whiteSpace: "nowrap"
									}
								}}
							>
								{finalist.AthleteName}
							</Typography>

						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);
};
