import { Box, useTheme } from "@mui/material";
import React from "react";


export const AthleteNameText = ({ athleteName, isPredictedWinner }: { athleteName: string, isPredictedWinner: boolean }) => {
	const theme = useTheme();

	return (
		<Box component="span" color={isPredictedWinner ? theme.palette.success.main : ""}>
			{athleteName}
		</Box>
	);
};
