import { immerable } from "immer";
import { Stage } from "./Stage";
import { CompetitionRuleset } from "../../Enums/CompetitionRuleset";
import { Athlete } from "../Shared/Athlete";


export interface ICompetitionStructure {
	Id: number;
	Name: string;
	Year: number;
}

export class CompetitionStructure implements ICompetitionStructure {
	[immerable] = true;
	public readonly Id: number = 0;
	public readonly Name: string = "";
	public readonly Year: number = 0;
	public readonly Stages: Array<Stage> = [];
	public readonly Ruleset: CompetitionRuleset = CompetitionRuleset.Wsm2023;

	constructor(id: number, name: string, year: number, stages: Array<Stage>, ruleset: CompetitionRuleset) {
		this.Id = id;
		this.Name = name;
		this.Year = year;
		this.Stages = stages;
		this.Ruleset = ruleset;
	}

	public get Heats(): Array<Stage> {
		return this.Stages
			.filter(x => !x.IsFinal)
			.map(x => x as Stage)
			.sort((x, y) => x.DisplayOrder > y.DisplayOrder ? 1 : -1);
	}

	public get Final(): Stage {
		const final = this.Stages.find(x => x.IsFinal);

		if (final) {
			return final;
		} else {
			throw new Error("No finals exist for this competition");
		}
	}

	public get AllAthletes(): Array<Athlete> {
		const allAthletes = this.Stages.flatMap(x => x.Athletes);
		const uniqueAthletes: Array<Athlete> = [];

		for (const athlete of allAthletes) {
			if(!uniqueAthletes.some(x => x.Id === athlete.Id)) {
				uniqueAthletes.push(athlete);
			}
		}

		return uniqueAthletes;
	}

	public ContainsStage(stageId: string): boolean {
		return this.Stages.some(x => x.Id === stageId);
	}

	public GetStage(stageId: string): Stage {
		const stage = this.Stages.find(x => x.Id === stageId);

		if (!stage) {
			throw new Error(`No stage with ID ${stageId} exists in ${this.Name}`);
		}

		return stage;
	}
}
