import React from "react";
import { Box, Typography } from "@mui/material";
import { LeaderboardSelectionTable } from "./LeaderboardSelectionTable";
import { useStore } from "../../../Store/useStore";


export const LeaderboardCard = ({ stageId, isFinal }: { stageId: string, isFinal: boolean }) => {

	const isReadOnly = useStore((state) => state.isReadOnly);

	return (
		<Box sx={{
			fontSize: {
				xs: "0.875em",
				sm: "1em"
			}
		}}>
			{!isReadOnly && (
				<Typography
					variant="body2"
					color="text.secondary"
					mb={3}
					mt={1}
				>
					Select how you think the athletes will place overall for this stage.
					{!isFinal && " The top two athletes will proceed to the final."}
					&nbsp;If you want to instead select who will win each individual event, change the prediction type above.
				</Typography>
			)}

			<LeaderboardSelectionTable stageId={stageId} />
		</Box>
	);
};
