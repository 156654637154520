import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { EventCard } from "./EventCard";
import { useStore } from "../../../Store/useStore";
import { OverallPointsTableComponent } from "./OverallPointsTableComponent";
import { FinalistsForStageCard } from "../../StagedSelection/GroupStage/FinalistsForStageCard";
import { Stage } from "../../../Domain/Aggregates/CompetitionStructure/Stage";
import { EventCardList } from "./EventCardList";
import {
	GroupStageEventPredictions
} from "../../../Domain/Aggregates/StagePrediction/EventPredictions/GroupStageEventPredictions";


const fontSize = "0.875em";

export const EventCardListWithStoneOff = ({ stage }: { stage: Stage }) => {
	//console.log("EventCardListWithStoneOff rendering...");

	const predictionForCurrentGroup = useStore((state) => state.getPredictionForCurrentGroup()) as GroupStageEventPredictions;
	const regularEventPredictions = predictionForCurrentGroup.EventPredictions.filter(x => !x.IsStoneOff);
	const stoneOffEventPrediction = predictionForCurrentGroup.EventPredictions.find(x => x.IsStoneOff);
	const athletesQualifiedForFinal = predictionForCurrentGroup.GetFinalists();
	const isReadOnly = useStore((state) => state.isReadOnly);

	if (!stoneOffEventPrediction) {
		throw new Error("The EventCardListWithStoneOff is being rendered for a prediction that does not contain the StoneOff event");
	}

	return (
		<Box>
			{!isReadOnly && (
				<Typography
					variant="body2"
					color="text.secondary"
					my={1}
				>
					Select individual event placings; the athlete who finishes first on points and the winner of the stone-off will proceed
					to the final.
				</Typography>
			)}

			<EventCardList
				stageId={stage.Id}
				predictions={regularEventPredictions}
			/>

			<Grid container columnSpacing={4} rowSpacing={4} pt={4} sx={{ fontSize: fontSize }}>
				<Grid item xs={12} sm={12} md={6} sx={{ margin: "0 auto" }}>
					<OverallPointsTableComponent
						pointsTable={predictionForCurrentGroup.PointsTable}
						title={`${stage.Name} Points Table`}
						text="Calculated from your event predictions"
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Grid container>
						<Grid item xs={12} mb={3}>
							<EventCard
								key={stoneOffEventPrediction.StrongmanEvent.Id}
								strongmanEvent={stoneOffEventPrediction.StrongmanEvent}
								stageId={stage.Id}
							/>
						</Grid>

						<Grid item xs={12}>
							<FinalistsForStageCard
								pointsWinner={athletesQualifiedForFinal[0] ?? undefined}
								stoneOffWinner={athletesQualifiedForFinal[1] ?? undefined}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

		</Box>
	);
};

